<div class="mobileimgsection VisbleonDesktop" >
  <div class="container">
<img src="../../assets/img/uidesign.png"  class="img-fluid" />
</div>
</div>


<div class="p60 innerbanner">

  <div class="container">
    <div class="row">


      <div class="col-md-6 col-sm-7 col-xs-12 ">
        <h1 data-aos="fade-up">USER EXPERIENCE DESIGN</h1>

        <p data-aos="fade-up" data-aos-delay="300"><span>User experience (UX) design is the process our team uses to
            create products that provide meaningful and relevant experiences to users. Our UX designers are concerned
            with the entire process of acquiring and integrating a product, including aspects of branding, design,
            usability and function
            <br/> <br/>
            <ul>
              <li>User Research</li>
              <li>User Interface (UI) Design</li>
              <li>Visual Design</li>
              <li>UI Engineering</li>
            </ul>
          </span> </p>

        <br>

      </div>

      <div class="col-md-6 col-sm-5 col-12" >
        <div class="innerheroimg">
          <img src="../../assets/img/uidesign.png"  />
        </div>
       
      </div>


    </div>
  </div>
</div>


<div class="servicedetailsection p60">
  <div class="container">
    <div class="row">
      <div class="col-sm-6 col-md-6 col-12">
        <div class="img-icon"  data-aos="fade-right">
          <img src="../../assets/img/icons/useresearch.png" class="img-fluid" style="max-height: 250px;" />
          </div>
      </div>
      <div class="col-sm-6 col-md-6 col-12">
        <div class="section-subheading" data-aos="fade-left">
          User Research
        </div>
        <p class="section-subpara" data-aos="fade-left">
          User research is the systematic investigation of users and their requirements, in order to add context and
          insight into the process of designing the user experience. Our team employs a variety of techniques, tools,
          and methodologies to determine facts, uncover problems and reach conclusions thereby revealing valuable
          information which can be fed into the design process.

        </p>

      </div>

    </div>


  </div>
</div>

<div class="servicedetailsection p60">
  <div class="container">

    <div class="row">


      <div class="col-sm-6 col-md-6 col-12">
        <div class="section-subheading" data-aos="fade-right">
          User Interface (UI) Design
        </div>
        <p class="section-subpara" data-aos="fade-right">
          A software becomes more popular if its user interface is simple to use, clear to understand, attractive and
          consistent. Our experts determine your needs and deliver creative and innovative solutions to your ideas

        </p>

      </div>

      <div class="col-sm-6 col-md-6 col-12">
        <div class="img-icon"  data-aos="fade-left">
          <img src="../../assets/img/icons/uidesign.png" class="img-fluid" style="max-height: 250px;" />
          </div>
      </div>

    </div>


  </div>
</div>


<div class="servicedetailsection p60">
  <div class="container">
    <div class="row">
      <div class="col-sm-6 col-md-6 col-12">
        <div class="img-icon"  data-aos="fade-right">
          <img src="../../assets/img/icons/creativervices.png" class="img-fluid" style="max-height: 250px;" class="hideonMobile"/>
          </div>
      </div>
      <div class="col-sm-6 col-md-6 col-12">
        <div class="section-subheading" data-aos="fade-left">
          Visual Design
        </div>
        <p class="section-subpara" data-aos="fade-left">
          We aim to shape and improve the user experience through the effects of illustrations, photography, typography,
          space, layouts, and color on the usability of products and their aesthetic appeal. Creative visual design
          enhances your product, service by attracting, engaging users and helping to build their trust and interest in
          the product.
        </p>
      </div>
    </div>
  </div>
</div>

