import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-uxdesign',
  templateUrl: './uxdesign.component.html',
  styleUrls: ['./uxdesign.component.css']
})
export class UXDesignComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
