import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  public openMenu()
  {
    
    let element = document.getElementById('nav-overlay')
    element.classList.add("open-menu");
  }

  public closeMenu()
  {
    let element = document.getElementById('nav-overlay')
    element.classList.remove("open-menu");
   
  }

}
