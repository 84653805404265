<div class="p60 innerbanner">
    
    <div class="container">
      <div class="row">
  
  
        <div class="col-md-6 col-sm-6 col-xs-12 ">
          <h1 data-aos="fade-up">Let’s talk</h1>

          <p data-aos="fade-up" data-aos-delay="300"><span>
            
            E: surendra@cdmedialabs.com<br/>
            <p>P: 96662 09922</p>
            
          </span> </p>
  <!-- 
        <form  data-aos="fade-up"> 
            <div class="field-group">
                <div class="inputgroup">
                  
                  <input type="email"  class="form-control" placeholder="Email Id">
                  
                </div>
              </div>

              <div class="field-group">
                <div class="inputgroup">
                  
                  <input type="text"  class="form-control" placeholder="Phone Number">
                  
                </div>
              </div>

              <div class="field-group">
                <div class="inputgroup">
                  
                  <input type="text"  class="form-control" placeholder="Message">
                  
                </div>
              </div>

              <a href="works.html" class="btn roundbtn" data-aos="fade-up" data-aos-delay="800">Submit</a> <br>
        </form> -->
  
          <br>
  
        </div>

        <div class="col-md-6 col-sm-6 col-xs-12 ">
            <h1 data-aos="fade-up">Location</h1>
            <p data-aos="fade-up" data-aos-delay="300"><span>#51-8-56,  <br/>
                Seethammadara, Visakhapatnam, <br/>
                Andhra Pradesh - 530003 <br/>
               
                
              </span> </p>
            <!--
              <a href="https://www.google.com/maps/place/Sistra+Technologies/@17.7349329,83.3223062,17z/data=!3m1!4b1!4m5!3m4!1s0x3a39432cfb5efbcb:0x288fa4d81b4304c4!8m2!3d17.7349329!4d83.3244949?hl=en-GB" target="_blank" class="btn roundbtn" data-aos="fade-up" data-aos-delay="800">View on maps</a> <br>-->
        </div>
  
  
      </div>
    </div>
  </div>