<header class="heroheader p60">


  <app-menu></app-menu>

</header>



<router-outlet></router-outlet>
<div class="letstalk-section p60 ">
  <div class="container-fluid">
    <div class="section-heading">LET's Build something awesome </div>
    <p class="section-p">Get in touch with some basic project info for a quick quote <br><br>
      <a routerLink="/contact" routerLinkActive="active" class="btn roundbtn">Contact Us</a></p> <br>
  </div>

</div>
<section class="footer-nav" id="About  Us">
  <div class="container">
    <div class="row navdiv">
      <div class="col-md-4 col-sm-4 col-12">

        <ul>
          <li><a routerLink="/home" routerLinkActive="active">Home</a></li>
          <li><a routerLink="/explore" routerLinkActive="active">Explore</a></li>
          <li><a routerLink="/services" routerLinkActive="active">Services</a></li>
          <li><a routerLink="/works" routerLinkActive="active">Works</a></li>
          <li> <a routerLink="/career" routerLinkActive="active">Careers</a></li>
        </ul>
      </div>
      <div class="col-md-8 col-sm-8 col-12 text-right">
        <ul>
          <li><a routerLinkActive="active">Application Development</a></li>
          <li><a  routerLinkActive="active">Creative Branding</a></li>
          <li><a  routerLinkActive="active">User Experience Design </a></li>
          

        </ul>

        <!--      -->
      </div>
    </div>
    <div class="row copyright">

      <div class="col-md-6 col-sm-6 col-12">
        <p class="copyrightText">© 2020 C & D Media Labs</p>
      </div>
      <div class="col-md-6 col-sm-6 col-12 text-right">
        <ul class="social copyrightText">

          <li>
            <a target="_blank" class="fa-twitter fa" href="https://twitter.com"></a>
          </li>
          <li>
            <a target="_blank"  class="fa-facebook fa"href="https://www.facebook.com"> </a>
          </li>
          <li>
            <a target="_blank" class="fa-linkedin fa" href="https://in.linkedin.com"></a>
          </li>
        
        </ul>
      </div>

    </div>
  </div>
</section>
