<nav class="navbar navbar-expand-lg fixed-top" data-aos="fade-down">
  <div class="container">
    
    <a class="navbar-brand" href="http://www.cdmedialabs.com">
      <img src="../assets/img/logo.png " alt="C & D Media Labs" />

    </a>


    <div class="collapse navbar-collapse" >
      <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
        <li><a routerLink="/explore" routerLinkActive="active">Explore</a></li>

        <li><a routerLink="/services" routerLinkActive="active">Services</a></li>
       <!-- 
        <li >
          
         <a  data-toggle="dropdown"  aria-haspopup="true" aria-expanded="false" >Services</a>
        <div class="dropdown-menu" data-aos="fade-down">
        
            <li><a class="dropdown-item" routerLink="/ApplicationDevelopment" routerLinkActive="active">Application Development</a></li>
          <li><a class="dropdown-item" routerLink="/UXDesign" routerLinkActive="active">UI/UX Design</a></li>
          <li><a class="dropdown-item" routerLink="/DigitalMarketing" routerLinkActive="active">Digital Marketing</a></li>
          </div>
         
        </li>-->
        <li><a routerLink="/works" routerLinkActive="active">Works</a></li>
        <li> <a routerLink="/career" routerLinkActive="active">Careers</a></li>
        <li> <a routerLink="/contact" routerLinkActive="active">Contact</a></li>
      </ul>

    </div>

    <button class="mobilemenubtn" (click)="openMenu()"><i class="fa fa-bars"></i></button>
  </div>
</nav>

<div class="nav-overlay full-screen-overlay dt-height show" id="nav-overlay">
  <button class="mobilemenubtn"><i class="fa fa-times" (click)="closeMenu()"></i></button>
  <div class="menucontainer">
    <img src="../../assets/img/logoicon.png"  style="padding-bottom: 20px; padding-left: 40px; height: 80px;"/>

  <ul >
      
    <li><a routerLink="/explore" routerLinkActive="active" (click)="closeMenu()">Explore</a></li>
    <li>
      <a  routerLink="/services" routerLinkActive="active" (click)="closeMenu()">Services</a>
     
    </li>
    <li><a routerLink="/works" routerLinkActive="active" (click)="closeMenu()">Works</a></li>
    <li> <a routerLink="/career" routerLinkActive="active" (click)="closeMenu()">Careers</a></li>
    <li> <a routerLink="/contact" routerLinkActive="active" (click)="closeMenu()">Contact</a></li>
    
  </ul>
</div>
  <img  src="../../assets/img/pattern.png" style="position: absolute;bottom: -35px; right: -30px;z-index: 1500; opacity: .2; " class="img-fluid">
</div>
