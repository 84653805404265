

<div class="banner-section newbanner morph-wrapper p60" style="
padding-bottom: 50px;">
    <div class="container">
      <div class="row">


        <div class="col-md-5 col-sm-5 col-xs-12 ">
          <h1 data-aos="fade-right">Every business is a
            digital buisness</h1>
          <p data-aos="fade-right" data-aos-delay="300"><span>Lets us unleash the true potential of your business by making it Digital.
			We CODE and DESIGN the imagination
            </span><br>
            <br>
            <a routerLink="/works" routerLinkActive="active" class="btn roundbtn" data-aos="fade-up" data-aos-delay="800">Works</a></p> <br>
        </div>
        <div class="col-md-7 col-sm-7 col-xs-12 hidden-xs text-center" data-aos="zoom-in">
          <!-- banner-svg -->
          <div class="bannerfull">
           
		 <svg version="1.0" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		 viewBox="0 0 1000 700" enable-background="new 0 0 1000 700" xml:space="preserve">
	<g id="People">
		<g>
			<rect x="24.7" y="60.2" display="none" fill="#F7F7F7" width="950.6" height="579.6"/>
			<g>
				
					<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="514.296" y1="189.3342" x2="511.9008" y2="246.8172" gradientTransform="matrix(1 0 0 -1 0 700)">
					<stop  offset="0" style="stop-color:#FFEBE3"/>
					<stop  offset="1" style="stop-color:#EDDAD4"/>
				</linearGradient>
				<path display="none" fill="url(#SVGID_1_)" d="M130,461.7h740.4c0,0,58.3-2.9,28.2,27.3c-30.1,30.1-146.2,22.9-218.1,66
					s-60.4-20.4-154.8-23.9c-94.4-3.6-382.6-9.6-385.2-35.9C137.9,468.8,98.6,462.4,130,461.7z"/>
				<g>
					
						<linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="266.838" y1="338.4322" x2="338.4294" y2="338.4322" gradientTransform="matrix(1 0 0 -1 0 700)">
						<stop  offset="0" style="stop-color:#40D5E6"/>
						<stop  offset="1" style="stop-color:#5577FF"/>
					</linearGradient>
					<path fill="url(#SVGID_2_)" d="M273.1,267c6.2-3,12.3,16.7,12.3,16.7c10.5,13.9,25.2-4.6,23.7,16.1
						c-2.5,36.3,41.7,11.4,21.3,49.3c-20.4,37.9,18,21.4,5.3,51.9s3.8,66.9-15.3,52c0,0-16.8-36.9-34.1-48.3
						c-17.3-11.4,10.4-24.9,5-40.4c-7.9-22.5-26.9-21.6-13.2-45.8C291.8,294.4,252.1,277,273.1,267z"/>
					<g>
						<path fill="#FFFFFF" d="M326.5,439.1c-8.3-5.8-18-46.4-18.5-70.5c-0.3-12.4-4-17.5-7.2-22.1c-3.1-4.3-5.7-8.1-4.2-16.7
							c3-16.5-4.8-23.6-4.9-23.7l0.9-1c0.3,0.3,8.5,7.6,5.4,24.9c-1.5,8.1,0.9,11.4,4,15.7c3.2,4.5,7.1,10,7.4,22.8
							c0.6,25.9,10.5,64.2,18,69.4L326.5,439.1z"/>
					</g>
					<g>
						
							<linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="319.3704" y1="298.4795" x2="384.1109" y2="298.4795" gradientTransform="matrix(1 0 0 -1 0 700)">
							<stop  offset="0" style="stop-color:#40D5E6"/>
							<stop  offset="1" style="stop-color:#5577FF"/>
						</linearGradient>
						<path fill="url(#SVGID_3_)" d="M329.6,442.2c-1.1,0-2.2-0.1-3.5-0.4c-3.2-0.6-5.3-2.3-6.2-5c-2.2-6.4,2.9-16.7,7.1-21.7
							c0.1-0.1,7.1-8.7,6.3-20.6c-0.1-2-0.3-3.8-0.5-5.5c-0.5-4.7-0.9-8.1,1.1-10.4c1.8-2,5.2-2.9,11.5-2.9c11-0.1,15.4-4.6,18.9-8.3
							c1.5-1.6,2.9-3,4.6-4l0,0c5.5-3,11.3-3.4,13.8-1c1.2,1.1,2.2,3.4,0.2,7.5c-1.8,3.8-4.2,6.2-6.1,8.1c-2.5,2.6-4.2,4.3-3.3,7.7
							c0.2,0.8,0.4,1.6,0.7,2.4c1.7,5.8,3.6,12.4-6.3,16.9c-2.1,0.9-4,1.7-5.7,2.4c-6.9,2.7-11.1,4.4-15.9,14.3
							c-0.9,1.9-1.7,3.8-2.5,5.5C340.1,435.8,337.3,442.1,329.6,442.2z M369.6,364.7c-1.5,0.8-2.8,2.2-4.3,3.7
							c-3.7,3.9-8.3,8.6-19.8,8.7c-5.8,0-9,0.8-10.5,2.4c-1.6,1.8-1.3,5-0.8,9.3c0.2,1.6,0.4,3.5,0.5,5.5c0.8,12.5-6.4,21.2-6.7,21.5
							c-4.2,4.9-8.9,14.8-6.9,20.4c0.8,2.2,2.5,3.6,5.2,4.1c9.4,1.9,11.9-4,16.2-13.7c0.8-1.8,1.6-3.6,2.5-5.6
							c5-10.5,9.6-12.3,16.6-15c1.7-0.7,3.6-1.4,5.7-2.3c8.9-4,7.3-9.4,5.6-15.2c-0.2-0.8-0.5-1.7-0.7-2.5c-1.1-4.2,1-6.3,3.7-9
							c1.9-2,4.1-4.2,5.8-7.7c0.9-1.9,1.6-4.4,0.1-5.9C379.7,361.5,374.5,362,369.6,364.7L369.6,364.7z"/>
					</g>
					<g>
						
							<linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="324.8641" y1="289.05" x2="373.4572" y2="289.05" gradientTransform="matrix(1 0 0 -1 0 700)">
							<stop  offset="0" style="stop-color:#40D5E6"/>
							<stop  offset="1" style="stop-color:#5577FF"/>
						</linearGradient>
						<path fill="url(#SVGID_4_)" d="M326.2,450.1l-1.4-0.1c0.1-1.3,1.8-31.6,13.9-40.4c13.3-9.7,17.5-20.2,19.5-27.2
							c2.4-8.3,14.4-10.5,14.9-10.6l0.2,1.3c-0.1,0-11.7,2.2-13.8,9.6c-2.1,7.2-6.4,17.9-20,27.9C328,419.2,326.2,449.7,326.2,450.1z
							"/>
					</g>
					
						<linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="307.3039" y1="328.2021" x2="363.8938" y2="328.2021" gradientTransform="matrix(1 0 0 -1 0 700)">
						<stop  offset="0" style="stop-color:#40D5E6"/>
						<stop  offset="1" style="stop-color:#5577FF"/>
					</linearGradient>
					<path fill="url(#SVGID_5_)" d="M354,297.5c-8.6-3-6.8,13.7-10.5,26c-3.6,12.3-40.6,5.4-25.1,36.2s-19.6,10.3-9.1,37.2
						c10.5,26.9,0.9,55.7,12.3,48.4c11.4-7.3,6.4-41.2,18.3-45.2s13.8-8.7,2.1-19.7c-11.7-10.9,30.8-21.8,20.3-43.9
						C351.7,314.4,364,300.9,354,297.5z"/>
					<g>
						<path fill="#FFFFFF" d="M326.9,444.1c-1.1-4.6-10.4-45.2-1.7-50.1c7.6-4.3,7.4-21.1,6.3-37c-1.1-14,17-20.6,17.8-20.9l0.5,1.3
							c-0.2,0.1-17.8,6.5-16.8,19.5c1.2,16.3,1.4,33.6-6.9,38.3c-6.6,3.7-0.5,36.3,2.4,48.6L326.9,444.1z"/>
					</g>
					<polygon fill="#38C0D1" points="346.6,429.6 334.2,476.4 306.7,476.4 294.3,429.6 				"/>
					<path display="none" fill="#199DD9" d="M346.6,429.6c0,0-27.1,5.1-27.1,46.8h-12.7l-12.3-46.8H346.6z"/>
				</g>
				<g>
					<g>
						<path fill="#38C0D1" d="M894.9,362.6c0,73.4-2.1,87.8-20.5,104.2c-7.2,6.4-20.5,19.8-58.8,26.5c-11.5,2-25.3,3.4-41.8,3.8
							c-6.7,0.2-12.9-0.1-18.6-0.7c-4.7-0.5-9.1-1.3-13.1-2.3c-42.1-10.6-45.3-46.6-26.8-77c4.9-8,10.5-15.4,16.3-22.3
							c9-10.6,18.7-20.1,27.4-28.9c8.1-8.1,15.4-15.7,20.7-23.2c2.2-3.2,4.1-6.3,5.6-9.5c0.3-0.7,0.7-1.5,1-2.2
							c1.1-2.4,2.3-4.7,3.5-6.9c2.2-4.1,4.5-7.9,6.6-11.3c1.7-2.7,3.4-5.1,5-7.3c5.4-7.4,9.4-11.6,9.4-11.6s0.5-0.2,1.4-0.6
							c0.5-0.2,1.1-0.5,1.8-0.7c0.3-0.1,0.6-0.2,0.9-0.3c0.3-0.1,0.7-0.2,1-0.4c0.3-0.1,0.7-0.2,1-0.3c0.4-0.1,0.8-0.3,1.3-0.4
							c0.3-0.1,0.5-0.2,0.8-0.3c0.2-0.1,0.3-0.1,0.5-0.2c0.1,0,0.1,0,0.2-0.1c5.5-1.7,13-3.4,21.1-3.7
							C864.9,286.1,894.9,298,894.9,362.6z"/>
					</g>
					<g>
						<path fill="#B7CEFF" d="M796.9,473.1c0,0,0.6,4.9,1.4,7.1c0.8,2.2,5.1,7.2,3.3,12c-2,5.2-10.7,0.2-14.2-1.4s-2.7-4.5-3.1-8
							c-0.4-3.5-2.1-3.6-1.5-6.1s4.5-7.3,4.5-7.3L796.9,473.1z"/>
					</g>
					<g>
						<g>
							<path fill="#EEA886" d="M789,466.8c0,0-2.2,3.1-2.2,3.2c0.1,0.1,9.5,5.8,10.2,4c0.5-1.5,0.7-3.6,0.7-3.6L789,466.8z"/>
						</g>
					</g>
					<g>
						<path fill="#B7CEFF" d="M757,476.6c0,0-2.9,2.5-5.5,6.4s-9.5,4.1-9.8,9.4s9.4,5.5,14.4,3.7c5.1-1.7,5.8-6.9,8.9-8.2
							c3.1-1.3,4.7-2.3,4.4-4.9c-0.2-2.5-1.9-6.6-1.9-6.6s-1.9,0.6-3,0.7C763.4,477.3,759.6,477.3,757,476.6z"/>
					</g>
					<g>
						<path fill="#E8E8E8" d="M791.7,332.8c0,0-1.9,11.5-3.5,19.2l0,0c-0.6,3.1-1.2,5.5-1.6,6.3c-0.4,1-4.1,4.9-8.5,9.5
							c-7.8,8.1-18.2,18.2-18.2,18.2l-4-7.7c0,0,9.2-10.8,15.4-17.9c2.9-3.3,5.1-5.9,5.5-6.2c1.3-1.2,2.8-12.6,4.9-22.1
							c2.1-9.5,6.4-14.7,6.4-14.7L791.7,332.8z"/>
					</g>
					<g>
						<path fill="#EEA886" d="M778.1,367.8c-7.8,8.1-18.2,18.2-18.2,18.2l-4-7.7c0,0,9.2-10.8,15.4-17.9
							C773.2,361.3,776.7,363.5,778.1,367.8z"/>
					</g>
					<g>
						<path fill="#EEA886" d="M757.4,473l-0.5,3.5c0,0,5,1.8,10.6-0.2l0.1-3.1L757.4,473z"/>
					</g>
					<g>
						<path fill="#E8E8E8" d="M828.7,384.4c0.3,1-53.2,0-53.2,0s14.8-10.8,15.8-13.6c1-2.9-3.8-20.1-4.4-25.8
							c-0.5-4.5-1.7-24.6,1.2-27.7c3.1-3.2,14.2-6.6,14.2-6.6l16.3,0.6c0,0,2.7,0.6,4.6,1.5c1.9,0.9,12.9,4.8,14.7,10.3
							S827,346.2,827,346.2s0,0.8-0.1,2.1l0,0c0,1.7-0.1,4.4-0.2,7.2c-0.1,3.7-0.2,7.8-0.2,10.7C826.5,373.2,828.4,383.4,828.7,384.4
							z"/>
					</g>
					<g>
						<path fill="#303669" d="M828.7,384.4c0,0,7.3,10.7,7.4,18c0.1,7.3-12.9,31.7-14.9,35.2c-5.9,10.5-22.2,33.2-22.2,33.2
							s-2.8-0.1-11.5-3.5c0,0,10.8-29.9,12.2-33c5.8-13.2,9-22.5,7.1-24.8c-2-2.3-7.3-4.5-8.8-5.9c-1.5-1.3-28.4,5.1-31.2,5.8
							c-7.3,1.9-4,14.6-2.4,20.2c2.7,9.5,5.1,43.8,5.1,43.8s-5.2,2.2-13.9,0.3c0,0-17.1-62-17.6-68.1c-0.5-6.2-0.8-16.3,41.2-24.1
							L828.7,384.4z"/>
					</g>
					<g>
						<path fill="#EEA886" d="M806.8,275.4c0,0-14.1-3.2-10.9,14.6c3.2,17.8,6.6,19.2,15.1,15.5s7.8-15.1,8.2-20.6
							C819.7,279.3,811.3,275.6,806.8,275.4z"/>
					</g>
					<g>
						<path fill="#EEA886" d="M803.1,315.3c0,0,6.3,6.6,16.1,0.6c0,0-2.2-14.4-1-21.4s-12.9,10.8-12.9,10.8c0.2,0.1,0.3,0.8,0.3,1.7
							c0,0.8,0,1.8-0.1,2.8C805.2,312.3,804.5,314.8,803.1,315.3z"/>
					</g>
					<g>
						<path fill="#3A3F47" d="M818.6,294c0,0-0.4-5.6-2.4-5.8c-2-0.2-2.4,2.1-2.4,2.1l-2.1,0.4c0,0,0.7-4.9-1.4-5.8
							c-2-0.9-13.2,2.7-14.9,1.3c-1.6-1.4-0.9-11.4,2.1-12.7c3-1.4,11.1-4.4,17.1-0.4c0,0,4.7-0.5,5.4,3.2
							C820.8,280.1,819.8,291.2,818.6,294z"/>
					</g>
					<g>
						<path fill="#A77860" d="M805.5,310c10.2-3.1,11-9.3,11-9.4c-3.4,5.8-10.8,6.5-10.8,6.5C805.7,307.9,805.6,308.9,805.5,310z"/>
					</g>
					<g>
						<path fill="#EEA886" d="M823.4,312.9c0,0-7.5,14.9-19.5,13.9c-12.1-1-2.1-15.9-1.5-16C803,310.6,814.7,307.7,823.4,312.9z"/>
					</g>
					<g>
						<path fill="#E8E8E8" d="M769.4,383.7c0,0,36.9,4.6,59.3,0.8l-42.8-5.3C786,379.1,785.7,380.2,769.4,383.7z"/>
					</g>
					<g>
						<path fill="#EEA886" d="M797,377.4l-2.8,1.4c0,0,0.6,4.4,2.1,5.7l3.1-0.8C799.5,383.7,798.9,378.9,797,377.4z"/>
					</g>
					<g>
						<path fill="#E8E8E8" d="M839.8,368.4c-0.6,0.6-7.8,3.6-16,6.7l0,0c-11.2,4.3-23.8,8.4-23.8,8.4l-2.9-6.2
							c0,0,13.2-7.2,22.2-12.2c4.2-2.4,7.5-4.3,8-4.7c1.4-1.4,1.8-3.6,0.8-7.4c-1.1-3.8-3.5-21.4-3.8-23.5s0.7-7.5,5.8-7.8
							s7.8,1.2,7.8,1.2s1.3,0.5,2.2,13C840.9,348.4,841.1,366.9,839.8,368.4z"/>
					</g>
					<g>
						<path fill="#B0B0B0" d="M826.9,348.3L826.9,348.3c0,1.7-0.1,4.4-0.2,7.2c-5-12.3-2.1-22.1-2.1-22.3
							C824.1,336.6,826.9,348.1,826.9,348.3z"/>
					</g>
					<g>
						<path fill="#B0B0B0" d="M788.2,352L788.2,352c-0.6,3.1-1.2,5.5-1.6,6.3c-1.7-8.3-0.2-18.9-0.2-18.9
							C786.7,344.5,788.2,352,788.2,352z"/>
					</g>
					<g>
						<path fill="#EEA886" d="M823.8,375.2L823.8,375.2c-11.2,4.3-24.3,8.5-24.3,8.5l-2.5-6.3c0,0,13.2-7.2,22.2-12.2
							C819.2,365.1,822.5,366.7,823.8,375.2z"/>
					</g>
					<g>
						<polygon fill="#444C94" points="796.2,384.1 743.7,383.3 770.3,396.7 					"/>
					</g>
					<g>
						<polygon fill="#303669" points="770.3,396.7 796.2,386.7 796.2,384.1 768.2,394.2 					"/>
					</g>
					<g>
						<g>
							<path fill="#EEA886" d="M794.9,378.9c0,0-3.3-0.5-5.5-1c-1.8-0.4-6.1,0.2-7.2,1.3c-1.1,1.1-4.6,9-5.3,9.4
								c-0.7,0.5,0.3,1.6,1.5,1c0,0,1.1,3.4,3.7,2.1c2.7-1.3,4.5-4.1,5.9-4.6s9.5-3,9.5-3S797,378,794.9,378.9z"/>
						</g>
					</g>
					<g>
						<g>
							<path fill="#EEA886" d="M782.9,378.6c0,0-2.1,1-2.7,1.5c-0.6,0.5-2.8,2.1-3.3,2.5s0.8,1.5,4.8-0.2S782.9,378.6,782.9,378.6z"
								/>
						</g>
					</g>
					<g>
						<polygon fill="#303669" points="770.3,396.7 754.6,354.1 704.3,354.9 719.2,394.2 					"/>
					</g>
					<g>
						
							<ellipse transform="matrix(0.8944 -0.4472 0.4472 0.8944 -90.0963 368.1416)" fill="#518AFA" cx="734.7" cy="374.9" rx="4.3" ry="6.2"/>
					</g>
					<g>
						<path fill="#EEA886" d="M813.9,287.9c0.2-1.6,3.3-2.1,3.5,0.2c0.2,2.3-1.4,6.5-2.6,6.1C813.6,293.8,813.6,290.1,813.9,287.9z"
							/>
					</g>
				</g>
				<g>
					<path fill="#E8E8E8" d="M503.4,457.8c-0.4,2.5-15.4,13-18.3,15.1c-2.4,1.7-4.4,2.5-8,3.8l0,0c-8.1,2.1-59.2,17.4-59.2,17.4
						c-0.1-0.5-0.2-1-0.5-1.6c-1.9,0.8-3.9,1.3-5.1,1.6c-1.9,0.6-17,1.1-20.1,1.7s-9.3,1.5-11.8-0.8c-2.5-2.4,1.5-7.5,5-8.8
						c3.4-1.3,16-1.7,18.3-1.9c2.3-0.2,5.2-1.1,6.7-0.6c0.4,0.2,2.4-0.4,5-1.3c-0.1-0.9-0.1-1.5-0.1-1.5l13.4-3.9l-2.5-23.8
						c0,0,5.8-22,29.2-5.2c0,0,5.2,1.5,7,2c0.1,0,0.3,0,0.6,0c3.6-0.3,18.8-4.9,24.4-4.9c5.9,0,7.8-0.4,10,0.7
						C499.4,447,503.8,455.3,503.4,457.8z"/>
					<path fill="#E8E8E8" d="M479.5,495c-2.5,2.4-8.7,1.4-11.8,0.8c-3.1-0.6-18.2-1.1-20.1-1.7s-5.5-1.4-8-3.1
						c-0.2-0.1-0.7-0.3-1.3-0.6l0,0l-3.8,2.7L382,476.4c-3.1-0.9-5.6-2.2-7.3-3.6c-2.7-2.4-17.9-12.6-18.3-15.1s4-10.8,6.2-11.9
						c2.1-1.1,4-0.7,10-0.7c5.9,0,23.2,5.3,25,4.9c0.8-0.2,2.5-1.1,4.1-1.9c1.9-1,3.7-2.1,3.7-2.1c25.5-11.4,28.4,7.3,28.4,7.3
						l-1.5,23c0,0,2.1,1.1,4.9,2.4l12.7,3.6l-1.7,1.2c0.7,0.2,1.2,0.3,1.4,0.3c1.4-0.5,4.4,0.4,6.7,0.6c2.3,0.2,14.9,0.6,18.3,1.9
						C478,487.5,482,492.6,479.5,495z"/>
					<path fill="#5478B3" d="M401.7,448.1c1.8,0.1,13.7,7,16.5,8.7c-4.3-2.2-20.6-6.7-20.6-6.7C398.4,449.8,400.1,449,401.7,448.1z"
						/>
					<path fill="#5478B3" d="M462.9,450.1c0,0-20.5,5-24.2,7.1c0.2-0.1,10.8-8.2,16.4-9.3c0,0,5.5,1.7,7.3,2.2
						C462.4,450.1,462.6,450.1,462.9,450.1L462.9,450.1z"/>
					<path fill="#EEA886" d="M479.5,495c-2.5,2.4-8.7,1.4-11.8,0.8c-3.1-0.6-18.2-1.1-20.1-1.7c-0.5-0.2-1.2-0.4-2-0.6l0,0
						c-1.9-0.6-4.2-1.3-6-2.5c-0.2-0.1-0.7-0.3-1.3-0.6l9.5-6.9l0.3-0.2c0.7,0.2,1.2,0.3,1.4,0.3c1.4-0.5,4.4,0.4,6.7,0.6
						c2.3,0.2,14.9,0.6,18.3,1.9C478,487.5,482,492.6,479.5,495z"/>
					<path fill="#FFFFFF" d="M479.5,495c-2.5,2.4-8.7,1.4-11.8,0.8c-3.1-0.6-18.2-1.1-20.1-1.7c-0.5-0.2-1.2-0.4-2-0.6l0,0l0,0
						c0,0,4.2-4.1,2.2-9.9l0.3-0.2c0.7,0.2,1.2,0.3,1.4,0.3c1.4-0.5,4.4,0.4,6.7,0.6c2.3,0.2,14.9,0.6,18.3,1.9
						C478,487.5,482,492.6,479.5,495z"/>
					<path fill="#E55353" d="M406.9,453.4c0,0.1,0,0.1,0,0.1C406.7,453.7,406.7,453.6,406.9,453.4z"/>
					<path fill="#5779D4" d="M475.6,425.6c-0.2-0.4-11-17.4-14-25.2c-2.9-7.8-10-11.3-13.3-12.4c-1.2-0.4-3.3-1.1-5.5-1.8
						c-4-1.3-8.4-2.7-8.4-2.7h-13.9c0,0-4.8,1.6-9.9,3.4l0,0c-4.7,1.7-9.7,3.5-11.3,4.3c-3.5,1.7-9.4,14.2-10.5,15.6
						c-1.2,1.4-10.3,18.4-11.6,24.9c-1,5.1,29,19.9,29.8,21.8c1-1.1,5.3-5.2,5.3-5.2s-1.4-0.9-3.4-2.2c8,0.4,27.7-0.3,30.5-0.2
						c0.6,0,1.2,0,1.8,0l0,0c0.9,0.6,6.5,5.6,6.5,5.6s13.9-5.8,20.2-12.2C477,430,476.6,427.6,475.6,425.6z M396.8,436.8
						c-3-3.6-7.1-5.7-6-7.3c1-1.6,9.7-16.6,9.7-16.6s4.5,9.9,5.8,15.1s1.2,7.5,0.6,9.1c-0.5,1.4-0.9,2.4-1.3,6.7
						C402,441.3,398.1,438.5,396.8,436.8z M458.1,435.1c-1.2,1.3-5.8,4.3-9.9,6.7c-0.3-2.9-0.8-7.4-0.8-9c-0.1-2.5,0.9-9.5,2.2-12.2
						c1.3-2.7,2.1-10.1,2.1-10.1s10.5,14.3,12.2,15.8C465.5,427.8,462,430.7,458.1,435.1z"/>
					<g>
						<path fill="#EEA886" d="M429.1,345.3c0,0,10.8,1.7,9.2,18.7c-1.7,17-11.2,15.6-11.9,15.6c-0.6,0-9.6,0.3-10.3-20.4
							C416.1,359.1,415.8,343.8,429.1,345.3z"/>
					</g>
					<g>
						<path fill="#EEA886" d="M438.5,359.2c0,0,1.2-1.3,1.6,0s-1.6,7-1.7,7.3c0,0-0.2,0.5-0.5,0.2
							C437.6,366.4,438.5,359.2,438.5,359.2z"/>
					</g>
					<g>
						<path fill="#EEA886" d="M416.4,359c0,0-1.2-1.3-1.6,0c-0.5,1.3,1.5,7,1.6,7.3c0,0,0.2,0.5,0.5,0.2
							C417.1,366.2,416.4,359,416.4,359z"/>
					</g>
					<g>
						<path fill="#434343" d="M438.5,361.8l-1.6,2.1c0,0,0-4.5-0.2-5.3c-0.3-0.7-0.6-2.9-0.8-3.4c-0.2-0.5-9.3-3.1-17.4,0.7
							c0,0,0.4,3.3-0.4,3.6c-0.8,0.3-0.4,5.3-0.4,5.3l-1.3-1.4c0,0-2-13.1-1.5-14.4c0.5-1.4,2-9.5,18.5-4.7c0,0,5.1-1.1,6.2,2.5
							C440.7,350.3,438.5,361.8,438.5,361.8z"/>
					</g>
					<path fill="#747575" d="M413.1,470.3c0,0-16.6-9.9-23.9-11.5c-6.9-1.5-9.4-4-9.4-4s10.9,0,18.1,5.2
						C403,463.4,413.1,470.3,413.1,470.3z"/>
					<path fill="#747575" d="M427.9,477.2c0,0,28.9-15.2,40.7-18.5c6.8-1.9,9.4-4,9.4-4s-17.9,1.7-28.7,8.3
						C444,466.1,427.9,477.2,427.9,477.2z"/>
					<path fill="#EEA886" d="M417.3,492.5c-1.9,0.8-3.9,1.3-5.1,1.6c-1.9,0.6-17,1.1-20.1,1.7s-9.3,1.5-11.8-0.8
						c-2.5-2.4,1.5-7.5,5-8.8c3.4-1.3,16-1.7,18.3-1.9c2.3-0.2,5.2-1.1,6.7-0.6c0.4,0.2,2.4-0.4,5-1.3
						C415.4,483.9,417.3,492.5,417.3,492.5z"/>
					<path fill="#FAA146" d="M427.9,491.1l28.4-8.4l-5.6-1.7C450.7,481,428,489.2,427.9,491.1z"/>
					<polygon fill="#B57433" points="427.9,491.1 441.2,487 432,492.4 				"/>
					<path fill="#525252" d="M418.8,457.1c0,0-0.2-0.1-0.6-0.4C418.4,456.9,418.6,457,418.8,457.1z"/>
					<path fill="#FFFFFF" d="M412.2,494.2c-1.9,0.6-17,1.1-20.1,1.7s-9.3,1.5-11.8-0.8c-2.5-2.4,1.5-7.5,5-8.8
						c3.4-1.3,16-1.7,18.3-1.9c2.3-0.2,5.2-1.1,6.7-0.6C410.3,483.7,406.5,489.5,412.2,494.2z"/>
					<path fill="#525252" d="M402.8,454.7l-4.4-24.9l56.6-1.3l-3.6,24.5c0,0-18.1,6.6-24.2,10.5C427.2,463.5,411.7,457.1,402.8,454.7
						z"/>
					<ellipse fill="#6E6E6E" cx="427.4" cy="444.8" rx="3.2" ry="3.7"/>
					<path fill="#EEA886" d="M434.5,383.4L434.5,383.4c-0.7-1.6-0.4-8.9-0.4-8.9s-13.8-3.5-13.4-0.8s-1.2,10-1.2,10l-7.1,2.4
						c0,0,7.5,20.3,16.2,17.7c8.7-2.6,12-18.4,12-18.4L434.5,383.4z"/>
					<path fill="#A77860" d="M421,376.7c0,0,2.2,2.8,6,2.8c2.5,0,4.3-0.6,6.8-3.1c0,0-1,4.6-6.8,5C427.1,381.5,422.9,382.1,421,376.7
						z"/>
				</g>
				<g>
					<g>
						<g>
							<g>
								<path fill="#464240" d="M658.9,223.8c0,0,8-0.8,10.9,6.9c2.9,7.8,5.6,10.3,6,14.4c1.4,16.5,9.1,12.7,6.9,22.2
									c-2.2,9.5-3.7,10.9-3.7,10.9l-18.4-3.6l-18.6,0.1c0,0,7.3-3.6,5.7-11.4c-1.6-7.8-5.7-20.5-3.4-29.1
									C646.5,225.5,654.1,224.4,658.9,223.8z"/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<path fill="#EB4747" d="M653.8,478.3c0,0-5.8,7.9-4.9,9.2c0.8,1.3,7.1,1.5,10.9,0c3.8-1.5,3.9-10.9,5.7-15
								c0.7-1.7-0.8-6.7-3-7.1s-4.7,0.8-4.7,0.8S659.9,472,653.8,478.3z"/>
						</g>
					</g>
					<g>
						<g>
							<path fill="#EEA886" d="M657.7,466.2c0,0-0.5,3.7-1.2,6.4c-0.7,2.6-4.6,8.7-4.5,9.4c0.2,0.7,5.1,1.4,7.1-0.5s3.6-7.3,3.7-10.6
								c0.2-3.3-0.5-5.4-0.5-5.4S659.6,464.2,657.7,466.2z"/>
						</g>
					</g>
					<g>
						<g>
							<polygon fill="#EB4747" points="665.4,472.5 665.4,481 664.3,481 664.3,474.5 						"/>
						</g>
					</g>
					<g>
						<g>
							<path fill="#EB4747" d="M668.9,478.3c0,0-5.8,7.9-4.9,9.2c0.8,1.3,7.1,1.5,10.9,0c3.8-1.5,3.9-10.9,5.7-15
								c0.7-1.7-0.8-6.7-3-7.1s-4.7,0.8-4.7,0.8S675,472,668.9,478.3z"/>
						</g>
					</g>
					<g>
						<g>
							<path fill="#EEA886" d="M672.8,466.2c0,0-0.5,3.7-1.2,6.4c-0.7,2.6-4.7,8.7-4.5,9.4c0.2,0.7,5.1,1.4,7.1-0.5s3.6-7.3,3.7-10.6
								c0.2-3.3-0.5-5.4-0.5-5.4S674.7,464.2,672.8,466.2z"/>
						</g>
					</g>
					<g>
						<g>
							<polygon fill="#EB4747" points="680.5,472.5 680.5,481 679.4,481 679.4,474.5 						"/>
						</g>
					</g>
					<g display="none">
						<g display="inline">
							<path fill="#EEA886" d="M643.2,335c0,0-4.4,24-5.5,30c-1.2,6-4.5,35.1-4.8,40.1c-0.4,5,10.8,28.8,11.4,30.4
								c11.6,27.5,13,33.7,13,33.7s3.8,2.4,5.6-0.5c0.5-0.9-7.2-32.4-8.4-41.1c-1.2-8.1-4.2-20.7-3.4-23.6s8.4-42.2,9.8-43.9
								c1.4-1.7,3.5,0.9,4.4,4.6c0.8,3.7,2.6,35.2,3.7,39.3c1.1,4,0.2,17.6,0.8,24.4s3,38.8,3,38.8s4.7,0.8,5.2,0.1
								c0.5-0.8,4.6-35.4,4.7-43.6c0.1-8.1,0.2-19.4,0.5-22.9c2.2-33.7,6.3-38.1-0.9-62.4C664.5,279.1,643.2,335,643.2,335z"/>
						</g>
					</g>
					<g>
						<g>
							<path fill="#2F4563" d="M643.2,335c0,0-4.4,24-5.5,30c-1.2,6-4.5,35.1-4.8,40.1c-0.4,5,10.4,28.8,10.6,30.5
								c0.2,1.8,9.1,27.1,9.1,27.1s8.9-0.2,11.4-2.5c0.5-0.5-8.2-23.9-9.5-32.6c-1.2-8.1-4.2-20.7-3.4-23.6s8.4-42.2,9.8-43.9
								c1.4-1.7,3.5,0.9,4.4,4.6c0.8,3.7,2.6,35.2,3.7,39.3c1.1,4,0.2,17.6,0.8,24.4s-0.7,31.1-0.7,31.1s11.7,0.6,12.2-0.1
								c0.5-0.8,1.3-27.6,1.5-35.7c0.1-8.1,0.2-19.4,0.5-22.9c2.2-33.7,6.3-38.1-0.9-62.4C664.5,279.1,643.2,335,643.2,335z"/>
						</g>
					</g>
					<g>
						<g>
							<path fill="#199DD9" d="M683.9,343.1c0.7-0.3-4.8-18.3-5.9-23.7c-1-4.9-4.3-9.3-1.1-17.6c1.9-4.7,1.7-10.4,1.7-10.4
								s5,11,5.4,13.8c0.5,2.8-17.2-2.3-17.4-1.9c-0.5,0.7-1,5.5-1,7.2c0,1.4,25.6,7.4,28.6,2.1c0.9-1.5-4.7-30.6-8.3-34.5
								c-5.9-6.4-21.4-11.8-21.4-11.8s-4.2-2.3-11.7,0.1s-17.9,9.6-19.7,11.5s-9,30.3-9.7,34c-0.6,3.5-0.4,10.7,7.5,9.8l21.3-4.9
								l-1.3-6.5l-16,1.3c-3.3,0.6-1.7-2.3-1.3-3.9s4.4-16,4.4-16c-0.2,4,2.8,17.2,4.4,21.7c1.6,4.6-1.9,28.9-2.1,31.6
								C640.5,344.9,653.4,355.4,683.9,343.1z"/>
						</g>
					</g>
					<g>
						<g>
							<path fill="#EEA886" d="M668.2,267.5c0,0-5.3,10.9-9.7,14c0,0-7.9-11.9-7.5-14.6C651,267,659.8,263.1,668.2,267.5z"/>
						</g>
					</g>
					<g>
						<path fill="#EEA886" d="M668.4,304.2c0,0-2.4-1-3.1-1.9c-0.6-0.9-2.8-1.4-4.4-0.9c-1.6,0.5-0.7,7.4-0.7,7.4l8.4,1.1
							L668.4,304.2z"/>
					</g>
					<g>
						<g>
							<path fill="#EEA886" d="M653.1,309.4c0,0,0.8-4.4,0.6-5.3c-0.2-0.8,1.4-1.6,1.7,0.4c0.2,2,0.3,3.3,0.3,3.3L653.1,309.4z"/>
						</g>
					</g>
					<g>
						<path fill="#51578C" d="M656.6,312.5l6.1-0.3c0.8,0,1.4-0.7,1.4-1.5l0.2-13.5c0-0.8-0.7-1.5-1.5-1.5l-7.4,0.2
							c-0.8,0-1.5,0.8-1.4,1.6l1,13.5C655.1,311.9,655.8,312.5,656.6,312.5z"/>
					</g>
					<g>
						<g>
							<path fill="#EEA886" d="M653,309.8c0,0-0.1-1.3,2.3-2.4c3.2-1.5,7.5-3.1,8.2-2.7c1.1,0.6,0.5,1.4-1.5,2.1s-2.7,0.9-2.7,0.9
								s4-0.4,4.9,0.1s0.9,1.6,0.3,1.8c-0.7,0.2-2.2,0.3-2.2,0.3s1.7,0.1,1.9,0.7c0.1,0.5,0.2,1.2-1.4,1.5c0,0,1.1,1.2-0.5,2
								s-4.6,1.5-6,1.7c-3.1,0.5-10,0.1-10,0.1l2.8-4.8c0,0,1.6,0.1,2.7-0.2C653.1,310.2,653,309.8,653,309.8z"/>
						</g>
					</g>
					<g>
						<polygon fill="#5779D4" points="651.2,310.2 652.5,316.7 638.9,319.8 638.4,311.2 					"/>
					</g>
					<g>
						<path fill="#5779D4" d="M666.7,303.3c0,0-1.1,2.4-1,7.2c0,0,8.4,3.1,15,3.8V306L666.7,303.3z"/>
					</g>
					<g>
						<path fill="#4469BC" d="M652.5,316.7L643,322c0.1-1.1,0.1-2.2,0.1-3.1L652.5,316.7z"/>
					</g>
					<g>
						<path fill="#4469BC" d="M677.6,317.4c-7.6-3-10.7-5.8-11.5-6.6c-0.2-0.2-0.3-0.3-0.3-0.3l10.9,3.2l0,0
							C676.9,314.9,677.2,316.2,677.6,317.4z"/>
					</g>
					<g>
						<g>
							<g>
								<path fill="#EEA886" d="M659.9,228.3c0,0,10.6,1.5,9.1,17.2c-1.6,15.7-10.9,14.5-11.6,14.4c-0.6,0-9.4,0.3-10.2-18.8
									C647.3,241.1,646.9,227,659.9,228.3z"/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<path fill="#EEA886" d="M664.6,257.2c0,0-0.7,8.2,0.9,10.6c1.6,2.4-1.3,5.8-7.4,4.6c-6-1.1-5.7-4.5-5.7-4.5s1.7-5,1.2-9.1
									C653.2,254.8,664.6,257.2,664.6,257.2z"/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<path fill="#464240" d="M658.7,234c0,0,5,5.2,7.3,7.9c2.3,2.6,2.6,7.8,2.6,7.8s3.9-4.7,2.3-10.3c-1.6-5.5-5.5-16.3-15.2-13
									s-10.1,17.9-9,25.1C646.7,251.4,651,235.5,658.7,234z"/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<path fill="#A77860" d="M654.5,259.3c0,0,4.5,2.1,8.9-1c0,0-1.2,2.5-4.5,2.9C655.7,261.5,654.5,259.3,654.5,259.3z"/>
						</g>
					</g>
					<g>
						<path fill="#464240" d="M653,262.1c0,0-14.6,13-10.8,21.1c3.2,6.9,14.7,2.7,12.8-4.6C653.1,271.4,653,262.1,653,262.1z"/>
					</g>
					<g>
						<circle fill="#48484D" cx="656" cy="298.7" r="0.9"/>
					</g>
				</g>
				<g>
					<g>
						<polygon fill="#5C5049" points="261.4,479.4 250.7,408.4 252.7,408.1 263.4,479.1 					"/>
					</g>
					<g>
						<polygon fill="#5C5049" points="248.3,479.4 237.7,408.4 239.7,408.1 250.4,479.1 					"/>
					</g>
					<g>
						<polygon fill="#5C5049" points="184.1,479.4 182.1,479.1 192.7,408.1 194.8,408.4 					"/>
					</g>
					<path fill="#FAB46C" d="M254,409.2l8.4-88c0,0-14.6-1.9-23.5-1.4l-13.3,89.4H254z"/>
					<rect x="225.6" y="404.7" fill="#FAB46C" width="28.4" height="4.5"/>
					<path fill="#FAB46C" d="M207.1,405.6l-4.4-83.8c0,0,37-4,59.7-0.6L207.1,405.6z"/>
					<path fill="#FCCCA2" d="M236.6,294.6c0,0,5.7-16.8-5.2-22s-20.2,2.3-21.9,6.4s-1.3,11.5,0.2,16.6c1.5,5.2,1.3,13-2,15.6
						c-3.2,2.6-7.3,7-7.1,15.8c0.1,5.5,21.7-14.1,21.7-14.1L236.6,294.6z"/>
					<path fill="#D4C3BD" d="M222.1,475c0,0-0.3,11.8,1.4,12.6c1.7,0.7,8.1-3.4,10.9-7.5c2.8-4.1-3.5-13.6-4.6-18.8
						c-0.4-2.2-5.4-6.1-7.8-5c-2.4,1.1-4.2,4-4.2,4S223.9,464.5,222.1,475z"/>
					<g>
						<path fill="#DE9673" d="M217.7,460.3c0,0,2.1,4,3.2,7.2c1.1,3.1,1.3,11.8,2,12.4s6-2.1,6.8-5.4c0.7-3.2-1.5-9.7-3.5-13.1
							c-2.1-3.4-4.2-5.1-4.2-5.1S218.3,457,217.7,460.3z"/>
					</g>
					<polygon fill="#D4C3BD" points="229.8,461.2 235.5,469.7 234.4,470.4 230,463.9 				"/>
					<path fill="#DE9673" d="M209,368.2c0,0,2.8,4.8,0,8.2c-2.8,3.5-21.8,13-21.6,18.5c0.1,5.5,3.6,14.1,4.9,16.3
						c1.2,2.2,5.8,11.2,8.2,16.5s16.2,30.7,18.9,36.4c2.7,5.7,1-22.9,1-22.9l-13.8-40.5l32.3-31.5C238.9,369.3,235,360.6,209,368.2z"
						/>
					<path fill="#2F4563" d="M221.3,314.6c0,0-9,2-12.5,4.1c-3.6,2.1-11.5,11.6-11.5,15.8c0,4.1,34.5,8,34.5,8l6.5,23.9
						c0,0,17.5-4.3,19.5-7.8c2.1-3.5-6.9-28.7-8.1-34c-1.2-5.3-7.8-8.9-15.1-9.9C227.5,313.5,221.3,314.6,221.3,314.6z"/>
					<path fill="#EEA886" d="M242.5,369c0,0-0.6,4.1,1,5.8c1.7,1.7,9.3,11.8,5.3,21.8c-3.7,9.1-11,8.9-11,8.9h-11.2
						c0,0,3.2,16.4,2.2,27.2c-1,10.8-6,35.5-6.1,36.7s-4.7,5.1-6.1,3.2c0,0-0.1-14.9-0.9-20.5c-0.7-5.6-3.8-28.1-4.8-31.4
						c-0.9-3.3-6.4-13.4-6.4-14.4s-5.4-17.3,6.4-21.5C222.8,380.7,226.9,369.1,242.5,369z"/>
					<path fill="#E6D3CC" d="M211.1,484.1c0,0-7.7,9-6.8,10.6s8.4,2.4,13.2,1s5.8-12.8,8.2-17.5c1-2-0.4-8.2-2.9-8.8
						c-2.6-0.6-5.8,0.5-5.8,0.5S219.1,477.2,211.1,484.1z"/>
					<g>
						<path fill="#EEA886" d="M217,470c0,0-0.9,4.5-2,7.6s-6.4,10-6.3,10.9c0.1,0.9,6,2.2,8.6,0.1s5-8.5,5.5-12.4c0.5-4,0-6.6,0-6.6
							S219.5,467.8,217,470z"/>
					</g>
					<polygon fill="#E6D3CC" points="225.7,478.3 224.9,488.5 223.6,488.4 224.2,480.5 				"/>
					<path fill="#2F4563" d="M248.8,396.6c-3.5,9.2-11,8.9-11,8.9h-11.2c0,0-4.7-7.4-16.1-8.7c-2.5-0.3-5.4-0.3-8.6,0.2h-0.1
						c-15.2,2.2-12.2,7.8-12.2,8c-1.3-3.1-2.6-8-2.3-10.6c1.5-12,18.8-14.5,21.6-18s0-8.2,0-8.2c26-7.7,29.9,1,29.9,1
						c1.1-0.2,4.1-1.8,5.3-1.8c0,0,1.3,2.8,2.2,5.4C247.3,375.1,252.6,386.6,248.8,396.6z"/>
					<polygon fill="#DED1C5" points="238,330.5 241.9,366.2 191.2,366.2 187.4,330.5 				"/>
					<rect x="237.4" y="363" fill="#DED1C5" width="8.5" height="3.2"/>
					<rect x="156.4" y="366.2" fill="#BC8551" width="139.4" height="4.9"/>
					<path fill="#EEA886" d="M226.9,275.5c0,0,11.3,3.4,9.7,19.1s-9,14.9-9.7,15s-9.5,0.7-12.3-18.4
						C211.9,272.2,226.9,275.5,226.9,275.5z"/>
					<path fill="#EEA886" d="M232.3,306.8c0,0-0.6,5.2,0.4,8.5c1,3.2-13.2,3.3-13.2,0.7c0-3,1.5-5.6,0.1-10.7
						C218.3,300,232.3,306.8,232.3,306.8z"/>
					<path fill="#FCCCA2" d="M224,282.5c0,0,6.2,9.8,12.6,12.1c0,0,3.5-16.4-11.1-19.9c-14.6-3.4-12.3,16.6-11.2,22
						C214.3,296.7,217.4,288.8,224,282.5z"/>
					<path fill="#A77860" d="M221.3,307.4c0,0,4.5,4.7,10.2,0.5c0,0-0.5,3-4.6,3.4C222.8,311.7,221.3,307.4,221.3,307.4z"/>
					<path fill="#23344A" d="M215.7,281.1c0,0,3.2-6.3,8.7-6.6c5.5-0.3,8.7,4.5,8.7,4.5s-2.1-5.4-8.7-5.4
						C217.9,273.6,215.7,281.1,215.7,281.1z"/>
					<path fill="#EEA886" d="M234.2,314.5c0,0-0.2,10.9-8.8,11.2c-8.6,0.3-6.8-10.4-6.8-10.4S224.8,312.7,234.2,314.5z"/>
					<rect x="292.2" y="371.1" fill="#807066" width="3.6" height="111.8"/>
					<rect x="156.4" y="371.1" fill="#807066" width="3.6" height="111.8"/>
				</g>
				<g>
					<path fill="#EEA886" d="M579.2,272.3c0,0,11.1,3.3,10.4,18.3C588.9,305.7,579.2,272.3,579.2,272.3z"/>
					<g>
						<path fill="#EEA886" d="M578.2,272.3c0,0,0.9,1.2-0.2,8.7c-1.1,7.5,1,10.6,1.2,13.8c0.2,3.1,3.9,23.3,4,24.2
							c0.1,0.9,0.6,11.6,2,14.5c1.4,2.8,11.9,19,12.1,19.8c0.1,0.8,5.1-2.8,5.1-2.8s-9.4-19.6-10-21.5c-0.8-2.4-3.1-8.3-3.2-11.5
							c-0.3-7.6,0.3-25.3,0.1-29.1C588.7,281,583,273.4,578.2,272.3z"/>
					</g>
					<g>
						<g>
							<g>
								<path fill="#EEA987" d="M592.3,336.7c0,0,1.7,3.6,2.6,3.9c1,0.2,2.1,1.4,3,1.8c0.8,0.4,3.5,0.6,4.2,1.3
									c0.3,0.3,1.4,2.7,2,3.2s2.1,1.4,1.6,1.9c-0.4,0.5-1.9,0.1-1.9,0.1s0.6,0.5,0.3,1.1c-0.5,1.2-1.3,1.2-1.3,1.2s0.3,0.9-0.1,1.3
									c-0.7,1-1.5,1-1.5,1s-0.1,0.7-0.7,1.2c-0.8,0.7-1.7,0.3-1.7,0.3s-0.8,1.1-1.5,0.9s-1.1-0.7-1.7-1.1c-0.5-0.3-4.1-4.5-5.4-8.3
									c-1.3-3.9-3.6-7-3.6-7L592.3,336.7z"/>
							</g>
						</g>
					</g>
					
						<rect x="566.5" y="320.5" transform="matrix(0.7767 -0.6299 0.6299 0.7767 -79.3997 448.3393)" fill="#214361" width="52.2" height="31.3"/>
					<g>
						<g>
							<path fill="#DB9C7C" d="M603.6,348.4c0,0-3.1-3.5-4.7-3.4c-1.6,0.1-0.5,1.4-0.5,1.4s-1-1.3-2.1-0.6c-1.1,0.7,0.2,2.2,0.2,2.2
								s-1-0.8-1.9,0.3c-0.8,1.1,0.6,2.1,0.6,2.1s-2.1-0.3-1.6,1.4c0.7,2.9,3.8,4.3,3.8,4.3s0.9,0.2,1.6-0.9c0,0,1.9,0.7,2.5-1.5
								c0,0,2.3-0.5,1.5-2.3C603,351.2,605.4,350.4,603.6,348.4z"/>
						</g>
					</g>
					<path fill="#FCCCA2" d="M569.1,228.2c0,0-16-1.3-15.6,15.5c0.4,16.9,3.1,19.4,0,31.3s15.6-6.8,15.6-6.8V228.2z"/>
					<path fill="#EEA987" d="M551.6,332.5c0,0-5.9,7.5-8,12.5s-5.6,15.9-5.1,24.4s3.2,24.3,2.4,27.9s-1.6,11-2.5,13.6
						c-0.9,2.5-4.3,10-4.7,17.6c-0.3,7.6,4.9,41.2,4.6,44.4c-0.2,3.1,5.9,1.3,5.9,1.3s2.1-30.3,3.2-35.6c1.2-5.2,2.5-22.9,3.6-24.2
						c1-1.3,3.9-14.5,3.5-16.6s11.4-33.4,12.1-39.6c0.7-6.3,18.1-25.6,18.1-25.6S569.5,326.7,551.6,332.5z"/>
					<path fill="#EEA987" d="M579.8,332.5c0,0,0.3,3.2,0.6,4.3c0.3,1.2,8.3,30.7,9.2,42c0.8,11.4,2.9,21,3.2,23.4s-0.3,10.5-1,12.6
						s3.5,51.3,3.8,52.5s-2.8,1.5-5,1.2s-13.3-36.1-12.6-42.2c0.7-6,1.4-18.9-0.6-24.1s-19.7-43-19.8-45.3
						C557.5,354.7,549.9,314.8,579.8,332.5z"/>
					<path fill="#EEA987" d="M544.2,470.4c0,0,0.6,4,1.2,5.1c0.6,1.1,2.3,6.6,3.1,7.7c0.8,1,2.5,8.8,0,9.8c-2.5,0.9-8.4,2.5-10.3-0.6
						c-2-3.1-2.2-6.2-1.9-8.6s2.7-13.4,2.6-14.4C538.8,468.2,544.2,470.4,544.2,470.4z"/>
					<path fill="#FFFFFF" d="M547.5,481c0,0,0.3,6.1-4.3,6.1s-6.6-5.5-6.6-5.5s-1.6,2.7,0,8.5s8.1,4.6,9.5,4.4
						C547.5,494.2,554.4,492.4,547.5,481z"/>
					<path fill="#FFFFFF" d="M595.1,465c0,0,3.2,5.8,5.1,6.9c2.4,1.5,7,6.1,8.7,6.4c1.6,0.2,7.4,1.2,6.3,4.5
						c-0.9,2.7-14.8,2.3-16.4,1c-1.6-1.4-6.3-6.2-8.1-6.1c-1.9,0.1-1.2,7-1.2,7h-2.1c0,0-0.7-6.7-0.8-7.9c-0.3-3.4,2.2-5.9,2.3-7.9
						c0.1-1.9,0.2-3.4,0.2-3.4L595.1,465z"/>
					<path fill="#EEA987" d="M608.4,478.1c0,0-4.6,1.6-8.1-0.4s-10.3-5.1-12.7-5.1c0,0,1.3-4,1.3-4.4s0.2-2.5,0.2-2.5l5.9-0.9
						c0,0,3.8,5.6,5.5,7C602.3,472.9,606.6,476.2,608.4,478.1z"/>
					<path fill="#5779D4" d="M584.9,332c0,0,1.6,6.9,2.3,8.9c0.6,2,4.5,33,5.5,52.4c0,0-23.4,8.9-55.5,7.4c0,0-0.8-35.6,2.2-45.7
						s8.3-19.5,12.3-22.4C555.2,329.9,584.9,332,584.9,332z"/>
					<path fill="#E8E8E8" d="M588.9,291.4c-0.3,4.1-1.9,6.7-2.3,8.2c-0.3,1.5,0,4.9-0.4,8.3s-1.7,19.5-1.3,24.2
						c0.4,4.6-31.6,2.5-34,1.1c0,0,5.4-9.6,5.5-13.6c0-1.4,0.9-5.6-1.4-8.8c-1.2-1.7-3.4-6.9-5.6-10.7c0,0,0.4-0.8,0,0
						c-0.9-1.5-1.8-3-2.4-4.1l0,0c-0.8-1.4-1.3-3.2-1.3-3.2s-1.4-17.7,2.5-19.7s6-2.3,9.8-2.5s6.3-1,6.3-1l8.9,1c0,0,3.2,1.5,5.4,1.7
						c2.3,0.2,5,5.1,6.4,8.6C586.2,283.7,589.1,287.3,588.9,291.4z"/>
					<path fill="#EEA886" d="M553.1,271.2c0,0-7.1,0.6-9.7,7.1c-2.7,6.5-2.4,13.1-3,15.5s-1.4,16.3-2,18.4s-2.7,11.6-3.2,19.3
						c-0.5,7.7,0.7,24,0.7,24l3.9-1.9c0,0,3.5-24.1,5-29.2c1.3-4.4,1.3-11.8,2-14.5s5.2-18.8,5.9-20.4
						C553.7,286.9,559.1,271.1,553.1,271.2z"/>
					<path fill="#EEA886" d="M567.5,230c4-0.7,13.1,0.3,15.7,10.8c0,0,0,2.9-0.4,5.2s2.3,8.7,1.8,9.1s-2.6,1.1-2.6,1.1
						s-1.5,7.5-4.1,7.7c-2.5,0.2-12.4-0.7-17.9-9.1C554.4,246.2,551.4,232.6,567.5,230z"/>
					<path fill="#EEA886" d="M574.1,260.9c0,0-0.2,1-0.4,2.6c-0.1,0.8-0.2,1.7-0.2,2.7c-0.2,2.7-0.1,5.8,0.8,7.8c0,0-5,3-10.3-4.4
						c0,0,0.5-9.2-2.5-14.1C558.4,250.6,574.1,260.9,574.1,260.9z"/>
					<path fill="#FCCCA2" d="M583.1,240.7c0,0-6,5.2-12.3,6.8s-9.7,8.8-9.7,8.8s-11.2-11.2-4.4-22
						C563.4,223.4,583.1,228.2,583.1,240.7z"/>
					<path fill="#A77860" d="M573.7,263.5c-0.1,0.8-0.2,1.7-0.2,2.7c-5.6-2.7-8.7-6.4-8.7-6.4C568.1,262.1,573.7,263.5,573.7,263.5z"
						/>
					<path fill="#FCCCA2" d="M569.7,247.9c0,0-6.5,17.4-5.7,21.6c0,0-3.4-0.6-9.5,1.3C554.4,270.8,557.4,239.7,569.7,247.9z"/>
					<path fill="#EEA886" d="M561.8,270.1c0,0,2.1,13.1,10,11.6s1.9-11.1,1.9-11.1S570.2,267.7,561.8,270.1z"/>
					<path fill="#EEA886" d="M539.8,352.9c0,0,0.6,2.1,1.6,4.2c0.5,1,1.2,7.9,1.2,8.8c0,0.7-0.5,0.3-0.8-0.1
						c-0.2-0.3-0.8-2.6-1.3-3.8c-0.4-1-0.8-1.4-1-1s-0.2,4.8,0.4,6.6c0.5,1.9,0.4,6.2-0.6,5.7c-1-0.6-4-8.2-4-11.2s0.4-6.6,0.4-6.6
						L539.8,352.9z"/>
				</g>
			</g>
		</g>
	</g>
	<g id="Navigation">
		<path fill="#38C0D1" d="M414.7,314.8c1.8-4.8-0.7-10.2-5.5-12s-10.2,0.7-12,5.5c0,0-0.2,0.4-0.4,1.3c-0.1,0.3-0.1,0.6-0.1,0.9
			c-0.4,2.9-0.4,8.9,2.2,20.2c9.3-7,13.2-11.5,14.8-14c0.2-0.3,0.3-0.5,0.5-0.8C414.6,315.2,414.7,314.8,414.7,314.8z M403.9,317
			c-3-1.1-4.6-4.5-3.4-7.5c1.1-3,4.5-4.6,7.5-3.4c3,1.1,4.6,4.5,3.4,7.5C410.2,316.6,406.9,318.1,403.9,317z"/>
	</g>
	<g id="Comment">
		<g>
			<g>
				<g>
					<polygon fill="#38C0D1" points="507.1,284.4 448.6,284.6 448.8,327 448.8,336 457.7,327 507.3,326.8 				"/>
				</g>
			</g>
			
				<ellipse transform="matrix(1 -3.165511e-03 3.165511e-03 1 -0.9707 1.5143)" fill="#FFFFFF" cx="477.9" cy="307.4" rx="4.5" ry="4.5"/>
			
				<ellipse transform="matrix(1 -3.295199e-03 3.295199e-03 1 -1.0103 1.6249)" fill="#FFFFFF" cx="492.6" cy="307.4" rx="4.5" ry="4.5"/>
			
				<ellipse transform="matrix(1 -3.165921e-03 3.165921e-03 1 -0.9709 1.4693)" fill="#FFFFFF" cx="463.6" cy="307.4" rx="4.5" ry="4.5"/>
		</g>
	</g>
	<g id="letter" class="moveup-rotation">
		<g>
			
				<rect x="350.6" y="229.8" transform="matrix(0.2001 -0.9798 0.9798 0.2001 49.1033 548.3842)" fill="#199DD9" width="19.6" height="28.6"/>
			<polygon fill="#32BAEA" points="344.4,250.8 360.6,243 372.4,256.6 		"/>
			<polygon fill="#1095C4" points="376.4,237.4 359.9,246.5 348.3,231.6 		"/>
		</g>
	</g>
	<g id="Blub"  class="semi-rotation">
		<g>
			<g>
				<path fill="#38C0D1" d="M264.3,241.8c-1.3,0.4-2.8,0.4-4,0c-1.4-0.5-2.5-1.5-3-2.8c-1.2-2.5-3.1-4.8-4.5-6.3
					c-4.1-1.2-7.4-4.4-8.7-8.5c-2.2-6.8,1.6-14.2,8.4-16.4c6.8-2.2,14.2,1.5,16.4,8.4c1.2,3.7,0.7,7.7-1.4,11
					c0.1,2.8,0.5,5.3,1.1,7.3c1.1,2.7-0.6,5.8-3.7,7.1C264.6,241.7,264.5,241.8,264.3,241.8z M252.7,208.9c-6.3,2-9.7,8.8-7.7,15
					c1.2,3.8,4.3,6.7,8.2,7.8h0.1l0.1,0.1c1.5,1.6,3.5,4,4.8,6.7l0,0c0.4,1,1.3,1.8,2.4,2.2c1.2,0.4,2.5,0.3,3.8-0.2
					c2.6-1,3.9-3.6,3.1-5.7l0,0l0,0c-0.7-2.2-1.1-4.8-1.2-7.8v-0.2l0.1-0.1c2-3,2.5-6.8,1.4-10.2C265.7,210.3,259,206.9,252.7,208.9z
					"/>
			</g>
			<g>
				<polygon fill="#38C0D1" points="260.4,238.6 253.4,226.2 257.1,227.3 258,224.7 260.2,225.9 261.9,222.7 265.7,236.5 
					264.7,236.8 261.6,225.6 260.7,227.3 258.6,226.3 257.8,228.6 255.7,228 261.3,238.1 			"/>
			</g>
			<g>
				
					<rect x="260.3" y="240.3" transform="matrix(0.928 -0.3725 0.3725 0.928 -71.1453 116.1006)" fill="#38C0D1" width="9.2" height="3.7"/>
			</g>
			<g>
				
					<rect x="257.9" y="236.7" transform="matrix(0.928 -0.3725 0.3725 0.928 -70.2834 115.5871)" fill="#38C0D1" width="12.2" height="6"/>
			</g>
		</g>
	</g>
	<g id="comment1"  class="down-up-animation1s">
		<g>
			<g>
				<g>
					<polygon fill="#199DD9" points="93.1,201.1 180.9,201.5 180.6,265.2 180.6,278.6 167.2,265.1 92.8,264.8 				"/>
				</g>
			</g>
			
				<ellipse transform="matrix(3.205898e-03 -1 1 3.205898e-03 -99.3553 371.8408)" fill="#FFFFFF" cx="136.8" cy="235.8" rx="6.7" ry="6.7"/>
			
				<ellipse transform="matrix(3.206176e-03 -1 1 3.206176e-03 -121.244 349.685)" fill="#FFFFFF" cx="114.8" cy="235.7" rx="6.7" ry="6.7"/>
			
				<ellipse transform="matrix(3.205621e-03 -1 1 3.205621e-03 -77.8594 393.3882)" fill="#FFFFFF" cx="158.4" cy="235.7" rx="6.7" ry="6.7"/>
		</g>
	</g>
	<g id="Comment2">
		<g>
			<g>
				<g>
					<polygon fill="#199DD9" points="489.7,168.5 548.2,168.7 548,211.1 548,220.1 539.1,211.1 489.5,210.9 				"/>
				</g>
			</g>
			
				<ellipse transform="matrix(3.165880e-03 -1 1 3.165880e-03 325.6736 709.787)" fill="#FFFFFF" cx="518.9" cy="191.5" rx="4.5" ry="4.5"/>
			
				<ellipse transform="matrix(3.035936e-03 -1 1 3.035936e-03 311.1785 694.9666)" fill="#FFFFFF" cx="504.1" cy="191.4" rx="4.5" ry="4.5"/>
			
				<ellipse transform="matrix(3.165141e-03 -1 1 3.165141e-03 339.9398 724.0585)" fill="#FFFFFF" cx="533.1" cy="191.5" rx="4.5" ry="4.5"/>
		</g>
	</g>
	<g id="Heart" class="animate-flicker">
		<path fill="#199DD9" d="M747,283.4c-7.3-0.1-9.9,6.5-9.9,6.5s-6.1-3.6-11.8,1.1s-2.8,9.2-2.8,9.2c4,9.8,23.4,14.7,23.4,14.7
			s12-16,9-26.1C754.9,288.8,754.3,283.5,747,283.4z"/>
	</g>
	<g id="arrow">
		<g>
			<g>
				<g>
					<g>
						<g>
							<g>
								<path fill="#38C0D1" d="M807.9,159.1l-10.5-4.6c-0.9-0.4-1.3-1.4-0.9-2.2c0.4-0.9,1.4-1.3,2.2-0.9l7.5,3.2l5.4-11.9
									c0.4-0.9,1.4-1.2,2.3-0.9c0.9,0.4,1.2,1.4,0.9,2.3L807.9,159.1z"/>
							</g>
						</g>
					</g>
				</g>
			</g>
			<g>
				<path fill="#38C0D1" d="M819.9,169.2c-9.7,6.8-23.2,4.5-30-5.2s-4.5-23.2,5.2-30s23.2-4.5,30,5.2
					C832,148.9,829.6,162.3,819.9,169.2z M796.9,136.4c-8.3,5.9-10.3,17.4-4.5,25.7c5.9,8.3,17.4,10.3,25.7,4.5
					c8.3-5.9,10.3-17.4,4.5-25.7C816.7,132.6,805.2,130.6,796.9,136.4z"/>
			</g>
		</g>
	</g>
	<g id="Comment3"  class="up-down-animation1s">
		<g>
			<g>
				<g>
					<polygon fill="#38C0D1" points="765.7,156.8 682.5,157.2 682.8,217.5 682.9,230.2 695.5,217.4 766,217.1 				"/>
				</g>
			</g>
			
				<ellipse transform="matrix(1 -3.111840e-03 3.111840e-03 1 -0.5865 2.2548)" fill="#FFFFFF" cx="724.3" cy="189.6" rx="6.4" ry="6.4"/>
			
				<ellipse transform="matrix(1 -3.211645e-03 3.211645e-03 1 -0.6048 2.394)" fill="#FFFFFF" cx="745.1" cy="189.5" rx="6.4" ry="6.4"/>
			
				<ellipse transform="matrix(1 -3.120123e-03 3.120123e-03 1 -0.5882 2.1972)" fill="#FFFFFF" cx="703.9" cy="189.6" rx="6.4" ry="6.4"/>
		</g>
	</g>
	<g id="Layer_2" class="down-up-animation2s">
		<g>
			<g>
				<g>
					<polygon fill="#199DD9" points="900.1,201.3 826.9,201.7 827.1,254.7 827.2,265.9 838.3,254.7 900.3,254.4 				"/>
				</g>
			</g>
			
				<ellipse transform="matrix(1 -3.141622e-03 3.141622e-03 1 -0.7186 2.7143)" fill="#FFFFFF" cx="863.6" cy="230.1" rx="5.6" ry="5.6"/>
			
				<ellipse transform="matrix(1 -3.245641e-03 3.245641e-03 1 -0.7422 2.8639)" fill="#FFFFFF" cx="882" cy="230.1" rx="5.6" ry="5.6"/>
			
				<ellipse transform="matrix(1 -3.142275e-03 3.142275e-03 1 -0.7192 2.6586)" fill="#FFFFFF" cx="845.7" cy="230.2" rx="5.6" ry="5.6"/>
		</g>
	</g>
	</svg>




          </div>


        </div>

      </div>
    </div>
  </div>
  <div class="expertise-section">
   
    <div class="container-fluid">
       
      <div class="section-heading" data-aos="fade-up">OUR EXPERTISE </div>
      <p class="section-p" data-aos="fade-up">We help bussiness define big visions, turn ideas into realities and deliver delightful
        digital experiences
      </p>
      <div class="row threeboxcontainer">
        <div class="col-sm-12" style="text-align: center;">
          <a  >
            <div class="threebox" data-aos="fade-up"  data-aos-delay="100">
				<div class="threeboximg">
					<img src="./assets/img/web-icon1.png" alt="Application Development" class="img-fluid">
				</div>
             
              <span>Application<br />Development
              </span>
            </div>
		  </a>
		  <a  >
            <div class="threebox" data-aos="fade-up" >
              <img src="./assets/img/ux-strategy-icon1.png" alt="Digital Marketing" class="img-fluid">
              <span>Creative  <br/>  Branding
              </span>
            </div>
		  </a>
		  
          <a  >
            <div class="threebox" data-aos="fade-up" >
              <img src="./assets/img/ux-icon1.png" alt="USER EXPERIENCE DESIGN" class="img-fluid">
              <span>USER EXPERIENCE<br />DESIGN
              </span>
            </div>
          </a>
         
        </div>
      </div>
    </div>

  </div>
  <div class="casestudysection p60">
    <div class="container">
		<div class="row">
			<div class="col-md-10 offset-md-1">
			
      <div class="row">
        <div class="col-sm-6 col-md-6 col-12" style="text-align: center; position: relative;" data-aos="fade-right">
        
<!-- Generator: Adobe Illustrator 21.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" 
	 y="0px" viewBox="0 0 2122 2122" xml:space="preserve" style="max-width: 100%;   max-height: 100%;  top: 0;  
	 bottom: 0;  
	 left: 0;  
	 right: 0;  
	 margin: auto;"  class="img-fluid" >
<g id="background_x5F_people">
	<g>
		<rect y="0" display="none" fill="#FFFFFF" width="2121.3" height="2121.3"/>
		<g display="none">
			<g display="inline">
				<path fill="#9EABD3" d="M1231.5,1222l-294.8-124l-229.9,155.2l122.1,51.4c-2.7,2.3-7.3,4.9-8.2,5.4c-4.3,1.9-9.8,3.4-15.1,4.4
					c-10.3,1.9-22.9,1.6-30.2,6.5c-11.4,7.7-2.4,21.7,20.1,31.8c0.7,0.3,1.3,0.6,2,0.9c24,10.1,52.9,11.8,64.7,3.9
					c9.6-6.4,2.2-16.7,7.9-24.2c0.5-0.6,5.4-4.8,9.5-7.2l121.9,51.3l95.1-64.2c-0.4-0.2-0.8-0.4-1.2-0.6c-4.7-2.1-11.4-4.3-12.5-4.6
					c-14.2-3.1-33.4,0.7-45.6-4.5c-15-6.7-12-21.9,6.5-34.3c18.7-12.6,46.1-17.7,61.2-11.3c9.4,4.2,9,10.7,12.6,16.1
					c1.9,2.8,4.7,5.8,8.4,8.1c0.4,0.3,2.1,1.1,4.3,2.1c1.8,0.8,3.9,1.7,5.9,2.3L1231.5,1222z"/>
			</g>
			<path display="inline" fill="#9EABD3" d="M918.6,1657.4c-7.4-5-20.3-4.7-30.7-6.6c-5.4-1-11-2.5-15.3-4.5
				c-0.9-0.4-5.6-3.1-8.4-5.4l124.1-52.2l-96.7-65.2c0.4-0.2,0.8-0.4,1.2-0.6c4.8-2.1,11.6-4.4,12.7-4.7c14.4-3.2,33.9,0.7,46.9-4.8
				c14.8-6.6,11.7-22-7.1-34.7c-19-12.8-46.8-18-62.6-11.3c-9.1,4-8.7,10.7-12.4,16.2c-1.9,2.9-4.8,5.9-8.6,8.2
				c-0.5,0.3-2.2,1.2-4.4,2.1c-1.8,0.8-4,1.7-6,2.4l-96.8-65.3L455,1557.1l233.7,157.7l123.9-52.1c4.2,2.5,9.2,6.7,9.7,7.4
				c5.8,7.6-1.7,18,8,24.6c12,8.1,41.4,6.3,65.8-3.9c0.7-0.3,1.4-0.6,2-0.9C921.1,1679.5,930.2,1665.3,918.6,1657.4z"/>
			<g display="inline">
				<path fill="#9EABD3" d="M1150.4,1363.4l123.9-52.2c4.2,2.5,9.2,6.7,9.7,7.4c5.8,7.6-1.7,18,8,24.6c12,8.1,41.4,6.3,65.8-3.9
					c0.7-0.3,1.3-0.6,2-0.9c22.9-10.2,32.1-24.5,20.4-32.3c-7.4-5-20.3-4.7-30.7-6.6c-5.4-1-11-2.5-15.3-4.5
					c-0.9-0.4-5.6-3.1-8.4-5.4l124.1-52.2l233.7,157.7l-299.6,126.1l-96.7-65.2c0.4-0.2,0.8-0.4,1.2-0.6c4.8-2.1,11.7-4.4,12.8-4.7
					c14.4-3.2,33.9,0.7,46.4-4.6c15.3-6.8,12.2-22.2-6.6-34.9c-19-12.8-46.8-18-62.2-11.5c-9.5,4.2-9.1,10.9-12.8,16.4
					c-1.9,2.9-4.8,5.9-8.6,8.2c-0.5,0.3-2.2,1.2-4.4,2.1c-1.8,0.8-4,1.7-5.9,2.4L1150.4,1363.4z"/>
			</g>
			<g display="inline">
				<path fill="#9EABD3" d="M1549.6,1705.9l-121.9-51.3c-4.1,2.4-9,6.6-9.5,7.2c-5.7,7.5,1.7,17.7-7.9,24.2
					c-11.8,7.9-40.7,6.2-64.7-3.9c-0.7-0.3-1.3-0.6-2-0.9c-22.5-10-31.5-24.1-20.1-31.8c7.3-4.9,19.9-4.6,30.2-6.5
					c5.3-1,10.8-2.4,15.1-4.4c0.9-0.4,5.5-3.1,8.2-5.4l-122.1-51.4l-95.2,64.3c-2-0.7-4.1-1.5-5.9-2.3c-2.2-1-3.8-1.8-4.3-2.1
					c-3.7-2.3-6.5-5.2-8.4-8.1c-3.6-5.4-3.3-12-12.2-15.9c-15.5-6.5-42.9-1.5-61.6,11.1c-18.5,12.5-21.5,27.6-7,34.1
					c12.8,5.4,31.9,1.6,46.1,4.7c1.1,0.2,7.8,2.5,12.5,4.6c0.4,0.2,0.8,0.4,1.2,0.6l-95.1,64.2l294.8,124L1549.6,1705.9z"/>
			</g>
		</g>
		<path display="none" fill="#B3E4FA" d="M598,8.5c-23.7,75-65.7,143.6-115.6,204c-46.2,55.9-98.9,105.7-160.9,143.8
			C224.4,415.8,113,447.1,0,456.6V0h600.6C599.8,2.9,598.9,5.7,598,8.5z"/>
		<path display="none" fill="#B3E4FA" d="M1765.9,2041.1c21.6-71.3,56.7-137.9,104.9-194.8c26.2-31,57.6-58.4,89.2-83.7
			c32.5-26.1,66.7-50,102-72.2c16-10,31.7-18.7,49.3-25.4c3.3-1.3,6.7-2.5,10-3.7v460.1h-374.1
			C1751.8,2094.2,1757.9,2067.4,1765.9,2041.1z"/>
		<g>
			<g>
				<ellipse display="none" fill="#9EABD3" cx="687.8" cy="1198.6" rx="168.4" ry="74.9"/>
				<g>
					<g>
						<polygon fill="#561136" points="733.1,1193.5 735.9,1193 738.7,1169.8 731.4,1161.3 						"/>
						<g>
							<polygon fill="#152035" points="729.8,1191.7 733.1,1193.5 732.6,1155.6 726.8,1154.3 							"/>
							<g>
								<path fill="#E59375" d="M755.3,1143.9l6.2,20.3c0,0,8.4,19.6,15.3,27.3c0,0,12.5,17.1-10.9,16c0,0-24.9-1.3-24.5-17.3
									c0.3-13.7-12.3-30.8-12.3-30.8c-4.6-8,0.1-14.6,1.6-16.4l4.3-4.9l20.9-3.7L755.3,1143.9z"/>
								<path fill="#152035" d="M778.1,1197.9c-10.3,1.9-20.7-1.9-27.1-8.2c-6.5-6.4-13.9-29.7-11.4-22c1,3.2-6.6-25.2-4.6-29.6
									l-4.3,4.9c-1.6,1.8-6.7,8.8-2.1,16.8c0,0,13.1,16.6,12.7,30.4c-0.4,16,24.5,17.3,24.5,17.3c14,0.7,15.1-5.2,13.8-10
									C779.2,1197.7,778.7,1197.8,778.1,1197.9z"/>
								<path fill="#152035" d="M789.9,1204.7c0,0,3.5,6.6-7.6,6.6c0,0-41.8-2.7-41.8-20.6l0-14.5c0,0,3.3,20.6,29.1,20.7
									c0,0,11.5,0,5.5-7.3L789.9,1204.7z"/>
							</g>
						</g>
					</g>
					<g>
						<circle fill="#1A2652" cx="694.8" cy="237.3" r="27.8"/>
						<path fill="#F7A483" d="M714.7,365.5h54.8c-2.5-8-7.6-28.6,0.3-42.7l-55.3-36.3C714.5,286.5,724.4,347.5,714.7,365.5z"/>
						<path fill="#E1876F" d="M720.9,331.1c3.3,8.4,8.6,12.5,17.2,15.2c9.6,3,18.8,3.6,27.8,2.1c-0.9-8.4-0.4-17.9,3.9-25.5
							l-55.3-36.3c0,0,3,18.6,4.1,38.3C719.3,326.9,720.1,329,720.9,331.1z"/>
						<path fill="#F7A483" d="M780.4,335.5l2.9-8.2l7.1-24.2c1-3.8,1.4-9.1,0.1-12.9c-1.6-4.5-2.8-11.9-1.1-20.1
							c4.5-22.1-5.3-33.6-5.3-33.6c-12.9-20.3-43.2-20.2-43.2-20.2c-37.6,1.9-40.6,38.3-40.6,38.3c-6,24.2,13.3,44.3,13.3,44.3
							c2.4,2.5,2.7,6.7,3.9,9.8c1.3,3.4,3.2,6.5,5.5,9.2c5.3,6.1,12.2,11.8,19.3,15.5c6.7,3.4,19.7,6.9,29,6.8
							C775,340.2,778.7,340.3,780.4,335.5z"/>
						<g>
							<polygon fill="#561136" points="605.1,1197.9 607.9,1198.3 617.9,1177.2 613.8,1166.8 							"/>
							<g>
								<polygon fill="#152035" points="602.5,1195 605.1,1197.9 616.8,1161.8 611.6,1158.6 								"/>
								<g>
									<path fill="#F7A483" d="M642,1158c-0.6,17-1.8,36,5,52c0,0,6.3,20.2-15.4,11.6c0,0-23.2-9.2-17.7-24.3
										c4.7-12.9-1.8-33.1-1.8-33.1c-1.8-9.1,4.7-13.8,6.8-15l5.7-3.3h21.8L642,1158z"/>
									<path fill="#152035" d="M646.3,1216.4c-10.4-1.5-19-8.4-23.1-16.5c-4.1-8.1-3.6-32.6-3.8-24.5c-0.1,3.4,1.8-26,5.2-29.5
										l-5.7,3.3c-2.1,1.2-9.2,6.2-7.3,15.3c0,0,7,19.9,2.3,32.8c-5.5,15,17.7,24.3,17.7,24.3c13.1,5.1,16-0.1,16.3-5.1
										C647.4,1216.6,646.9,1216.5,646.3,1216.4z"/>
									<path fill="#152035" d="M655.2,1226.7c0,0,1.2,7.4-9.4,3.8c0,0-38.7-15.9-32.9-32.9l4.6-13.7c0,0-3.5,20.6,20.9,28.9
										c0,0,10.9,3.7,7.6-5.1L655.2,1226.7z"/>
								</g>
							</g>
						</g>
						<path fill="#F7A483" d="M666.1,904.6c-27.8,51.3-32.7,72.2-37.6,111.1c-2.9,22.7-1.9,118.2-4.9,132.5
							c-1.2,5.9,21.2,2.7,21.2,2.7c4.1-10,69.5-229.5,69.5-229.5s7.3-22.7,1.2-26.7C685,874.9,678.2,882.3,666.1,904.6z"/>
						<path fill="#1F2732" d="M645.4,1155.5c4.1-10,68.8-234.1,68.8-234.1s7.3-22.7,1.2-26.7c-30.5-19.9-37.3-12.4-49.4,9.9
							c-27.8,51.3-32.7,72.2-37.6,111.1c-2.9,22.7-7.6,114.9-10.5,129.2C617.9,1144.9,634.5,1164.7,645.4,1155.5z"/>
						<path fill="#1F2732" d="M790.7,533.8c0,0,21.3,90.3,18.5,100.2c-8.3,28.4-84.8,68.3-154.6,10.1c0,0,16-66.8,49.1-123.3
							C703.7,520.8,715.9,533.4,790.7,533.8z"/>
						<path fill="#6652A2" d="M779.9,350.2c0,0,27.9,54,40.4,83.6c-5.2,34.7-24.1,86.2-31.7,97.1c-16.6,23.8-92.6,2.6-92.6,2.6
							l-21.9-73.1c63.9-23,30.3-90.3,30.3-90.3l10-6.3c0,0,10.2,33.1,51.8,31.9c0,0,13.7-8.9,3.6-46.7
							C769.8,349,775.8,349.2,779.9,350.2z"/>
						<path fill="#E59375" d="M710.3,658.3c0,0,7.4,145.7,34.3,224.1c4,11.7,50.1-3.8,50.1-3.8s23.2-125.4,21.9-177.3
							c-0.8-29.4-11.3-92.7-11.3-92.7S718.9,597.2,710.3,658.3z"/>
						<path fill="#E59375" d="M746,881.9l48.8-3.3c0,0-30,248.8-32.7,259.2c0,0-21,7.6-20.5,1.6c1-14.5-16.5-126.2-17-149
							C723.6,953.6,725.1,930.4,746,881.9z"/>
						<path fill="#171F29" d="M710.3,658.3c0,0,7.4,145.7,34.3,224.1c4,11.7,50.1-3.8,50.1-3.8s23.2-125.4,21.9-177.3
							c-0.8-29.4-11.3-92.7-11.3-92.7S718.9,597.2,710.3,658.3z"/>
						<path fill="#171F29" d="M768.1,1139.3c4.9-9.6,26.6-260.8,26.6-260.8l-48.8,3.3c-20.9,48.5-22.3,71.7-21.5,108.4
							c0.5,22.9,11.6,134.5,10.6,149C735.1,1139.3,761.6,1151.9,768.1,1139.3z"/>
						<g>
							<g>
								<path fill="#E59375" d="M868.1,527.1l-37-134c-13.3-35.9-33.2-36.7-33.2-36.7c-38.1,5.8-13.7,72.6-13.7,72.6l47,111.3"/>
							</g>
							<g>
								<g>
									<path fill="#E59375" d="M975.2,627.3c-16-11.9-17.1-15.1-19.5-19.9c-10.4-20.7,16.2-16.2,22.6-13.6
										c3.7,1.4,2.6,10.6,12.9,23.6c0,0,17.2,13.7,21.9,13.6c0,0,4.7,1.4,4.2,4.9C1017.4,635.8,1003,641.6,975.2,627.3z"/>
								</g>
								<g>
									<g>
										<path fill="#E59375" d="M843.9,511.6"/>
									</g>
									<g>
										<path fill="#E59375" d="M865.2,519.3l108.3,81.9c3,14.9-3.9,16-3.9,16l-87.3-39.7c-37.4-16.2-50.4-34.8-50.4-34.8
											s-15.3-14.3,9-28.7C840.9,514,852,507.7,865.2,519.3z"/>
									</g>
								</g>
								<path fill="#E59375" d="M975.3,592.8c0,0-4.7,9.2,7.6,8.6l13.1-7.2c0,0,3.9-1.4,3.2-5.1c0,0-1.1-2.2-5.2-0.8L975.3,592.8z"
									/>
							</g>
						</g>
						<path fill="#F7A483" d="M650.7,670.5c0,0,0.6,145.8,19.8,226.4c2.9,12,46.8,8.3,46.8,8.3s28.7-137.9,32.3-189.7
							c2.1-29.3,7.9-80.6,7.9-80.6S654.7,624.7,650.7,670.5z"/>
						<path fill="#F7A483" d="M672,387.6c-22.4,16.4-11,41-11,41l42.3,102.8c0,0,68.6,23.3,85.3-0.5c7.6-10.9,14.6-39.7,23.8-81.2
							c6.3-28.2,6.7-77.6-9.2-92.2c-9.2-8.4-38.8-8.3-39.1-9.3l-48.1,15.5L672,387.6z"/>
						<path fill="#F7A483" d="M762.6,523c4.1-0.7,26,7.9,26,7.9s15.8,67.4,16.7,77.6c0.9,10.2-69.5,63.3-148.2,33.1
							c0,0,39.7-93.7,45.5-113.3C709.3,505.8,762.6,523,762.6,523z"/>
						<path fill="#6652A2" d="M800.8,391.9c0,0,23.2,27.3,25.2,47.7c1.1,11.3-13.2,30.3-20.3,37.2
							C800.6,481.7,770.6,410.8,800.8,391.9z"/>
						<path fill="#1F2732" d="M676.3,619.3c0,0-27.7,6.2-25.2,57c2.4,49.7,92.9,60,104.5,10C768.1,632.4,725.7,600.8,676.3,619.3z"
							/>
						<path fill="#2B3B6F" d="M717.7,313c-0.2-17.2,0.2-16.1,0.2-16.1c0.4-5,10.2,5.8,10-0.7c-0.9-29.9,9.9-44.1,14.2-46.4
							c11.5-6.2,23.7-8,33.3-6.3c15.1,2.7,14.5,22.8,14.5,22.8c12.9-73.6-69.3-50.1-69.3-50.1c-40,10.5-35.9,48.2-35.9,48.2
							c-4.9,40.5,33.4,63.9,33.4,63.9L717.7,313z"/>
						<g>
							<g>
								<path fill="#F8A07D" d="M720.4,307.2c-1.3,1-3.1,1.6-5.2,1.6c-5.8-0.1-10.6-5.5-10.8-12.1c-0.1-6.6,4.5-11.9,10.3-11.8
									c2.8,0,5.3,1.3,7.2,3.3c2.8,2.9,2.9,8.3,2.2,12C723.5,303.1,722.4,305.6,720.4,307.2z"/>
							</g>
							<g>
								<g>
									<path fill="#E98B6F" d="M717,290.9c-0.3-3.5-7.4-0.2-6.3,1.8c0.6,1.1,1.8,1.9,2.8,2.7c1.3,1.1,2,2.1,2.2,3.8
										c0,0.2,0.1,0.8-0.1,0.9c-0.9,0.6-3.9-2.8-4.5-3.5c-1.3-1.4-1.5-3.8-0.8-5.6c0.7-1.9,1.9-2.7,3.9-2.6
										c1.6,0.1,3.2,0.2,3.1,2.2C717.1,290.8,717,291.1,717,290.9L717,290.9z"/>
								</g>
								<g>
									<path fill="#E98B6F" d="M714.7,294.5c0.7-0.7,1.4-0.1,2.2,0.4c0.7,0.5,1.1,1.2,1.3,2c0.4,1.8,0.2,4.2-0.9,5.8
										c-0.8,1.3-2.2,2-3.8,2c-2.1-0.1-2.7-1.9-2.8-3.7c0-0.5-0.1-1.6,0.4-1.9c0.5-0.3,0.9,0.5,1.2,0.8c0.5,0.4,1.2,0.6,1.8,0.7
										c1.2,0.1,2.4-0.5,3.1-1.4c0.1-0.2,0.3-0.3,0.3-0.5c0,1.2-0.1,1.4-0.2,0.8c-0.1-0.3-0.2-0.6-0.4-0.8
										c-0.5-0.8-1.3-1.4-1.9-2.1C714.9,296.2,714.4,294.9,714.7,294.5L714.7,294.5z"/>
								</g>
							</g>
						</g>
						<g>
							<path fill="#3C2415" d="M753.7,281.6c0.2,2.1-0.8,3.9-2.2,4c-1.4,0.1-2.7-1.4-2.9-3.5c-0.2-2.1,0.8-3.9,2.2-4
								C752.2,277.9,753.5,279.5,753.7,281.6z"/>
							<path fill="#3C2415" d="M782.6,277.7c0.1,2.1-1,3.8-2.4,3.9c-1.4,0.1-2.6-1.6-2.7-3.7c-0.1-2.1,1-3.8,2.4-3.9
								C781.3,273.9,782.5,275.6,782.6,277.7z"/>
							<path fill="#2B3B6F" d="M741.2,278.8c5.4-5.7,18.2-5.3,18.2-5.3s0.9,0,0.6-2.6c0,0-0.1-2.3-1.9-2.1
								C758.1,268.8,741.9,267.7,741.2,278.8z"/>
							<path fill="#2B3B6F" d="M789.9,267.9c-5.7-4.6-16.1-1-16.1-1c-1.8,0.3-1.3,2.5-1.3,2.5c0.5,2.6,1.3,2.4,1.3,2.4
								s8.6-2.7,15.4-1.1C789.4,269.7,789.7,268.8,789.9,267.9z"/>
							<g>
								<path fill="#FFFFFF" d="M778.9,308l-20.6,3.8c-1.1,0.2-1.5,0.8-0.9,1.4c0,0,5.9,5.9,12.6,4.7c6.7-1.2,10-8.9,10-8.9
									C780.3,308.3,779.8,307.9,778.9,308z"/>
							</g>
							<path fill="#E98B6F" d="M764.1,301.5c10.8-0.8,11.4-3.6,11.4-3.6l-5.7-11.4C769.8,286.5,771.1,297.9,764.1,301.5z"/>
						</g>
						<path fill="#1F2732" d="M650.7,670.5c0,0,0.6,145.8,19.8,226.4c2.9,12,46.8,8.3,46.8,8.3s28.7-137.9,32.3-189.7
							c2.1-29.3,7.9-80.6,7.9-80.6S654.7,624.7,650.7,670.5z"/>
						<path fill="#1F2732" d="M790.7,533.8c0,0,21.3,90.3,18.5,100.2c-8.3,28.4-84.8,68.3-154.6,10.1c0,0,16-66.8,49.1-123.3
							C703.7,520.8,715.9,533.4,790.7,533.8z"/>
						<path fill="#6652A2" d="M696,533.5c0,0,78.1,24.1,94.7,0.3c7.6-10.9,24.3-65.2,29.5-99.9c-12.5-29.6-40.4-83.6-40.4-83.6
							c-4-1-10-1.2-10-1.2c10.1,37.8-3.6,46.7-3.6,46.7c-41.6,1.2-51.8-31.9-51.8-31.9l-10,6.3c0,0,31,64.4-28.8,93.8
							C675.5,463.9,674.1,533,696,533.5z"/>
						<circle fill="#FFFFFF" cx="717.1" cy="308.4" r="3.6"/>
						<g>
							<path fill="#F7A483" d="M705.5,397c12.2,12.9,11.5,33.3-1.4,45.4c-12.9,12.2-53.5,35.3-53.9,17.6c-0.7-34.9,0.6-53.8,13.5-66
								C676.7,381.9,693.4,384.1,705.5,397z"/>
							<path fill="#F7A483" d="M707,426.5l-28.1-16.2l-28.2,17.7c0,0-16.5,105.2-10.4,129.3c6.5,25.7,18.3,6.8,23.8-0.3
								C664.1,556.9,710.1,446.9,707,426.5z"/>
							<path fill="#F7A483" d="M644.5,567.5c0,0,50.5,36,95.3,55.7c0,0,14.8-6.2,7.4-13.1c-12.6-11.6-67.5-64.2-80.2-75.9
								C644.5,526.5,644.5,567.5,644.5,567.5z"/>
							<g>
								<g>
									<path fill="#F7A483" d="M765.3,643.2c-18.3-7.9-20.1-10.8-23.5-14.9c-14.9-17.8,12-19.4,18.9-18.5c4,0.6,5,9.8,18,20
										c0,0,19.8,9.4,24.4,8.2c0,0,4.9,0.3,5.2,3.8C808.3,641.9,795.7,650.9,765.3,643.2z"/>
								</g>
								<path fill="#F7A483" d="M759.9,609.7c0,0-7.8,6.7,3.9,10.8l14.8-1.8c0,0,4.2,0.2,4.8-3.5c0,0-0.2-2.5-4.5-2.7L759.9,609.7z"
									/>
							</g>
						</g>
					</g>
				</g>
			</g>
			<g>
				<path fill="#004E89" d="M1076.2,740.5c-0.2-0.1-0.4-0.1-0.7-0.1c-0.3-0.1-0.7-0.2-1-0.2c-0.2,0-0.5-0.1-0.7-0.1
					c-0.2,0-0.5-0.1-0.7-0.1l-0.5,57.4l-95.1,64.2L978,793l78.8-53.2c-1.2,0.1-2.3,0.3-3.5,0.5c-0.3,0-0.5,0.1-0.8,0.1
					c-0.4,0.1-0.9,0.2-1.3,0.2c-0.3,0.1-0.6,0.1-0.9,0.2c-0.4,0.1-0.9,0.2-1.3,0.3c-0.3,0.1-0.5,0.1-0.8,0.2
					c-0.7,0.2-1.4,0.3-2.1,0.5c-0.2,0-0.4,0.1-0.6,0.1c-0.9,0.2-1.7,0.5-2.6,0.7c-0.2,0-0.3,0.1-0.5,0.1c-1.2,0.3-2.3,0.7-3.5,1.1
					c0,0-0.1,0-0.1,0c-1.2,0.4-2.3,0.8-3.5,1.3c-0.1,0-0.2,0.1-0.3,0.1c-1.3,0.5-2.6,1.1-3.9,1.6c-0.1,0-0.1,0-0.2,0.1
					c-1.3,0.6-2.6,1.2-3.8,1.9c0,0-0.1,0-0.1,0.1c-1.3,0.7-2.5,1.4-3.7,2.1c0,0,0,0-0.1,0c-1.2,0.7-2.5,1.5-3.6,2.3
					c-10.5,7.1-16,15-16.1,21.8c0.2-22.8,0.4-45.7,0.6-68.5c0.1-6.8,5.6-14.7,16.1-21.8c1.2-0.8,2.4-1.6,3.6-2.3c0,0,0,0,0.1,0
					c1.2-0.7,2.5-1.4,3.7-2.1c0,0,0.1,0,0.1-0.1c1.3-0.7,2.5-1.3,3.8-1.9c0.1,0,0.1-0.1,0.2-0.1c1.3-0.6,2.6-1.1,3.9-1.6
					c0,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.2-0.1c1.1-0.4,2.3-0.9,3.4-1.3c0.1,0,0.1,0,0.2-0.1c1.2-0.4,2.3-0.8,3.5-1.1
					c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.2-0.1c0.8-0.2,1.7-0.5,2.5-0.7c0.2-0.1,0.4-0.1,0.6-0.1c0.7-0.2,1.4-0.3,2-0.5
					c0.1,0,0.3-0.1,0.4-0.1c0.2,0,0.3-0.1,0.5-0.1c0.4-0.1,0.8-0.2,1.2-0.3c0.3-0.1,0.6-0.1,1-0.2c0.4-0.1,0.8-0.2,1.3-0.2
					c0.2,0,0.4-0.1,0.7-0.1c0.1,0,0.1,0,0.2,0c1.2-0.2,2.3-0.4,3.5-0.5c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0c1-0.1,2-0.2,3-0.3
					c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0c0.9-0.1,1.9-0.1,2.8-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0.9,0,1.7,0,2.6,0c0.1,0,0.2,0,0.3,0
					c0.1,0,0.2,0,0.3,0c0.8,0,1.7,0.1,2.5,0.1c0,0,0,0,0.1,0c0.1,0,0.1,0,0.2,0c0.9,0.1,1.7,0.2,2.5,0.3c0.1,0,0.2,0,0.3,0
					c0.1,0,0.1,0,0.2,0c0.3,0,0.7,0.1,1,0.2c0.3,0,0.5,0.1,0.8,0.1c0.3,0.1,0.6,0.1,0.9,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0,0.1,0,0.1,0
					c0.7,0.2,1.4,0.4,2.1,0.6c0.2,0,0.3,0.1,0.5,0.2c0.7,0.2,1.4,0.5,2,0.8c0.5,0.2,1,0.5,1.5,0.7c0.1,0.1,0.3,0.2,0.4,0.2
					c0.2,0.1,0.3,0.2,0.5,0.3c0.1,0.1,0.3,0.2,0.4,0.3c0.2,0.1,0.3,0.2,0.5,0.3c0.3,0.2,0.5,0.4,0.7,0.5c0.1,0.1,0.3,0.2,0.4,0.3
					c0,0,0.1,0.1,0.1,0.1c0.3,0.2,0.5,0.5,0.8,0.7c0,0,0.1,0.1,0.1,0.1c0.2,0.2,0.4,0.4,0.6,0.6v0h0v0c0.1,0.1,0.1,0.1,0.2,0.2
					c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.2,0.3,0.4,0.5,0.6c0.1,0.1,0.2,0.2,0.2,0.3c0,0,0.1,0.1,0.1,0.2c0.1,0.1,0.1,0.2,0.2,0.3
					c0.1,0.2,0.3,0.4,0.4,0.6c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.2,0.2,0.4,0.4,0.7c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.2,0.1,0.2
					c0.2,0.4,0.4,0.8,0.6,1.2c0,0.1,0.1,0.1,0.1,0.2c0.2,0.5,0.5,1.1,0.7,1.6c0.1,0.3,0.3,0.7,0.4,1c0.1,0.2,0.1,0.3,0.2,0.5
					c0.2,0.5,0.4,0.9,0.6,1.3c0.1,0.2,0.2,0.4,0.4,0.7c0.1,0.1,0.1,0.2,0.2,0.4c0.2,0.3,0.4,0.7,0.6,1c0.1,0.2,0.3,0.4,0.5,0.6
					c0.2,0.3,0.4,0.6,0.7,0.9c0.1,0.1,0.2,0.3,0.3,0.4c0.3,0.4,0.7,0.8,1,1.2c0,0,0,0,0.1,0.1c0.4,0.4,0.8,0.8,1.2,1.2
					c0.1,0.1,0.2,0.2,0.4,0.3c0.3,0.3,0.6,0.6,0.9,0.8c0.2,0.1,0.3,0.3,0.5,0.4c0.3,0.3,0.7,0.5,1,0.8c0.2,0.1,0.3,0.2,0.5,0.3
					c0.5,0.4,1,0.7,1.6,1c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1,0.1,0.2,0.1
					c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0.2,0.1,0.3,0.2
					c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0,0.2,0.1,0.2,0.1c0.2,0.1,0.3,0.1,0.5,0.2c0.1,0,0.2,0.1,0.3,0.1c0.3,0.1,0.5,0.2,0.8,0.3
					c1.8,0.8,3.9,1.7,5.9,2.3l95.2-64.3l-0.6,68.6l-95.2,64.3c-2-0.7-4.1-1.5-5.9-2.3c-0.3-0.1-0.5-0.2-0.8-0.4c0,0-0.1,0-0.1-0.1
					c-0.2-0.1-0.4-0.2-0.6-0.3c-0.1,0-0.1-0.1-0.2-0.1c-0.2-0.1-0.3-0.1-0.4-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.2-0.1-0.3-0.2
					c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.2-0.1-0.3-0.1c0,0-0.1,0-0.1-0.1
					c-0.1,0-0.2-0.1-0.2-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0c-0.5-0.3-1-0.7-1.5-1c-0.2-0.1-0.3-0.2-0.5-0.3
					c-0.3-0.2-0.7-0.5-1-0.8c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.3-0.3-0.6-0.5-0.9-0.8c-0.1-0.1-0.3-0.2-0.4-0.4
					c-0.4-0.4-0.8-0.8-1.2-1.2c0,0,0,0,0,0c0,0,0-0.1-0.1-0.1c-0.4-0.4-0.7-0.8-1-1.2c-0.1-0.1-0.2-0.3-0.3-0.4
					c-0.2-0.3-0.5-0.6-0.7-0.9c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.3-0.4-0.7-0.6-1c-0.1-0.2-0.2-0.3-0.3-0.5
					c-0.1-0.2-0.2-0.3-0.3-0.5c0,0,0,0,0,0c-0.2-0.5-0.4-0.9-0.7-1.4c-0.1-0.1-0.1-0.3-0.2-0.4c-0.2-0.4-0.4-0.8-0.5-1.2
					c-0.2-0.5-0.4-0.9-0.6-1.4c0-0.1-0.1-0.2-0.1-0.3c-0.2-0.4-0.3-0.7-0.5-1.1c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.2-0.2-0.4-0.4-0.6
					c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1-0.2-0.3-0.4-0.4-0.6c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1-0.1-0.2-0.2-0.2-0.3
					c-0.2-0.2-0.3-0.4-0.5-0.6c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2-0.3-0.5-0.5-0.7-0.8c0,0-0.1-0.1-0.1-0.1c-0.3-0.3-0.6-0.6-0.9-0.8
					c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.2-0.5-0.4-0.7-0.5c-0.2-0.1-0.3-0.2-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.5
					c-0.1-0.1-0.3-0.2-0.4-0.2c-0.5-0.3-1-0.5-1.5-0.7c-0.7-0.3-1.3-0.5-2-0.8c-0.2-0.1-0.3-0.1-0.5-0.2
					C1077.6,740.9,1076.9,740.7,1076.2,740.5z"/>
				<path fill="#0061A2" d="M682.6,737.5l0.6-68.6l122.1,51.4l-0.5,57.1c0,0,0.1,0,0.1,0c0.3,0,0.5,0.1,0.8,0.1
					c1,0.1,1.9,0.2,2.9,0.3c0.1,0,0.2,0,0.3,0c0.7,0.1,1.4,0.1,2.1,0.1c0.3,0,0.5,0,0.8,0c0.5,0,1,0,1.6,0.1c0.2,0,0.4,0,0.6,0
					c1.2,0,2.4,0,3.6,0c0.2,0,0.3,0,0.5,0c1,0,2-0.1,2.9-0.1c0.1,0,0.1,0,0.2,0c0.9-0.1,1.8-0.1,2.6-0.2c0.2,0,0.4,0,0.6-0.1
					c0.7-0.1,1.4-0.2,2.1-0.3c0.1,0,0.2,0,0.3,0c0.8-0.1,1.5-0.3,2.2-0.4c0.2,0,0.4-0.1,0.6-0.1c0.7-0.2,1.3-0.3,2-0.5
					c0.1,0,0.2,0,0.3-0.1c0.1,0,0.3-0.1,0.4-0.1c0.3-0.1,0.7-0.2,1-0.3c0.2-0.1,0.3-0.1,0.5-0.2c0.3-0.1,0.6-0.2,0.9-0.3
					c0.2-0.1,0.3-0.1,0.5-0.2c0.3-0.1,0.5-0.2,0.8-0.4c0.1-0.1,0.3-0.1,0.4-0.2c0.3-0.1,0.5-0.3,0.8-0.4c0.1-0.1,0.2-0.1,0.4-0.2
					c0.4-0.2,0.7-0.4,1.1-0.7c4.6-3.1,5.3-7.1,5.4-11.2c0-1.1,0-2.2,0-3.3c0-3.4,0.3-6.7,2.5-9.7c0.5-0.6,5.4-4.8,9.5-7.2L978,793
					l-0.6,68.6l-121.9-51.3c-4.2,2.4-9.1,6.6-9.5,7.2c-2,2.6-2.4,5.6-2.5,8.6c0,1.1,0,2.2,0,3.3c0,4.5-0.4,8.9-5.4,12.2
					c-0.3,0.2-0.7,0.5-1.1,0.7c-0.1,0.1-0.3,0.1-0.4,0.2c-0.3,0.1-0.5,0.3-0.8,0.4c-0.1,0.1-0.3,0.1-0.5,0.2
					c-0.3,0.1-0.5,0.2-0.8,0.4c-0.2,0.1-0.3,0.1-0.5,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0.1-0.4,0.1-0.6,0.2c-0.2,0.1-0.3,0.1-0.5,0.2
					c-0.3,0.1-0.7,0.2-1,0.3c-0.1,0-0.3,0.1-0.4,0.1c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.2,0c-0.6,0.2-1.3,0.4-2,0.5c-0.1,0-0.2,0-0.3,0.1
					c-0.1,0-0.2,0-0.2,0c-0.7,0.2-1.5,0.3-2.2,0.4c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c-0.7,0.1-1.5,0.2-2.2,0.3c-0.1,0-0.2,0-0.3,0
					c-0.1,0-0.2,0-0.3,0c-0.9,0.1-1.7,0.2-2.6,0.2c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.2,0c-1,0.1-1.9,0.1-2.9,0.1c-0.1,0-0.2,0-0.3,0
					c0,0-0.1,0-0.1,0c-1.2,0-2.4,0-3.6,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c-0.5,0-1,0-1.6-0.1c-0.3,0-0.5,0-0.8,0
					c-0.7,0-1.5-0.1-2.2-0.2c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-1-0.1-1.9-0.2-2.9-0.3c-0.3,0-0.5-0.1-0.8-0.1c-0.9-0.1-1.7-0.2-2.6-0.3
					c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0c-0.8-0.1-1.6-0.3-2.4-0.4c-0.1,0-0.3-0.1-0.4-0.1c-0.7-0.1-1.4-0.2-2.1-0.4
					c-0.3-0.1-0.6-0.1-0.9-0.2c-0.5-0.1-1-0.2-1.6-0.3c-0.3-0.1-0.7-0.2-1-0.2c-0.5-0.1-1-0.2-1.5-0.3c-0.1,0-0.3-0.1-0.4-0.1
					c-0.1,0-0.2-0.1-0.3-0.1c-1.3-0.3-2.6-0.7-4-1c-0.2,0-0.3-0.1-0.5-0.1c-1.3-0.4-2.7-0.8-4-1.2c-0.1,0-0.3-0.1-0.4-0.1
					c-1.4-0.5-2.7-0.9-4.1-1.4c-0.1,0-0.2-0.1-0.2-0.1c-1.4-0.5-2.8-1.1-4.2-1.7c-0.7-0.3-1.3-0.6-2-0.9c-0.9-0.4-1.8-0.8-2.7-1.3
					c-0.3-0.1-0.6-0.3-0.9-0.4c-0.6-0.3-1.1-0.6-1.7-0.9c-0.3-0.2-0.6-0.3-1-0.5c-0.5-0.3-1-0.6-1.5-0.8c-0.3-0.2-0.6-0.4-0.9-0.5
					c-0.2-0.1-0.4-0.2-0.6-0.3c-0.3-0.2-0.6-0.4-0.9-0.6c-0.3-0.2-0.5-0.3-0.8-0.5c-0.5-0.3-1.1-0.7-1.6-1c-0.2-0.1-0.3-0.2-0.5-0.3
					c-0.7-0.5-1.3-0.9-1.9-1.4c-0.2-0.1-0.3-0.2-0.5-0.4c-0.4-0.3-0.7-0.6-1.1-0.9c-0.1-0.1-0.1-0.1-0.2-0.2
					c-0.4-0.4-0.9-0.8-1.3-1.1c-0.2-0.2-0.4-0.3-0.6-0.5c-0.6-0.5-1.1-1.1-1.6-1.6c0,0,0,0,0,0c-0.4-0.5-0.9-1-1.2-1.4
					c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2-0.3-0.4-0.5-0.6-0.8c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.3-0.4-0.7-0.6-1
					c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.1-0.1-0.2-0.1-0.2c-0.2-0.4-0.3-0.7-0.5-1.1c0-0.1,0-0.1-0.1-0.2c-0.1-0.2-0.2-0.5-0.3-0.7
					c-0.1-0.2-0.1-0.3-0.1-0.5c0-0.1-0.1-0.2-0.1-0.2c-0.1-0.4-0.2-0.7-0.2-1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2
					c-0.1-0.4-0.1-0.8-0.1-1.2c0,0,0-0.1,0-0.1c0.1-16.3,0.3-32.5,0.4-48.8L682.6,737.5z"/>
				<g>
					<path fill="#1C9DD8" d="M1207.9,637.8l-294.8-124L683.2,669l122.1,51.4c-2.7,2.3-7.3,4.9-8.2,5.4c-4.3,1.9-9.8,3.4-15.1,4.4
						c-10.3,1.9-22.9,1.6-30.2,6.5c-11.4,7.7-2.4,21.7,20.1,31.8c0.7,0.3,1.3,0.6,2,0.9c24,10.1,52.9,11.8,64.7,3.9
						c9.6-6.4,2.2-16.7,7.9-24.2c0.5-0.6,5.4-4.8,9.5-7.2L978,793l95.1-64.2c-0.4-0.2-0.8-0.4-1.2-0.6c-4.7-2.1-11.4-4.3-12.5-4.6
						c-14.2-3.1-33.4,0.7-45.6-4.5c-15-6.7-12-21.9,6.5-34.3c18.7-12.6,46.1-17.7,61.2-11.3c9.4,4.2,9,10.7,12.6,16.1
						c1.9,2.8,4.7,5.8,8.4,8.1c0.4,0.3,2.1,1.1,4.3,2.1c1.8,0.8,3.9,1.7,5.9,2.3L1207.9,637.8z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#128A93" d="M938.3,916.9c0,0.2,0,0.3,0,0.5c0,0.2,0,0.4,0,0.5c0,0.1,0,0.3,0,0.4c0,0.2,0,0.3-0.1,0.5
						c0,0.2-0.1,0.3-0.1,0.5c0,0.1-0.1,0.3-0.1,0.4c0,0.2-0.1,0.3-0.1,0.5c0,0.1-0.1,0.2-0.1,0.4c0,0.1-0.1,0.1-0.1,0.2
						c-0.2,0.5-0.4,0.9-0.6,1.4c0,0,0,0,0,0c-0.2,0.4-0.5,0.9-0.8,1.3c0,0.1-0.1,0.1-0.1,0.2c-0.3,0.4-0.6,0.8-0.9,1.2
						c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.3-0.4,0.4c-0.1,0.1-0.2,0.2-0.4,0.3
						c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.2-0.4,0.3-0.7,0.5c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.2-0.5,0.3-0.8,0.5
						c-0.1,0.1-0.2,0.1-0.3,0.2c-0.2,0.1-0.5,0.3-0.7,0.4c-0.1,0.1-0.3,0.2-0.4,0.2c-0.4,0.2-0.8,0.4-1.2,0.6
						c-1.6,0.7-3.2,1.2-4.9,1.6c-1.4,0.3-2.8,0.6-4.2,0.8c-1.4,0.2-2.8,0.4-4.2,0.5c-2,0.2-4.1,0.3-6.1,0.3
						c-4.5,0.1-9.2,0.1-13.7,0.2c-2,0.1-4.1,0.2-6,0.3l0.6,69.7c2-0.2,4-0.3,6-0.3c4.5-0.1,9.2-0.1,13.7-0.2
						c2.1-0.1,4.1-0.2,6.1-0.3c1.4-0.1,2.9-0.3,4.2-0.5c1.5-0.2,2.9-0.5,4.2-0.8c1.7-0.4,3.4-0.9,4.9-1.6c0.4-0.2,0.8-0.4,1.2-0.6
						c0.1-0.1,0.2-0.1,0.3-0.2c0.3-0.1,0.5-0.3,0.8-0.4c0,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.2-0.1c0.3-0.2,0.5-0.3,0.8-0.5
						c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.2,0.5-0.3,0.7-0.5c0.1-0.1,0.1-0.1,0.2-0.2c0.1,0,0.1-0.1,0.2-0.1c0.1-0.1,0.3-0.2,0.4-0.3
						c0.1-0.1,0.3-0.2,0.4-0.4c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0.1-0.1,0.1-0.1c0.3-0.4,0.7-0.8,0.9-1.2
						c0,0,0,0,0.1-0.1c0,0,0-0.1,0.1-0.1c0.3-0.4,0.5-0.8,0.8-1.3l0,0c0,0,0,0,0,0c0.2-0.5,0.4-0.9,0.6-1.4c0,0,0-0.1,0-0.1
						c0,0,0,0,0-0.1c0-0.1,0.1-0.3,0.1-0.4c0-0.2,0.1-0.3,0.1-0.5c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0.1-0.2,0.1-0.3c0,0,0-0.1,0-0.1
						c0-0.2,0.1-0.4,0.1-0.6c0-0.1,0-0.3,0-0.4c0-0.2,0-0.4,0-0.6c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1L938.3,916.9z"/>
					<path fill="#128A93" d="M882.1,934.1C882.1,934.1,882,934.1,882.1,934.1c-1.4,0.3-8,2.6-12.7,4.7c-0.4,0.2-0.9,0.4-1.2,0.6
						l0.6,69.7c0.4-0.2,0.8-0.4,1.2-0.6c4.7-2.1,11.4-4.3,12.6-4.6c0,0,0.1,0,0.1,0c1.1-0.2,2.2-0.4,3.4-0.6
						c1.4-0.2,2.8-0.3,4.2-0.5l-0.6-69.7c-1.4,0.1-2.8,0.3-4.2,0.5C884.3,933.6,883.2,933.8,882.1,934.1z"/>
				</g>
				<path fill="#20A7AA" d="M965.4,1074.2l-0.6-69.7l-124.1,52.2l0.5,58c-14.4,1.7-27.4,0.4-34.3-4.3c-4.6-3.1-5.4-7.2-5.5-11.3
					c0-1.1,0-2.3,0-3.4c0-3.4-0.3-6.8-2.5-9.8c-0.5-0.6-5.5-4.9-9.7-7.4l-123.9,52.2l0.6,69.7l123.9-52.2c4.2,2.5,9.2,6.7,9.7,7.4
					c2,2.7,2.4,5.7,2.5,8.7c0,1.2,0,2.3,0,3.5c0,4.6,0.4,9,5.5,12.4c12,8.1,41.4,6.3,65.8-3.9c0.7-0.3,1.4-0.6,2-0.9
					c16.2-7.2,25.5-16.5,25.5-24c-0.1-16.5-0.3-33.1-0.4-49.6L965.4,1074.2z"/>
				<path fill="#128A93" d="M929,929.3l0.6,69.7c-13,5.5-32.5,1.6-46.9,4.8c-1.1,0.3-7.9,2.5-12.7,4.7c-0.4,0.2-0.9,0.4-1.2,0.6
					l-0.6-69.7c0.4-0.2,0.8-0.4,1.2-0.6c4.8-2.1,11.6-4.4,12.7-4.7C896.5,930.9,916,934.7,929,929.3z"/>
				<polygon fill="#128A93" points="665.2,1130.6 431.5,972.9 432.1,1042.6 665.8,1200.3 				"/>
				<path fill="#39C1D2" d="M895.1,1073.3c-7.4-5-20.3-4.7-30.7-6.6c-5.4-1-11-2.5-15.3-4.5c-0.9-0.4-5.6-3.1-8.4-5.4l124.1-52.2
					l-96.7-65.2c0.4-0.2,0.8-0.4,1.2-0.6c4.8-2.1,11.6-4.4,12.7-4.7c14.4-3.2,33.9,0.7,46.9-4.8c14.8-6.6,11.7-22-7.1-34.7
					c-19-12.8-46.8-18-62.6-11.3c-9.1,4-8.7,10.7-12.4,16.2c-1.9,2.9-4.8,5.9-8.6,8.2c-0.5,0.3-2.2,1.2-4.4,2.1
					c-1.8,0.8-4,1.7-6,2.4l-96.8-65.3L431.5,972.9l233.7,157.7l123.9-52.2c4.2,2.5,9.2,6.7,9.7,7.4c5.8,7.6-1.7,18,8,24.6
					c12,8.1,41.4,6.3,65.8-3.9c0.7-0.3,1.4-0.6,2-0.9C897.5,1095.4,906.7,1081.2,895.1,1073.3z"/>
			</g>
			<g>
				<ellipse display="none" fill="#9EABD3" cx="1684.1" cy="1340.4" rx="168.4" ry="74.9"/>
				<g>
					<g>
						<g>
							<path fill="#17181C" d="M1627.4,1323.6c-11.9,12-31.5,18.4-31.5,18.4c-16.5,4.2-26.3-6.8-26.3-6.8c-3.5-5,0.8-7.7,0.8-7.7
								c34.8-26.5,45.3-34,45.3-34h32c1.6,12.9,0.7,18.1,0.7,18.1c-2.8,3.8-8.6,9.1-17.6,10.6c-0.7,0.1-1.9-0.9-2.5-0.6
								L1627.4,1323.6z"/>
						</g>
						<path d="M1570.2,1327.6c0.1,0.7,0.3,1.5,0.7,2.2c2.7,5.8,10.2,8.2,16.2,8.1c7.2-0.1,13.7-3.6,19.9-6.8c6-3,11.3-6.9,16.5-11.2
							c3.6-3,8.2-4.4,12.5-6.2c4.8-2.1,8.4-5.4,12.6-8.5c0,3.4-0.2,6.3-0.2,6.3c-2.8,3.8-8.6,9.1-17.6,10.6
							c-0.7,0.1-1.9-0.9-2.5-0.6l-0.9,2c-11.9,12-31.5,18.4-31.5,18.4c-16.5,4.2-26.3-6.8-26.3-6.8
							C1566.5,1330.8,1569.5,1328.1,1570.2,1327.6z"/>
					</g>
					<g>
						<path fill="#E59375" d="M1651.2,1034c-35.6,39.3-49.7-2.3-49.7-2.3l-4.4-241.4c-0.5,3.6-1.6-24.3,43.3-18.3
							c44.8,6,56.1,23.5,56.1,23.5S1671.9,962.3,1651.2,1034"/>
						<path fill="#E59375" d="M1601.3,1028.7c0,0,18.1,254.3,19.5,263.2c0,0,21.9,4.9,22-0.2c0.3-12.2,25.3-161,25.6-180.1
							c0.8-48.5-11.7-57.6-24.1-90.3C1644.3,1021.3,1601.2,989.4,1601.3,1028.7z"/>
					</g>
					<g>
						<path fill="#104473" d="M1661.9,1043.2c20.7-71.7,34.6-247.6,34.6-247.6s-11.3-17.5-56.1-23.5c-44.9-6-43.7,21.9-43.3,18.3
							c0,0-13.6,130.9-1.1,249.9C1596,1040.2,1634.3,1060.6,1661.9,1043.2z"/>
					</g>
					<path fill="#F7A483" d="M1729.8,660.8c6.3,22.6,32.5,108.8,32.5,108.8c-93.8,112.2-164.4,14-165.3,3.8
						c-1.1-12.2,12.6-59,15-109.5C1612,664,1637.6,624.6,1729.8,660.8z"/>
					<path fill="#104473" d="M1597.5,801.5c-4.4-30.2,4.8-60.7,9.5-91.2h137.4c8.8,29.3,17.9,59.4,17.9,59.4s5.9,69.3-52.9,77.6
						C1709.4,847.2,1606.9,865,1597.5,801.5z"/>
					<g>
						<path fill="#F7A483" d="M1753.8,1039.3c-29.4,44.1-49.5,5.1-49.5,5.1l-47.6-235.2c0.1,3.6,1.4-30.6,46.7-31.3
							c45.2-0.7,59,14.9,59,14.9S1763.6,965.3,1753.8,1039.3"/>
						<path fill="#F7A483" d="M1703.6,1041.4c0,0,47.2,267.9,50,276.5c0,0,22.4,1.5,21.8-3.5c-1.6-12.1,2.5-181,0-200
							c-6.4-48-13-56.3-30.2-86.7C1745.1,1027.8,1697.7,1002.6,1703.6,1041.4z"/>
					</g>
					<g>
						<path fill="#17181C" d="M1751.4,1312.8l-13.3,40.5c0,0,2.7,36.2,31.8,13.8l8.7-8.4c4.4-3.7,3.1-8.9,3.1-8.9l-2.6-15.5
							l-3.1-21.5C1776.1,1312.8,1757.9,1300.4,1751.4,1312.8z"/>
						<path d="M1770,1361.9l8.7-8.4c1.7-1.5,2.6-3.1,3-4.6l0.2,0.9c0,0,1.3,5.2-3.1,8.9l-8.7,8.4c-29.1,22.4-31.8-13.8-31.8-13.8
							l0.5-1.6C1740.3,1360.2,1746.7,1379.8,1770,1361.9z"/>
					</g>
					<g>
						<path fill="#152035" d="M1760.1,1040.2"/>
						<path fill="#065099" d="M1698.5,1050.9c-16.5-48.6-47.8-249.4-47.8-249.4c0.1,3.6,9.4-48,54.7-48.6
							c45.2-0.7,56.9,16.8,56.9,16.8s14.2,196.6,4.3,270.6C1766.7,1040.2,1734.8,1077,1698.5,1050.9z"/>
					</g>
					<path fill="#E59375" d="M1619,506.3c0,0-76.7,90.2-114.9,120.1c0,0-25.6-9.5-24.8-26.9c0,0,56.2-83.1,91.4-132.3
						C1570.7,467.3,1618.4,453.6,1619,506.3z"/>
					<path fill="#E59375" d="M1490.4,592c0,0-83.8,48.8-118.5,65.1c0,0-6.2,9.7,3.8,10.7c16.8,1.5,133.5-38,136.4-47.2
						C1525.4,577.8,1490.4,592,1490.4,592z"/>
					<path fill="#E59375" d="M1566.9,496.2c6,16.1,23.9,24.4,40,18.5c16-5.9,24.2-23.7,18.1-39.9c-6-16.1-23.9-24.4-40-18.5
						C1569,462.3,1560.9,480.1,1566.9,496.2z"/>
					<path fill="#C5D0D8" d="M1605.2,454.7c0,0-27.4-9.6-46.2,17.3l-66.9,97.1c0,0,13.8,30.9,46.4,47.8
						C1538.5,616.8,1658.3,506.4,1605.2,454.7z"/>
					<path fill="#F7A483" d="M1755,1061.7"/>
					<path fill="#F8A07D" d="M1682.7,422.1c0,0,5.5-10.6,17.7-31c0,0,25.9-26.6,11.4-60.1c0,0-8-28-56.9-23.9
						C1654.9,307,1650.3,384.1,1682.7,422.1z"/>
					<path fill="#F7A483" d="M1684.7,378.4c0,0-8.2,76.8,9.3,82.6l-62.6-3.7c0,0,9.8-23.2-1.6-40.3L1684.7,378.4z"/>
					<path fill="#E1876F" d="M1634.8,443.9c1-17.5-1.2-21.1-5-26.9l54.9-38.5c0,0-2.7,24.8-1.7,47.3
						C1667.1,440.8,1653.9,444.3,1634.8,443.9z"/>
					<path fill="#F7A483" d="M1587.2,454.5c-13.9,10.2-15.5,34.5-10.6,49.8c5.7,17.5,8,39.8,13.7,65.1c7.8,34.3,13,60.6,21.7,94.6
						c7.2,28.1,120.2,5.5,120.2,5.5l40.5-138.4c0,0,7-36.1-25.8-48.2c0,0-34.5-18.6-59.7-27.2c-22.3-7.6-30.4-8.3-53.3-6.9
						C1634,448.8,1603.3,449.9,1587.2,454.5z"/>
					<path fill="#271000" d="M1607.4,312.8c-3.2,14.7-6.9,22.4-2.8,35.9c3.7,12.1,2.8,24.9,8.1,36.6c7.6,16.8,17.4,14.6,24.9-0.8
						c7.7-15.8,15.7-37.1-1.7-48.8C1624.7,328.2,1620.7,315.3,1607.4,312.8L1607.4,312.8z"/>
					<path fill="#F8A07D" d="M1617,427.9l-2.6-8.7c0,0-6.6-32.1-6.6-32.1c-0.8-4-0.5-9.3,0.5-13.3c1.1-4.4,1.9-8.9,1.1-13.7
						c-4-23,8.6-35,8.6-35c14.3-20.4,45.8-18.8,45.8-18.8c38.8,3.9,40.2,41.7,40.2,41.7c5.1,25.4-15.9,45.2-16,45.3
						c-1.7,1.6-1.3,9.9-1.9,12.2c-2.6,9.9-12.4,15.6-21.1,19.4c-9.6,4.2-21.1,8.6-31.5,9C1629.6,434.1,1618.5,432.9,1617,427.9z"/>
					<path fill="#271000" d="M1608.6,386.6c5.1,4.6,12,2.5,17.5,5.8c3.6,2.2,5.3,2,9.2,0.9c3.1-0.9,5.6-0.6,8.8,0.3
						c3.2,0.9,9.1,3.8,12.3,5c4.8,1.9,10.2,1.3,13.9-3.2c3.9-4.8,5.4-5.7,7.2-11.4c3.4-10.5,14-11.1,21.3-5.2
						c-4.8,9-10.7,14.6-10.7,14.6c-1.7,1.6-1.3,16.7-1.9,19c-2.6,9.9-12.4,15.6-21.1,19.4c-9.6,4.2-24.1,4.8-34.6,5.2
						c-4,0.2-12-4-13.5-9l-2.6-8.7c0,0-6.6-32.1-6.6-32.1c-0.1-0.6-0.2-1.2-0.2-1.8C1607.9,385.8,1608.3,386.2,1608.6,386.6z"/>
					<path fill="#271000" d="M1607.9,326.5c8.8,0,8.7,7.4,15.4,11.5c14.4,8.8,36.8-4.1,48.5,10.1c1,1.3,2,2.8,2.9,4.5
						c3.4,6.4,4.5,14.2,5.6,21.3c0.3,2.1-1.2,7.5,0.7,9.1c1.1,0.9,3,0.7,5,0c0.4,3.2-2.2,5.7-3.3,8.6c-1.1,3-2.8,5.5-1.3,8.5
						c4.1,7.7,0.9,6.4,1.3,21.9c2.5-3.7,8.7-8.1,10.6-9.8c5.6-4.9,11-10.3,15-16.6c4-6.2,6.6-13.2,9.1-20.1
						c4.2-11.3,6.6-23.4,3.3-35.3c0,0-2.7-27.4-25.6-30.4c0,0,2.9-17.7-39-22.3c0,0-33.1,0.5-34.9-10.9c0,0-6.7,5.7-5,14.6
						c0,0-13.6,4.2-19.8-11.4C1596.4,279.8,1585.1,320.8,1607.9,326.5z"/>
					<g>
						<g>
							<path fill="#F8A07D" d="M1682.2,399.8c1,1.3,2.5,2.3,4.4,2.6c5.2,1,10.5-3.9,11.7-10.9c1.2-6.9-2-13.4-7.2-14.3
								c-2.5-0.4-5,0.4-7,2.2c-3,2.6-4,8.2-4,12.3C1680,394.9,1680.6,397.8,1682.2,399.8z"/>
						</g>
						<g>
							<g>
								<path fill="#E98B6F" d="M1687.8,383.4c0.8-3.6,7.8,0.8,6.5,2.7c-0.8,1.1-2.2,1.8-3.3,2.6c-1.5,1-2.4,1.9-2.8,3.8
									c-0.1,0.2-0.2,0.8,0,1c0.9,0.8,4.5-2.4,5.3-3.1c1.5-1.3,2-3.9,1.6-5.8c-0.5-2.1-1.6-3.1-3.8-3.3c-1.7-0.1-3.4-0.2-3.6,1.9
									C1687.7,383.3,1687.7,383.6,1687.8,383.4L1687.8,383.4z"/>
							</g>
							<g>
								<path fill="#E98B6F" d="M1689.7,387.5c-0.7-0.9-1.5-0.3-2.4,0.2c-0.8,0.4-1.3,1.2-1.6,2c-0.7,1.9-0.7,4.5,0.2,6.3
									c0.7,1.5,2.1,2.5,3.8,2.6c2.3,0.2,3.2-1.7,3.5-3.6c0.1-0.5,0.3-1.7-0.2-2.1c-0.5-0.4-1,0.4-1.4,0.7c-0.6,0.4-1.3,0.5-2,0.5
									c-1.3,0-2.5-0.8-3.2-1.9c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1,1.2-0.1,1.5,0.1,0.9c0.1-0.3,0.3-0.6,0.5-0.8c0.6-0.8,1.6-1.3,2.3-2
									C1689.4,389.3,1690,388,1689.7,387.5L1689.7,387.5z"/>
							</g>
						</g>
					</g>
					<path fill="#E98B6F" d="M1628.2,384.6l10.6,4.1c0,0-14.3,0.9-14.8-3.3c0,0,2.2-8.1,4.5-15.8
						C1628.5,369.7,1626.6,384,1628.2,384.6z"/>
					<g>
						<path fill="#271000" d="M1646.7,369.7c0,2.2,1.2,4,2.8,4c1.5,0,2.7-1.8,2.7-4c0-2.2-1.2-4-2.7-4
							C1647.9,365.7,1646.7,367.5,1646.7,369.7z"/>
						<path fill="#271000" d="M1616.2,366.3c0,2.2,1.2,4,2.8,4c1.5,0,2.7-1.8,2.7-4c0-2.2-1.2-4-2.7-4
							C1617.4,362.3,1616.2,364.1,1616.2,366.3z"/>
						<path fill="#271000" d="M1643.8,361.9c-1,0.2-1.2-1.1-1.2-1.1c-0.3-1.3,0.1-2.4,0.1-2.4c0.4-0.9,1.2-1.2,1.2-1.2
							c2.6-0.4,7.5,0.3,11.7,1.5c2.3,0.7,6.1,2.4,6.3,4.6c0.1,0.6,0.1,1.7-0.6,1.9C1661.3,365.2,1653.3,362.7,1643.8,361.9z"/>
						<path fill="#271000" d="M1609.1,358.4c2.8,0,7.7,0.3,13.6,1.4c0.9,0.5,1.5-0.7,1.5-0.7c0.6-1.2,0.6-2.3,0.6-2.3
							c-0.1-1-0.8-1.5-0.8-1.5c-2.4-1-5.7-1.6-10.1-1.6c-1.3,0-3.6,0.2-5.1,0.7C1608.8,354.4,1608.5,355.2,1609.1,358.4z"/>
					</g>
					<g>
						<path fill="#FFFFFF" d="M1622.3,397.6c-0.9,0-1.3,0.4-1,1c0,0,3.8,7.2,10.5,8c6.7,0.8,12.1-5.2,12.1-5.2
							c0.5-0.6,0.1-1.2-1-1.3C1642.9,400,1631.4,398.5,1622.3,397.6z"/>
					</g>
					<path fill="#065099" d="M1752.8,742.2c0,0,24.1,38.8,0,82.3c-24.1,43.5-97.5,27.7-97.7-28.7
						C1654.9,740.2,1705.6,718.9,1752.8,742.2z"/>
					<path fill="#C5D0D8" d="M1634,448.8c0,0-30.7,1.1-46.7,5.7c-13.9,10.2-15.5,34.5-10.6,49.8c5.7,17.5,8,39.8,13.7,65.1
						c7.8,34.3-2,59.3,6.6,93.3c7.2,28.1,156.7,0,156.7,0l19-131.6c0,0,7-36.1-25.8-48.2c0,0-32.9-18.1-58.2-26.6
						c0,0,3.9,26.6-39.5,19.3C1649.3,475.5,1622.8,469.1,1634,448.8z"/>
					<path fill="#C5D0D8" d="M1753.7,662.6c6.3,22.6,11.4,98.8,11.4,98.8c-17.2,71.3-175.4,20-176.3,9.8c-1.1-12.2,3.7-70.5,6.2-121
						C1595,650.2,1661.5,626.4,1753.7,662.6z"/>
					<path fill="#F7A483" d="M1707.1,534.4c10.3,21,48.6,126.9,61,143.7c3.3,4.5,34.9,24.8,42-2.9c-17.4-110-43.7-172.7-43.7-172.7
						s-30.7-25.6-55.3-1.9C1711,500.5,1699.8,519.8,1707.1,534.4z"/>
					<path fill="#F7A483" d="M1702.7,515.6c-3.4,20.2,10.2,39.2,30.3,42.6c20.2,3.4,39.2-10.2,42.6-30.3
						c3.4-20.2-10.2-39.2-30.3-42.6C1725.2,481.9,1706.1,495.4,1702.7,515.6z"/>
					<path fill="#C5D0D8" d="M1739.2,478.8c0,0,31.8,11.9,39.3,37.7l19.6,70.2c0,0-25.7,37.4-64.1,36.2l-37.9-84.1
						C1696.3,538.8,1670.8,491.5,1739.2,478.8z"/>
					<path fill="#E59375" d="M1332.1,679.7l9.8-0.3c0,0-17.9,4.6-23.6-1.2c0,0-1.5-0.9,0.8-3c0,0,15.8-0.5,22.7-2.5
						c0,0-15.6,3.8-26.8,2.7c0,0-4.4-2.9,0.3-5.5c-3.5-1.4-0.5-3.7-0.5-3.7s3.2-1.8,10.6-4.1c0,0,11.4-3.7,24.4-9.5
						c0,0-10.3-6.7-6.4-12.2l15.9,8.4l14.4,9.3l1.9,9.7l-15,10.4c0,0-14.6,5.8-22.8,6.1C1337.9,684.3,1314.3,683.4,1332.1,679.7z"/>
					<path fill="#065099" d="M1698.5,1050.9l41.1,256.4c0,0,13.6,13.1,45.3,0l5.3-125.3c0,0,9.3-82.8-23.5-141.8
						C1766.7,1040.2,1702.1,988.1,1698.5,1050.9z"/>
					<path fill="#104473" d="M1661.9,1043.2c0,0,17.5,25.3,1.8,120.4l-16.1,129.9c0,0-18.5,11.1-39.3,0.5l-12.9-258.7
						C1595.5,1035.3,1607.1,991.3,1661.9,1043.2z"/>
				</g>
			</g>
			<g>
				<path fill="#128A93" d="M1361.7,730.2c0-3.1-1.7-5.9-5-8.2c-0.5-0.4-1.1-0.7-1.7-1c-0.2-0.1-0.4-0.2-0.6-0.3
					c-0.4-0.2-0.9-0.4-1.3-0.6c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.7-0.3-1.5-0.5-2.2-0.8c0,0,0,0,0,0c0,0,0,0,0,0
					c-0.8-0.2-1.6-0.4-2.4-0.6c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3-0.1c-1-0.2-1.9-0.4-2.9-0.6c0,0,0,0-0.1,0
					c-0.1,0-0.2,0-0.2,0c-1-0.2-2.1-0.3-3.1-0.5c-0.1,0-0.2,0-0.3,0c-1.1-0.1-2.2-0.3-3.3-0.4c-0.1,0-0.1,0-0.2,0
					c-0.9-0.1-1.8-0.2-2.7-0.3c-1.1-0.1-2.1-0.2-3.2-0.4c-0.2,0-0.4,0-0.6-0.1c-1-0.1-2-0.3-3-0.4c-0.5-0.1-1-0.2-1.6-0.3
					c-1-0.2-2.1-0.4-3.2-0.6c-0.2,0-0.4-0.1-0.6-0.1c-0.8-0.2-1.5-0.4-2.3-0.5c0,0,0,0-0.1,0c-0.8-0.2-1.5-0.4-2.3-0.6
					c-0.2-0.1-0.5-0.1-0.7-0.2c-0.5-0.2-1-0.3-1.5-0.5c-0.3-0.1-0.5-0.2-0.7-0.3c-0.5-0.2-1-0.4-1.5-0.6c-0.2-0.1-0.4-0.1-0.6-0.2
					c-0.6-0.3-1.3-0.5-1.9-0.8c-0.9-0.4-5.6-3.1-8.4-5.5l0.6,69.7c2.7,2.3,7.4,5,8.4,5.5c0.6,0.3,1.2,0.5,1.9,0.8c0,0,0.1,0,0.1,0.1
					c0.1,0.1,0.3,0.1,0.4,0.2c0.5,0.2,1,0.4,1.6,0.6c0.2,0.1,0.5,0.2,0.7,0.2c0.5,0.2,1,0.3,1.6,0.5c0.1,0,0.3,0.1,0.4,0.1
					c0.1,0,0.2,0,0.2,0.1c0.8,0.2,1.5,0.4,2.3,0.6c0,0,0,0,0,0c0.8,0.2,1.6,0.4,2.3,0.6c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0.1
					c1.1,0.2,2.1,0.5,3.2,0.6c0.4,0.1,0.9,0.2,1.3,0.2c0.1,0,0.2,0,0.3,0c1,0.2,2,0.3,3,0.4c0.2,0,0.4,0.1,0.6,0.1
					c1,0.1,2.1,0.2,3.1,0.4c0,0,0,0,0,0c0.7,0.1,1.4,0.2,2,0.2c0.3,0,0.6,0.1,0.8,0.1c1.1,0.1,2.3,0.3,3.4,0.4c0.1,0,0.2,0,0.3,0
					c1.1,0.1,2.1,0.3,3.1,0.5c0.1,0,0.2,0,0.3,0c1,0.2,2,0.4,2.9,0.6c0.2,0,0.4,0.1,0.6,0.1c0.8,0.2,1.6,0.4,2.4,0.6
					c0.8,0.2,1.5,0.5,2.3,0.8c0.2,0.1,0.4,0.2,0.6,0.2c0.5,0.2,0.9,0.4,1.3,0.6c0.2,0.1,0.4,0.2,0.6,0.3c0.6,0.3,1.2,0.6,1.7,1
					c3.4,2.3,5,5.1,5,8.2L1361.7,730.2z"/>
				<path fill="#128A93" d="M1260.7,883.6c0.2-0.1,0.5-0.1,0.7-0.2c0.3-0.1,0.6-0.1,1-0.2c0.3,0,0.5-0.1,0.8-0.1
					c0.2,0,0.5-0.1,0.7-0.1l0.5,58.4l96.7,65.2l-0.6-69.7l-80.1-54c1.2,0.1,2.3,0.3,3.5,0.5c0.3,0,0.5,0.1,0.8,0.1
					c0.4,0.1,0.9,0.2,1.3,0.2c0.3,0.1,0.6,0.1,1,0.2c0.4,0.1,0.8,0.2,1.3,0.3c0.3,0.1,0.6,0.1,0.9,0.2c0.7,0.2,1.4,0.3,2.1,0.5
					c0.2,0,0.4,0.1,0.6,0.1c0.9,0.2,1.7,0.5,2.6,0.7c0.2,0,0.3,0.1,0.5,0.1c1.2,0.4,2.4,0.7,3.5,1.1c0.1,0,0.1,0,0.2,0.1
					c1.2,0.4,2.3,0.8,3.5,1.3c0.1,0,0.2,0.1,0.3,0.1c1.3,0.5,2.6,1.1,3.9,1.7c0.1,0,0.1,0.1,0.2,0.1c1.3,0.6,2.6,1.2,3.9,1.9
					c0,0,0.1,0,0.1,0.1c1.3,0.7,2.6,1.4,3.8,2.1c0,0,0,0,0.1,0c1.3,0.8,2.5,1.5,3.7,2.3c10.7,7.2,16.3,15.3,16.3,22.2
					c-0.2-23.2-0.4-46.5-0.6-69.7c-0.1-6.9-5.7-15-16.3-22.2c-1.2-0.8-2.4-1.6-3.7-2.3c0,0,0,0-0.1,0c-1.2-0.7-2.5-1.4-3.8-2.1
					c0,0-0.1,0-0.1-0.1c-1.3-0.7-2.6-1.3-3.9-1.9c-0.1,0-0.1-0.1-0.2-0.1c-1.3-0.6-2.6-1.1-3.9-1.7c0,0-0.1,0-0.1,0
					c-0.1,0-0.1,0-0.2-0.1c-1.2-0.5-2.3-0.9-3.5-1.3c0,0-0.1,0-0.1,0c-1.2-0.4-2.4-0.8-3.6-1.1c-0.1,0-0.2-0.1-0.3-0.1
					c-0.1,0-0.1,0-0.2,0c-0.9-0.3-1.7-0.5-2.6-0.7c-0.2,0-0.4-0.1-0.5-0.1c-0.7-0.2-1.4-0.4-2.1-0.5c-0.1,0-0.3-0.1-0.4-0.1
					c-0.1,0-0.3-0.1-0.4-0.1c-0.4-0.1-0.9-0.2-1.3-0.3c-0.3-0.1-0.6-0.1-0.9-0.2c-0.4-0.1-0.9-0.2-1.3-0.2c-0.2,0-0.4-0.1-0.6-0.1
					c-0.1,0-0.1,0-0.2,0c-1.2-0.2-2.4-0.4-3.6-0.5c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0c-1-0.1-2.1-0.2-3.1-0.3
					c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c-1.9-0.1-3.7-0.2-5.5-0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0
					c-0.9,0-1.7,0.1-2.6,0.1c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c-0.9,0.1-1.8,0.2-2.6,0.3c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0
					c-0.4,0.1-0.7,0.1-1,0.2c-0.2,0-0.5,0.1-0.7,0.1c-0.4,0.1-0.7,0.2-1.1,0.2c-0.2,0-0.4,0.1-0.6,0.1c0,0-0.1,0-0.1,0
					c-0.8,0.2-1.5,0.4-2.2,0.6c-0.2,0.1-0.3,0.1-0.5,0.2c-0.7,0.2-1.4,0.5-2.1,0.8c-0.5,0.2-1,0.5-1.5,0.7c-0.1,0.1-0.3,0.2-0.4,0.2
					c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1,0.1-0.3,0.2-0.4,0.3c-0.2,0.1-0.3,0.2-0.5,0.3c-0.3,0.2-0.5,0.4-0.8,0.5
					c-0.1,0.1-0.3,0.2-0.4,0.3c0,0-0.1,0.1-0.2,0.1c-0.3,0.2-0.6,0.5-0.8,0.7c0,0-0.1,0.1-0.1,0.1c-0.2,0.2-0.4,0.4-0.6,0.6
					c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.2-0.4,0.4-0.5,0.7c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1-0.1,0.1-0.1,0.2
					c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.2-0.3,0.4-0.4,0.6c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.2-0.2,0.4-0.4,0.6c0,0.1-0.1,0.1-0.1,0.2
					c0,0.1-0.1,0.2-0.1,0.2c-0.2,0.4-0.4,0.7-0.5,1.1c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.5-0.4,1-0.7,1.5c-0.2,0.4-0.3,0.8-0.5,1.2
					c-0.1,0.1-0.1,0.3-0.2,0.4c-0.2,0.5-0.4,0.9-0.6,1.4c-0.1,0.2-0.3,0.5-0.4,0.7c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2,0.3-0.4,0.7-0.6,1
					c-0.1,0.2-0.3,0.4-0.4,0.6c-0.2,0.3-0.5,0.6-0.7,0.9c-0.1,0.1-0.2,0.3-0.3,0.4c-0.3,0.4-0.7,0.8-1,1.2c0,0-0.1,0.1-0.1,0.1
					c-0.4,0.4-0.8,0.8-1.2,1.2c-0.1,0.1-0.2,0.2-0.4,0.3c-0.3,0.3-0.6,0.6-0.9,0.8c-0.2,0.1-0.3,0.3-0.5,0.4c-0.3,0.3-0.7,0.5-1,0.8
					c-0.2,0.1-0.3,0.2-0.5,0.4c-0.5,0.4-1,0.7-1.6,1c0,0-0.1,0.1-0.2,0.1c0,0,0,0-0.1,0c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1,0.1
					c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0.1-0.3,0.1
					c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.2,0.1-0.4,0.2c-0.2,0.1-0.3,0.2-0.5,0.2c-0.1,0-0.2,0.1-0.2,0.1
					c-0.3,0.1-0.5,0.2-0.8,0.4c-1.8,0.8-4,1.7-5.9,2.4l-96.8-65.3l0.6,69.7l96.8,65.3c2-0.7,4.1-1.6,5.9-2.4c0.5-0.2,1-0.5,1.5-0.7
					c0,0,0,0,0,0c0.2-0.1,0.4-0.2,0.6-0.3c0,0,0,0,0,0c0.2-0.1,0.3-0.1,0.4-0.2c0.1,0,0.1-0.1,0.2-0.1c0.1-0.1,0.2-0.1,0.3-0.2
					c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.2-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1
					c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1-0.1c0,0,0,0,0,0c0.5-0.3,1.1-0.7,1.6-1.1c0.2-0.1,0.3-0.2,0.5-0.3c0.3-0.3,0.7-0.5,1-0.8
					c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.1,0.1-0.1,0.2-0.2c0.3-0.3,0.6-0.6,0.9-0.8c0.1-0.1,0.3-0.2,0.4-0.4c0.4-0.4,0.8-0.8,1.2-1.2
					c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1c0.4-0.4,0.7-0.8,1-1.2c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.3,0.5-0.6,0.7-0.9
					c0.1-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.3,0.4-0.7,0.6-1c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.2,0.2-0.4,0.3-0.6
					c0,0,0,0,0,0c0.2-0.5,0.4-0.9,0.7-1.4c0.1-0.1,0.1-0.3,0.2-0.4c0.2-0.4,0.3-0.8,0.5-1.1c0.2-0.5,0.4-1,0.7-1.5
					c0-0.1,0.1-0.1,0.1-0.2c0.2-0.4,0.4-0.8,0.5-1.2c0.1-0.2,0.2-0.3,0.2-0.4c0.1-0.2,0.2-0.4,0.4-0.7c0.1-0.2,0.2-0.3,0.3-0.5
					c0.1-0.2,0.3-0.4,0.4-0.6c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.1,0.2-0.2,0.2-0.3c0.2-0.2,0.3-0.4,0.5-0.6c0.1-0.1,0.2-0.2,0.3-0.3
					c0.2-0.3,0.5-0.5,0.8-0.8c0,0,0.1-0.1,0.1-0.1c0.3-0.3,0.6-0.6,1-0.8c0.1-0.1,0.3-0.2,0.4-0.3c0.2-0.2,0.5-0.4,0.8-0.6
					c0.2-0.1,0.3-0.2,0.5-0.3c0.3-0.2,0.6-0.4,0.9-0.5c0.1-0.1,0.3-0.1,0.4-0.2c0.5-0.3,1-0.5,1.5-0.7c0.7-0.3,1.4-0.5,2.1-0.8
					c0.2-0.1,0.3-0.1,0.5-0.2C1259.3,884,1260,883.8,1260.7,883.6z"/>
				<g>
					<polygon fill="#20A7AA" points="1660.1,810.9 1660.7,880.6 1361.1,1006.6 1360.5,937 					"/>
				</g>
				<g>
					<path fill="#39C1D2" d="M1126.8,779.3l123.9-52.2c4.2,2.5,9.2,6.7,9.7,7.4c5.8,7.6-1.7,18,8,24.6c12,8.1,41.4,6.3,65.8-3.9
						c0.7-0.3,1.3-0.6,2-0.9c22.9-10.2,32.1-24.5,20.4-32.3c-7.4-5-20.3-4.7-30.7-6.6c-5.4-1-11-2.5-15.3-4.5
						c-0.9-0.4-5.6-3.1-8.4-5.5l124.1-52.2l233.7,157.7L1360.5,937l-96.7-65.2c0.4-0.2,0.8-0.4,1.2-0.6c4.8-2.1,11.7-4.4,12.8-4.7
						c14.4-3.2,33.9,0.7,46.4-4.6c15.3-6.8,12.2-22.2-6.6-34.9c-19-12.8-46.8-18-62.2-11.5c-9.5,4.2-9.1,10.9-12.8,16.4
						c-1.9,2.9-4.8,5.9-8.6,8.2c-0.5,0.3-2.2,1.2-4.4,2.1c-1.8,0.8-4,1.7-5.9,2.4L1126.8,779.3z"/>
				</g>
			</g>
			<g>
				<path fill="#F8A07D" d="M706.7,1107.6c0,0,10.3-6.7,0.4-18.9c-25.4-1.6-55.4-15.4-55.4-15.4c-37.4-11.8-89.9-69.7-113.8-96
					c-26.9-29.6-67.5-4.5-45.7,29.3c40.8,63.2,148.1,104.8,213.9,102.4"/>
				<path fill="#053B6B" d="M428.3,1401.5c15.6-73,20.3-242.1,20.3-242.1s-12.5-16.6-57.6-19.5c-45.2-2.9-42.1,24.9-41.9,21.3
					c0,0,0,119.7,20.8,237.5C369.8,1398.6,402,1420.8,428.3,1401.5z"/>
				<path fill="#007EA3" d="M349.5,1169.4c-4.4-30.2,4.8-60.7,9.5-91.2h137.4c8.8,29.3,17.9,59.4,17.9,59.4s5.9,69.3-52.9,77.6
					C461.4,1215.1,358.9,1232.9,349.5,1169.4z"/>
				<path display="none" fill="#9EABD3" d="M268.9,1696.6c0,58.2,106.1,105.4,237,105.4c130.9,0,237-47.2,237-105.4
					c0-58.2-106.1-105.4-237-105.4C375,1591.2,268.9,1638.4,268.9,1696.6z"/>
				<g>
					<path fill="#17181C" d="M643.7,1692.8l-7.8-0.5c-2.7-0.4-2.6,3.8-3,3.9c-8.2,3.2-15.5,5.7-18.5,5.9c0,0-14.5-1.9-16-6.3l2-26.9
						c16.1-1.4,33-4.5,33-4.5l35.5-5c17.1-1.8,20.2,4.4,20.2,4.4S699.4,1679.6,643.7,1692.8z"/>
					<path fill="#040308" d="M622.2,1691.2c2.6-1.2,6.4-2.5,9.2-3c17.5-3.1,34.6-4.8,49.2-16c3.6-2.7,6.1-6.2,6.3-10.2
						c1.6,1.1,2.1,2,2.1,2s10.4,15.6-45.3,28.8l-7.8-0.5c-2.7-0.4-2.6,3.8-3,3.9c-8.2,3.2-15.5,5.7-18.5,5.9c0,0-14.5-1.9-16-6.3
						l0.5-6.5C604.6,1692.7,614.7,1694.7,622.2,1691.2z"/>
				</g>
				<g>
					<path fill="#17181C" d="M406.8,1686.2l-7.8-0.5c-2.7-0.4-2.6,3.8-3,3.9c-8.2,3.2-15.5,5.7-18.5,5.9c0,0-14.5-1.9-16-6.3l2-26.9
						c16.1-1.4,33-4.5,33-4.5l35.5-5c17.1-1.8,20.2,4.4,20.2,4.4S462.5,1672.9,406.8,1686.2z"/>
					<path fill="#040308" d="M385.3,1684.6c2.6-1.2,6.4-2.5,9.2-3c17.5-3.1,34.6-4.8,49.2-16c3.6-2.7,6.1-6.2,6.3-10.2
						c1.6,1.1,2.1,2,2.1,2s10.5,15.6-45.3,28.8l-7.8-0.5c-2.7-0.4-2.6,3.8-3,3.9c-8.2,3.2-15.5,5.7-18.5,5.9c0,0-14.5-1.9-16-6.3
						l0.5-6.5C367.7,1686,377.8,1688,385.3,1684.6z"/>
				</g>
				<path fill="#053B6B" d="M403.8,1664.4c5.8-6.9,24.9-261.6,24.9-261.6c3.2-39.2-61.9-22.7-61.9-22.7c-9.5,39-7.9,46.3-10.9,94.7
					c-1.2,19.1,1.9,177.3,1.2,189.5C356.9,1664.2,387.2,1684.2,403.8,1664.4z"/>
				<path fill="#30528B" d="M453.7,1113.6c0,0,39.1,66.6,15,110.1c-24.1,43.5-125.4-3.8-119.2-54.2
					C356.4,1114.2,406.5,1090.2,453.7,1113.6z"/>
				<path fill="#F7A483" d="M481.8,1028.7c6.4,22.6,32.5,108.8,32.5,108.8c-71.8,67.7-164.4,14-165.3,3.8
					c-1.1-12.2,12.6-59,15-109.5C364,1031.9,389.6,992.5,481.8,1028.7z"/>
				<path fill="#152035" d="M512,1408.1"/>
				<path fill="#30528B" d="M568.1,1388.9c-7.6-74.3-60.5-263.8-60.5-263.8s-15.5-14.2-59.3-3.1c-43.9,11.2-48.7,68.3-49.6,64.8
					c0,0,51,119.9,118.8,225.6C517.6,1412.4,550.3,1429.2,568.1,1388.9z"/>
				<path fill="#30528B" d="M634.2,1661.5c-1.3-9-67.3-275.1-67.3-275.1c-15.5-32.8-60.2,1.7-60.2,1.7c0.2,23.8,0.8,52.6,16.2,98.5
					c6.1,18.2,62.2,182.5,66.1,194C589,1680.7,622.2,1691.2,634.2,1661.5z"/>
				<g>
					<path fill="#E59375" d="M312.3,856.2c0,0-7.1,119.5-2.6,167.8c0,0,0,21.9,38.6,32.9c0,0,14.1-133.1,25.5-192.5
						C373.8,864.5,351,820.4,312.3,856.2z"/>
					<path fill="#E59375" d="M355.4,887.1c-15.8,6.7-34.2-0.6-40.9-16.3c-6.8-15.7,0.6-33.9,16.5-40.6c15.8-6.7,34.2,0.6,40.9,16.3
						C378.6,862.3,371.3,880.4,355.4,887.1z"/>
				</g>
				<path fill="#5C76BA" d="M339.2,822.4c0,0-28.2,6.5-29.7,39.3l-4.6,117.8c0,0,28.2,18.7,64.7,15.5
					C369.6,995.1,411.8,837.7,339.2,822.4z"/>
				<path fill="#F7A483" d="M507,1429.6"/>
				<path fill="#F7A483" d="M445.8,747.6c0,0,0.3,42.1,3.9,84l-55.5-9.2c0,0,8.1-19.1-3.3-36.3L445.8,747.6z"/>
				<path fill="#F7A483" d="M339.2,822.4c-13.9,10.2-15.5,34.6-10.6,49.8c5.7,17.5,8,39.8,13.7,65.1c7.8,34.3,13,60.6,21.7,94.6
					c7.2,28.1,120.2,5.5,120.2,5.5l40.5-138.4c0,0,7-36.1-25.8-48.2c0,0-34.5-18.6-59.7-27.2c-22.3-7.6-30.4-8.3-53.3-6.8
					C385.9,816.7,355.3,817.8,339.2,822.4z"/>
				<path fill="#30528B" d="M504.8,1110.1c0,0,24.1,38.8,0,82.3c-24.1,43.5-97.5,27.7-97.7-28.7
					C406.9,1108.1,457.6,1086.8,504.8,1110.1z"/>
				<path fill="#5C76BA" d="M385.9,816.7c0,0-30.7,1.1-46.7,5.7c-14.5,10.6-14.2,31.4-11.4,47.2c5.3,30.1,10.8,60.6,13.6,91
					c1.2,13.5,4.6,41,5.6,54.5c1,13.1,5.1,20.3,18.6,27.3c10.2,5.3,17.8,1.1,28.9,1.4c28.3,0.8,56.2-2.3,84.2-5.8
					c1.2-0.1,44.9,29.3,30.5,7.7l15.6-146.7c0,0,7-36.1-25.8-48.2c0,0-23-14.4-48.3-23C450.7,827.8,406.8,828.9,385.9,816.7z"/>
				<path fill="#F8A07D" d="M464.5,796.2l2.4-9.1c0,0,5.6-33.7,5.6-33.7c0.7-4.2,0.2-9.7-1.1-13.9c-1.3-4.5-2.4-9.2-1.7-14.2
					c3.2-24.1-10.3-36.1-10.3-36.1c-15.7-20.7-48.5-17.8-48.5-17.8c-17,2.6-31.8,9.6-40.2,25.1c-7.7,14-11.8,31.2-7.3,46.8
					c5,17.2,14.6,30.6,27.3,42.8c8.2,7.8,16.5,8.8,27.2,12.7c7.4,2.7,21.7,4.3,29.5,4.4C451.6,803.1,463.1,801.5,464.5,796.2z"/>
				<path fill="#3F1901" d="M458,703.2l-5.2,20.9c-6,2.6-8.3,4.9-7.5,12.7l1.9,17.3l-4.7,1.8c0.4-6.9-9.2-7-9.2-7
					c-7-0.4-8.2,5.6-7.8,11.3c1,15-4.4,38.7-16.9,42.9c-5,1.7-14.3,3.4-15.8-3.3c-1.9-8.2-17.4-24.5-17.4-24.5
					c-18.7-18.1-23.3-48.9-14.5-72.5c2.7-7.1,7.9-14.6,13.4-19.8c11.6-11.1,26.3-16.6,41.7-18.6c0,0,21.3-2.6,33.2,5.8
					c7.4,5.2,12.3,10,15.4,14.1C464.5,684.3,469,693.9,458,703.2z"/>
				<g>
					<path fill="#F8A07D" d="M440.9,770.6c-1.4,1.2-3.2,1.9-5.5,2.1c-6.2,0.3-11.7-5.2-12.3-12.2c-0.6-7.1,4-13,10.2-13.3
						c2.9-0.1,5.7,1,7.9,3c3.2,2.9,3.6,8.7,3.2,12.7C444,766,442.9,768.8,440.9,770.6z"/>
				</g>
				<g>
					<g>
						<path fill="#E98B6F" d="M436.2,753.5c-0.6-3.7-7.9,0.3-6.6,2.4c0.7,1.2,2.1,2,3.2,2.7c1.5,1.1,2.2,2.1,2.6,3.9
							c0,0.2,0.1,0.9-0.1,1c-0.9,0.7-4.3-2.7-5.1-3.4c-1.5-1.4-1.8-4-1.3-5.9c0.6-2,1.8-3,3.9-3c1.7,0,3.4,0,3.4,2.1
							C436.3,753.3,436.2,753.7,436.2,753.5L436.2,753.5z"/>
					</g>
					<g>
						<path fill="#E98B6F" d="M434,757.5c0.7-0.8,1.5-0.2,2.4,0.3c0.8,0.5,1.2,1.2,1.5,2.1c0.6,1.9,0.5,4.5-0.5,6.2
							c-0.8,1.4-2.3,2.3-3.9,2.4c-2.3,0.1-3.1-1.9-3.3-3.8c-0.1-0.5-0.2-1.7,0.3-2.1c0.5-0.4,1,0.4,1.3,0.8c0.5,0.4,1.3,0.6,2,0.6
							c1.3,0,2.5-0.7,3.3-1.7c0.1-0.2,0.2-0.4,0.3-0.6c0.1,1.3,0,1.5-0.1,0.9c-0.1-0.3-0.3-0.6-0.5-0.9c-0.6-0.8-1.5-1.4-2.1-2.2
							C434.3,759.2,433.7,757.9,434,757.5L434,757.5z"/>
					</g>
				</g>
				<g>
					<path fill="#F7A483" d="M745.9,1094.9c-2.9-4.3-0.4-6.5-6.2-6.7c-6.6-0.2-12.6-3.5-17.1-3.4c-7.8,0.2-37.5,7.1-17.6,24.1
						c4.6,4,17.9,21.7,27,16.4c13.5-7.8,8.9-4.9,15.8-21.7L745.9,1094.9z"/>
				</g>
				<path fill="#F7A483" d="M469.7,877.4c-10.4,16.2-5.7,37.7,10.5,48c16.2,10.4,37.7,5.7,48-10.5c10.4-16.2,5.7-37.7-10.5-48
					C501.6,856.6,480.1,861.3,469.7,877.4z"/>
				<path fill="#5C76BA" d="M513.3,1006.2c4.5,47.4,7.4,163.5,7.4,163.5c-16.8,44.9-177.3,7-178.2-3.2c-1.1-12.2,2.2-98.5,4.6-148.9
					C347.2,1017.5,421.1,970,513.3,1006.2z"/>
				<path fill="#5C76BA" d="M491.4,846.8c42.3,11.9,47.5,63,47.5,63l19.1,84c0,0-48,38.1-77.3,27.4c0,0-23.8-63.4-31.3-126.6
					C449.4,894.6,449.7,848.2,491.4,846.8z"/>
				<path fill="#3F1901" d="M467,702.7c-4.8,3.8-10.9,1.3-16.3,0.1c-7.4,8.7,1.5,17.5-9.8,21.7c-6,2.2-14.8,2.9-21.4,3.9
					c-5.3,0.9-10.6-0.4-14.4,3.6c-4.1,4.3-0.2,17.6-5,20.1c-8.4,4.5-15.1-18.7-25.8-16.8c-9.2,1.6-10.8,16.1-7.6,23.3
					c-1.1-0.9-1.8-1.3-2.4-1.6c-4.4-11.4-10-11.6-14.8-19.7c-7.5-12.6-6.2-9.6-7.7-21.7c0-0.1,1.5-5.3,1.5-5.4
					c3.5-12.9,9.5-15,12.4-20.5c3-5.8,7.7-9.8,11.7-14.9c3.6-4.7,5.4-11,9.5-15.2c6.3-6.3,16.6-9.5,25.2-10.7
					c9.3-1.2,18.1,4.5,27.4,2.9c3.8-0.7,7.3-4.1,11.1-3.9c4.6,0.3,7.8,4.8,11.8,6.6c7.4,3.3,19.1,2,25.5,7.2
					c7,5.8,5.5,14.1,1.2,21.2c-2.4,4-7.3,6.3-9.5,10.4C468.8,694.6,467.2,702.6,467,702.7z"/>
			</g>
			<g>
				<path fill="#F7A483" d="M1772.6,668.9c0,0-38.5,67.4-68.9,143.3c0,0,0.6,14.4,11.2,10c10.6-4.5,96.5-126.1,96.6-137.2
					C1811.7,656.6,1772.6,668.9,1772.6,668.9z"/>
				<path fill="#F7A483" d="M1683.1,841.1l9.3-3.2c0,0-15.7,9.7-22.9,5.8c0,0-1.7-0.4-0.1-3.1c0,0,14.9-5.2,21-9.2
					c0,0-13.8,8.2-24.8,10.5c0,0-5-1.4-1.3-5.3c-3.8-0.3-1.6-3.4-1.6-3.4s2.5-2.6,9-7.1c0,0,9.9-6.9,20.5-16.3c0,0-11.8-3.4-9.8-9.7
					l17.7,3.3l16.5,4.6l4.7,8.7l-11.2,14.4c0,0-12.2,9.9-20,12.6C1690,843.8,1667.2,850,1683.1,841.1z"/>
			</g>
			<g>
				<path fill="#004E89" d="M1294.9,1073.5c0-3.1,1.6-5.9,5-8.1c0.5-0.4,1.1-0.7,1.7-1c0.2-0.1,0.4-0.2,0.6-0.3
					c0.4-0.2,0.8-0.4,1.3-0.6c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2-0.1,0.3-0.1c0.7-0.3,1.4-0.5,2.2-0.7c0,0,0,0,0,0c0,0,0,0,0,0
					c0.8-0.2,1.5-0.4,2.3-0.6c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.4-0.1c0.9-0.2,1.9-0.4,2.8-0.5c0,0,0.1,0,0.1,0
					c0.1,0,0.2,0,0.3,0c1-0.2,1.9-0.3,2.9-0.4c0.1,0,0.3,0,0.4,0c1.1-0.1,2.1-0.3,3.2-0.4c0.1,0,0.1,0,0.2,0c0.2,0,0.5-0.1,0.7-0.1
					c0.7-0.1,1.4-0.2,2.2-0.2c1-0.1,2-0.2,3-0.4c0.2,0,0.4,0,0.6-0.1c1-0.1,2-0.3,2.9-0.4c0.5-0.1,1-0.2,1.5-0.3
					c1-0.2,2.1-0.4,3.1-0.6c0.2,0,0.4-0.1,0.6-0.1c0.7-0.2,1.5-0.3,2.2-0.5c0,0,0,0,0.1,0c0.7-0.2,1.5-0.4,2.2-0.6
					c0.2-0.1,0.5-0.1,0.7-0.2c0.5-0.2,1-0.3,1.5-0.5c0.3-0.1,0.5-0.2,0.7-0.3c0.5-0.2,1-0.4,1.5-0.5c0.2-0.1,0.4-0.1,0.6-0.2
					c0.6-0.3,1.3-0.5,1.9-0.8c0.9-0.4,5.5-3.1,8.2-5.4l-0.6,68.6c-2.7,2.3-7.3,4.9-8.2,5.4c-0.6,0.3-1.2,0.5-1.9,0.8
					c0,0-0.1,0-0.1,0.1c-0.1,0-0.3,0.1-0.4,0.2c-0.5,0.2-1,0.4-1.5,0.5c-0.2,0.1-0.5,0.2-0.7,0.2c-0.5,0.2-1,0.3-1.5,0.5
					c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.2,0-0.2,0.1c-0.7,0.2-1.5,0.4-2.2,0.6c0,0-0.1,0-0.1,0c-0.7,0.2-1.5,0.4-2.2,0.5
					c-0.1,0-0.2,0-0.2,0.1c-0.1,0-0.2,0-0.4,0.1c-1,0.2-2.1,0.4-3.1,0.6c-0.4,0.1-0.8,0.2-1.3,0.2c-0.1,0-0.2,0-0.3,0
					c-1,0.2-1.9,0.3-2.9,0.4c-0.2,0-0.4,0.1-0.7,0.1c-1,0.1-1.9,0.2-2.9,0.4c0,0-0.1,0-0.1,0c-0.7,0.1-1.4,0.2-2.1,0.2
					c-0.3,0-0.7,0.1-1,0.1c-1.1,0.1-2.1,0.2-3.2,0.4c-0.1,0-0.3,0-0.4,0c-1,0.1-2,0.3-2.9,0.4c-0.1,0-0.3,0-0.4,0.1
					c-1,0.2-1.9,0.3-2.9,0.5c-0.2,0-0.4,0.1-0.6,0.1c-0.8,0.2-1.6,0.4-2.3,0.6c0,0,0,0,0,0c-0.8,0.2-1.5,0.5-2.2,0.8
					c-0.2,0.1-0.4,0.2-0.6,0.2c-0.4,0.2-0.9,0.4-1.3,0.6c-0.2,0.1-0.4,0.2-0.6,0.3c-0.6,0.3-1.1,0.6-1.7,1c-3.3,2.3-4.9,5-5,8.1
					L1294.9,1073.5z"/>
				<path fill="#004E89" d="M1027.2,1138.1c0-0.1-0.1-0.3-0.1-0.4c0-0.1-0.1-0.2-0.1-0.4c0,0,0-0.1,0-0.1c0-0.2,0-0.4-0.1-0.5
					c0-0.1,0-0.3,0-0.4c0-0.2,0-0.4,0-0.6c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1l0.6-68.6c0,0.2,0,0.3,0,0.5c0,0.2,0,0.4,0,0.6
					c0,0.1,0,0.3,0,0.4c0,0.2,0,0.4,0.1,0.5c0,0.2,0.1,0.3,0.1,0.5c0,0.1,0.1,0.3,0.1,0.4c0,0.2,0.1,0.3,0.1,0.5
					c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0,0.1,0.1,0.2c0.2,0.5,0.4,0.9,0.6,1.4c0,0,0,0,0,0c0.2,0.4,0.5,0.8,0.8,1.3
					c0,0.1,0.1,0.1,0.1,0.2c0.3,0.4,0.6,0.8,0.9,1.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.3,0.2,0.4,0.4
					c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.2,0.2,0.4,0.3c0.2,0.2,0.4,0.3,0.7,0.5c0.1,0.1,0.2,0.2,0.4,0.3c0.3,0.2,0.5,0.3,0.8,0.5
					c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.1,0.5,0.3,0.8,0.4c0.1,0.1,0.2,0.1,0.3,0.2c0.4,0.2,0.8,0.4,1.2,0.6c0.8,0.3,1.6,0.6,2.4,0.9
					c0.3,0.1,0.6,0.2,0.8,0.2c0.6,0.2,1.1,0.3,1.7,0.5c0.2,0,0.3,0.1,0.5,0.1c0.7,0.2,1.3,0.3,2,0.4c0.3,0,0.5,0.1,0.8,0.1
					c0.6,0.1,1.3,0.2,1.9,0.3c0.2,0,0.4,0.1,0.6,0.1c0.8,0.1,1.7,0.2,2.5,0.3c0.5,0,1.1,0.1,1.6,0.1c0.5,0,0.9,0.1,1.4,0.1
					c0.6,0,1.1,0,1.7,0.1c0.4,0,0.9,0,1.3,0c1.1,0,2.1,0,3.2,0.1c0,0,0.1,0,0.1,0c1.3,0,2.5,0,3.8,0c0.8,0,1.6,0,2.4,0
					c0.4,0,0.8,0,1.2,0c0.7,0,1.4,0,2.1,0c0.2,0,0.4,0,0.5,0c0.3,0,0.6,0,0.9,0c0.5,0,1,0,1.6,0.1c0.5,0,1,0.1,1.5,0.1
					c0.5,0,1,0.1,1.5,0.1c0.2,0,0.3,0,0.5,0v0l0,0v0c0.6,0,1.1,0.1,1.7,0.2c0.1,0,0.2,0,0.3,0c0.7,0.1,1.4,0.2,2.1,0.3
					c0.1,0,0.2,0,0.3,0c1,0.2,2.1,0.3,3.1,0.6c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0.1
					c0.1,0,0.1,0,0.2,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.2,0.1c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.1,0,0.2,0.1
					c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.2,0,0.3,0.1,0.5,0.2c0.1,0,0.2,0.1,0.3,0.1c0.2,0.1,0.3,0.1,0.5,0.2
					c0.1,0,0.2,0.1,0.3,0.1c0.2,0.1,0.4,0.1,0.5,0.2c0.1,0,0.2,0.1,0.3,0.1c0.2,0.1,0.3,0.1,0.5,0.2c0.1,0,0.3,0.1,0.4,0.2
					c0.2,0.1,0.3,0.1,0.5,0.2c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0.1,0.3,0.1,0.5,0.2c0.1,0.1,0.3,0.1,0.5,0.2c0.2,0.1,0.3,0.1,0.5,0.2
					c0.2,0.1,0.3,0.1,0.5,0.2c0.2,0.1,0.3,0.1,0.5,0.2c0.2,0.1,0.3,0.1,0.5,0.2c0.2,0.1,0.3,0.1,0.5,0.2c0.1,0.1,0.3,0.1,0.4,0.2
					c0.2,0.1,0.4,0.2,0.5,0.2c0.1,0.1,0.3,0.1,0.4,0.2c0.3,0.1,0.6,0.3,0.9,0.4c0.4,0.2,0.8,0.4,1.2,0.6l-0.6,68.5
					c-0.4-0.2-0.8-0.4-1.2-0.6c-0.3-0.1-0.6-0.3-0.9-0.4c-0.1,0-0.2-0.1-0.3-0.1c-0.2-0.1-0.4-0.2-0.6-0.3c-0.1,0-0.2-0.1-0.4-0.1
					c-0.2-0.1-0.4-0.2-0.6-0.2c-0.1-0.1-0.3-0.1-0.4-0.2c-0.2-0.1-0.4-0.1-0.6-0.2c-0.1-0.1-0.3-0.1-0.4-0.2
					c-0.2-0.1-0.4-0.1-0.5-0.2c-0.2-0.1-0.3-0.1-0.4-0.2c-0.2-0.1-0.3-0.1-0.5-0.2c-0.1-0.1-0.3-0.1-0.4-0.2
					c-0.2-0.1-0.3-0.1-0.5-0.2c-0.1,0-0.3-0.1-0.4-0.1c-0.2-0.1-0.3-0.1-0.5-0.2c-0.2-0.1-0.3-0.1-0.4-0.2c-0.1,0-0.3-0.1-0.4-0.1
					c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.1
					c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2-0.1-0.2-0.1
					c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c-1-0.2-2-0.4-3.1-0.6
					c-0.8-0.1-1.6-0.2-2.4-0.3c-0.1,0-0.3,0-0.4,0c-0.7-0.1-1.4-0.1-2.1-0.2c-0.5,0-1-0.1-1.5-0.1c-0.5,0-1-0.1-1.5-0.1
					c-0.5,0-1,0-1.5-0.1c-0.5,0-1,0-1.5,0c-0.7,0-1.3,0-2,0c-0.4,0-0.8,0-1.3,0c-0.8,0-1.6,0-2.4,0c-1,0-2.1,0-3.1,0
					c-0.3,0-0.5,0-0.8,0c-1.1,0-2.1,0-3.2-0.1c-0.1,0-0.1,0-0.2,0v0l0,0v0c-0.4,0-0.9,0-1.3,0c-0.5,0-1.1,0-1.6-0.1
					c-0.5,0-0.9-0.1-1.4-0.1c-0.5,0-1-0.1-1.6-0.1c-0.1,0-0.1,0-0.2,0c-0.8-0.1-1.6-0.1-2.4-0.2c-0.2,0-0.4,0-0.6-0.1
					c-0.4-0.1-0.8-0.1-1.2-0.2v0l0,0v0c-0.3,0-0.5-0.1-0.8-0.1c-0.3,0-0.5-0.1-0.8-0.1c-0.7-0.1-1.3-0.3-2-0.4
					c-0.2,0-0.3-0.1-0.5-0.1c0,0-0.1,0-0.1,0c-0.5-0.1-1.1-0.3-1.6-0.4c-0.3-0.1-0.6-0.1-0.8-0.2c-0.8-0.3-1.6-0.6-2.4-0.9
					c-0.4-0.2-0.8-0.4-1.2-0.6c-0.1-0.1-0.3-0.1-0.4-0.2c-0.2-0.1-0.5-0.3-0.7-0.4c0,0-0.1,0-0.1,0c-0.1-0.1-0.2-0.1-0.3-0.2
					c-0.3-0.2-0.5-0.3-0.8-0.5c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2-0.2-0.4-0.3-0.6-0.5c-0.1-0.1-0.1-0.1-0.2-0.2v0l0,0v0
					c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.3-0.4-0.4c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.1-0.1-0.2-0.2
					c0,0-0.1-0.1-0.1-0.1c-0.3-0.4-0.7-0.8-0.9-1.2c0,0,0,0-0.1-0.1c0,0,0-0.1-0.1-0.1c-0.3-0.4-0.5-0.8-0.8-1.3c0,0,0,0,0,0
					c0,0,0,0,0,0c-0.2-0.4-0.4-0.9-0.6-1.4c0,0,0-0.1,0-0.1v0l0,0v0c0,0,0-0.1,0-0.1c0-0.1-0.1-0.2-0.1-0.4
					C1027.3,1138.4,1027.2,1138.2,1027.2,1138.1z"/>
				<g>
					<polygon fill="#0061A2" points="1001.3,1152.9 1000.7,1221.4 1295.5,1345.5 1296.1,1276.9 					"/>
				</g>
				<g>
					<polygon fill="#004E89" points="1296.1,1276.9 1295.5,1345.5 1525.4,1190.3 1526,1121.7 					"/>
				</g>
				<g>
					<path fill="#1C9DD8" d="M1526,1121.7l-121.9-51.3c-4.1,2.4-9,6.6-9.5,7.2c-5.7,7.5,1.7,17.7-7.9,24.2
						c-11.8,7.9-40.7,6.2-64.7-3.9c-0.7-0.3-1.3-0.6-2-0.9c-22.5-10-31.5-24.1-20.1-31.8c7.3-4.9,19.9-4.6,30.2-6.5
						c5.3-1,10.8-2.4,15.1-4.4c0.9-0.4,5.5-3.1,8.2-5.4l-122.1-51.4L1136,1062c-2-0.7-4.1-1.5-5.9-2.3c-2.2-1-3.8-1.8-4.3-2.1
						c-3.7-2.3-6.5-5.2-8.4-8.1c-3.6-5.4-3.3-12-12.2-15.9c-15.5-6.5-42.9-1.5-61.6,11.1c-18.5,12.5-21.5,27.6-7,34.1
						c12.8,5.4,31.9,1.6,46.1,4.7c1.1,0.2,7.8,2.5,12.5,4.6c0.4,0.2,0.8,0.4,1.2,0.6l-95.1,64.2l294.8,124L1526,1121.7z"/>
				</g>
			</g>
			<g>
				<ellipse display="none" fill="#9EABD3" cx="1528" cy="1836.9" rx="168.4" ry="74.9"/>
				<g>
					<g>
						<g>
							<path fill="#E59375" d="M1442.6,1834.6c0,0-17.9-3.3-12.8,11.3c0,0,41.7,17.9,70.8,13.9c0,0,20.5-0.7,17.3-22.9l0.3-18
								l-27.5,5.2l1.6,17.1L1442.6,1834.6z"/>
						</g>
						<path fill="#4F1531" d="M1517.9,1836.9c0,2.9-0.9,5.6-2.7,7.7c-7.2,8.3-21.3,8.5-31.3,7.9c-15.7-1-32.1-3.4-47.6-6.7
							c-3.4-0.7-4.3-1.8-4.4-2.8c1.8-4,5-7.4,10.7-8.4l-15.2-1.9c-14-0.5-20.4,2.7-23.7,4.8c-3.9,11.1,15,18.5,15,18.5
							s20.1,7.6,44.3,12.4c0,0,53.4,12.6,59.8-3.8L1517.9,1836.9z"/>
					</g>
					<g>
						<g>
							<path fill="#E59375" d="M1548.6,1809.8c0,0-17.9-3.3-12.8,11.3c0,0,41.7,17.9,70.8,13.9c0,0,20.5-0.7,17.3-22.9l0.3-18
								l-27.5,5.2l1.6,17.2L1548.6,1809.8z"/>
						</g>
						<path fill="#4F1531" d="M1623.9,1812.1c0,2.9-0.9,5.6-2.7,7.7c-7.2,8.3-21.3,8.5-31.3,7.9c-15.7-1-32.1-3.4-47.6-6.7
							c-3.4-0.7-4.3-1.8-4.4-2.8c1.8-4,5-7.4,10.7-8.4l-15.2-1.9c-14-0.5-20.4,2.7-23.7,4.8c-3.9,11.1,15,18.5,15,18.5
							s20.1,7.6,44.3,12.4c0,0,53.4,12.6,59.8-3.8L1623.9,1812.1z"/>
					</g>
					<g>
						<g>
							<path fill="#00468B" d="M1543.7,1327.9c0,28.1,22.8,51,51,51c28.1,0,51-22.8,51-51c0-28.1-22.8-51-51-51
								C1566.5,1276.9,1543.7,1299.7,1543.7,1327.9z"/>
							<path fill="#00468B" d="M1626.7,1573.2c0,0-0.7,12.8-23.7,13.1c0,0-21.8,7.4-31.2-20.9c-37.1-111.7-27.3-228.3-27.3-228.3
								s52.3-29.1,101.1-9.2C1645.6,1327.9,1637.7,1474.5,1626.7,1573.2z"/>
						</g>
						<path fill="#00468B" d="M1571.8,1565.4c0,0,18,241.5,19.6,250.1c17.5,11.1,33-0.3,33-0.3c-0.1-11.8,12.8-110.6,14.2-129.1
							c2.3-31.9,6.1-72.1-14-117.9L1571.8,1565.4z"/>
						<g>
							<path fill="#0058A9" d="M1479,1580.6c0,0-4.9,11.8-26.7,4.5c0,0-23-0.2-22.5-30.1c2-117.7,53.6-239.7,53.6-239.7
								s58.1-5.5,97.5,29.4C1580.9,1344.7,1522.1,1491.1,1479,1580.6z"/>
							
								<ellipse transform="matrix(0.3313 -0.9435 0.9435 0.3313 -227.8494 2334.2549)" fill="#0058A9" cx="1532.8" cy="1327.9" rx="51" ry="51"/>
						</g>
						<path fill="#0058A9" d="M1645.6,1320.4c-6.2-56.7-34.6-83-34.6-83l-98.5-12.4c0,0-27.2,47.3-30,95.4
							c11.8,32.1,53.7,41.5,53.7,41.5l31.9,9.5C1568.1,1371.4,1640.4,1360.4,1645.6,1320.4z"/>
						<g>
							<path fill="#0058A9" d="M1434.4,1578.4c0,0,48.9,253.6,51.8,261.9c-1.3-0.6,23.1,11.2,33.9-4.1
								c-1.8-11.7-2.9-140.5-4.3-159.1c-2.4-31.9-2.1-61.6-28.8-103.9C1486.9,1573.3,1420.2,1525.6,1434.4,1578.4z"/>
						</g>
					</g>
					<path fill="#340E08" d="M1520.7,1759.5"/>
					<g>
						<g>
							<path fill="#E59375" d="M1652.9,1134.3c0,0-17.9,21.1-53.6,72.2l6.2,10.5c0,0,61.1-30.8,80.6-65.4L1652.9,1134.3z"/>
						</g>
						<g>
							<path fill="#E59375" d="M1602.4,1058.7c0,0,46,100.4,73.7,96.6c0,0,9.5,0.6,11.4-6.6c0,0,19.5-17.3-61.6-142.2
								c0,0-7.9-10.6-24.2-7.9C1601.7,998.7,1577,1015,1602.4,1058.7z"/>
						</g>
					</g>
					<g>
						<rect x="1528" y="980.1" fill="#F4B78E" width="49.7" height="52.9"/>
					</g>
					<g>
						<path fill="#F7A483" d="M1497.2,981.3l-1.9-8.5c0,0-3.7-31-3.7-31c-0.5-3.9,0.4-8.9,1.8-12.6c1.6-4,2.9-8.3,2.5-12.9
							c-1.9-22.1,12.4-32.4,12.4-32.4c17-18,49.7-13.7,49.7-13.7c16.9,3.2,31.4,10.4,39,24.9c6.9,13.2,10.1,29,4.7,43
							c-6,15.4-16.4,27.1-29.9,37.6c-8.6,6.7-17.1,7.1-28,10.1c-7.6,2.1-22,2.8-29.9,2.4C1509.7,988.2,1498.3,986.2,1497.2,981.3z"
							/>
					</g>
					<g>
						<path fill="#340E08" d="M1519.8,952.7"/>
					</g>
					<g>
						<path fill="#340E08" d="M1519.8,952.7c2.5-3.5,11.4-5.6,11.4-5.6c10.8,0,9.4,14.4,10.4,20.7c0.3,2,0.7,4.1,1.2,6.2
							c5.1,18.3,20.5,18.2,33.7,20.4c0.5-0.3,1-0.6,1.5-0.9c2.4-8.1,10-16.6,10-16.6c19.8-17.1,26.2-47.8,18.7-72
							c-2.3-7.3-7.1-15.1-12.3-20.6c-11.1-11.8-25.5-18.2-40.9-21.1c-15.3-2.9-70.1,4.5-55.1,37.8
							C1498.4,900.9,1521,926.9,1519.8,952.7"/>
					</g>
					<g>
						<g>
							<g>
								<path fill="#F8B78F" d="M1519.8,952.7c1.9-4.9,6.8-8.7,12.2-7.1c4,1.2,6.3,4.7,6,8.8c-0.1,0.9-0.3,1.8-0.4,2.7
									c-1.6,9.5-11.6,13.1-11.6,13.1"/>
							</g>
							<g>
								<path fill="#DD9D7E" d="M1531,950c-0.5-0.4-1.7-0.6-2.4-0.7c-3.6-0.2-6.5,5.4-2.9,6.4c1.4,1.3,0,2.9,0,2.9
									c-1.2,1.4-2.9,3.3-2.5,5.2c0.2,1.1,1.5,1.7,2.6,2c0.9,0.2,1.1-0.5,0.4-0.8c-0.6-0.3-1-0.7-1.4-1.1c0-0.1-0.1-0.1-0.1-0.2
									c0,0-0.1-0.1-0.1-0.1c-0.1-0.3-0.1-0.5,0-0.8c0.8,0.6,2.2,0.7,2.8,0c0.7-0.8,0.2-2.1,0.4-3.1c0.2-0.7,0.5-1.3,0.8-1.9
									c1.7-2.8-1.3-3.2-1.3-3.2c-0.5,0-0.8,0-1.1,0.1c-1.5-0.8,0.2-3.1,1.1-3.4c1.1-0.4,2.4-0.3,3.2,0.6c0.8,0.8,1.1,2.1,1,3.2
									c-0.1,1.3-0.4,2.6-1,3.8c-0.3,0.7-0.7,1.5-1.1,2.2c-0.4,0.8,0.5,1.1,0.9,0.3c0.8-1.6,2.3-4,2.6-6.3
									C1533.1,953,1532.7,951.2,1531,950z"/>
							</g>
							<g>
								<path fill="#DD9D7E" d="M1519.8,952.7c0,0,0.4-0.6,1.1-1.7c0.3-0.6,0.9-1.1,1.5-1.7c0.5-0.6,1.5-1.3,2.3-1.8
									c0.9-0.5,2-1,3.1-1.1c1.1-0.1,2.4-0.1,3.5,0.2c0.4,0.1,1.3,0.5,1.8,0.8c0.5,0.3,1,0.6,1.4,1.1c0.9,0.9,1.6,2,1.9,3.2
									c0.3,1.3,0.3,2.5,0.1,4l-0.1,1.1l0,0.1v0c0-0.1,0,0.2,0-0.1l0,0l0,0.1l-0.1,0.3l-0.1,0.5c-0.1,0.8-0.3,1.3-0.5,1.9
									c-0.4,1.2-1,2.4-1.7,3.4c-0.7,1-1.4,1.9-2.2,2.7c-1.5,1.6-3,2.7-4.1,3.4c-1.1,0.7-1.8,1.1-1.8,1.1s0.7-0.2,2-0.7
									c1.2-0.5,3-1.3,4.8-2.8c0.9-0.7,1.9-1.6,2.7-2.7c0.9-1,1.7-2.3,2.2-3.7c0.3-0.7,0.6-1.5,0.7-2.2l0.1-0.5l0.1-0.3l0-0.1
									l0-0.2l0-0.1l0.2-1.1c0.3-1.4,0.4-3.2,0-4.9c-0.4-1.7-1.4-3.2-2.7-4.3c-0.3-0.3-0.6-0.6-1-0.8l-1.1-0.6
									c-0.8-0.4-1.2-0.5-2.2-0.8c-1.5-0.4-3.1-0.3-4.5,0.1c-1.4,0.3-2.5,1.1-3.5,1.8c-1,0.8-1.6,1.5-2.2,2.4
									c-0.5,0.8-1,1.5-1.2,2.1C1520.1,951.9,1519.8,952.7,1519.8,952.7z"/>
							</g>
						</g>
						<g>
							<g>
								<path fill="#FFFFFF" d="M1526.1,967.7c0,1.7-1.3,3-3,3c-1.7,0-3-1.4-3-3c0-1.7,1.4-3,3-3
									C1524.7,964.7,1526.1,966.1,1526.1,967.7z"/>
							</g>
							<g>
								<path fill="#E6E7E8" d="M1523.8,969.4c-1.3,0-2.4-1.1-2.4-2.4c0-1.1,0.7-1.9,1.7-2.2l0,0c-1.7,0-3,1.4-3,3c0,1.7,1.4,3,3,3
									c1.7,0,3-1.4,3-3c0-0.1,0-0.1,0-0.2C1525.8,968.6,1524.9,969.4,1523.8,969.4z"/>
							</g>
						</g>
					</g>
					<g>
						<path fill="#384E54" d="M1657.6,1261.1c-0.2-0.3-54.9-78.8-54.9-78.8c-2.6-11.6,3.2-34.6,3.2-34.6l17.6-120.7
							c-2.1-13.8-3-31.4-21.8-28.3c-13.2,2.2-15.8,4.1-24,6.7c2.4,11.2-17,18.4-17,18.4c-26.6,11.5-32.8,3.9-32.8,3.9
							c-7.1,3.8-31,15.8-37.3,20.9c-6.5,5.3-10.4,13.6-8.3,27.5l19.2,114.4l-3.4,28.4l-35,88.7c10.8-3.9,22.7-5.5,34.1-4.1
							c10.9,1.3,20.3,7.7,31.1,8.9c16.3,1.8,28.5-4.1,40.8-11.9c15-3.2,31-8.4,47.2-16.6c0.3,0,0.7,0,1,0
							c12.2,0.1,26.1-0.1,35.1-9.7C1654.3,1272.4,1659.9,1264.4,1657.6,1261.1z"/>
					</g>
					<g>
						<path fill="#F7A483" d="M1468.1,1231.8l0.3-139c3.4-38.1,22.3-44.2,22.3-44.2c38.2-4.5,32.3,66.4,32.3,66.4l-16,119.8"/>
					</g>
					<g>
						<path fill="#F7A483" d="M1346.3,1307.8c17.5-9.4,19.1-12.4,22.2-16.8c13.3-18.9-13.6-18.3-20.4-16.8
							c-3.9,0.9-4.1,10.2-16.2,21.5c0,0-19,11-23.7,10.2c0,0-4.9,0.7-4.9,4.2C1303.4,1310.1,1316.7,1318,1346.3,1307.8z"/>
					</g>
					<g>
						<g>
							<path fill="#F8B78F" d="M1493.1,1212.7"/>
						</g>
						<g>
							<path fill="#F7A483" d="M1470.9,1217.2l-119.1,65.2c-5.1,14.3,1.5,16.4,1.5,16.4l92.2-26.5c39.4-10.5,55-27.1,55-27.1
								s17.2-11.9-4.8-29.7C1495.7,1215.5,1485.7,1207.6,1470.9,1217.2z"/>
						</g>
					</g>
					<g>
						<path fill="#340E08" d="M1509.3,944.1c3.1,23.1-0.4,53.6,11.1,78.8c5.1,11.2,10.8,16.8,7.7,29.8c-2.5,10.7-7.5,20.7-10.3,31.7
							c-6,24.3,2.2,48.1,30.5,39.3c13.6-4.2,15.1-4.2,27.5,0.6c10.4,4,20.2,5.2,30.5,0.1c16-7.8,11.2-19.6,20.2-31.4
							c6.8-9,16.5-8.8,21.7-20.9c6.2-14.6-1.2-22.4-8.8-34.8c-7.9-12.9-9.2-21.4-6.6-37c2.2-13.1-1.1-19.7-5.4-31.7
							c-4.4-12.3-2.5-23.1-4.6-35.4c-4.2-24.4-17.5-57.6-43.1-64.8c-12.6-3.5-28.8-6.3-40.8-1.8c-16.4,6-34.8,12.5-34.1,32.7
							C1505.6,920.1,1506.5,923.6,1509.3,944.1z"/>
					</g>
					<path fill="#F7A483" d="M1351.3,1273.7c0,0,3.3,9.8-8.8,7.4l-11.9-9.1c0,0-3.7-2-2.4-5.5c0,0,1.4-2,5.2,0L1351.3,1273.7z"/>
				</g>
			</g>
		</g>
		<g>
			<circle fill="#39C1D2" cx="298.3" cy="1911.8" r="41.1" class="animate-flicker"/>
			<path fill="#1C9DD8" d="M298.3,496.8c0,16.3-13.2,29.4-29.4,29.4c-16.3,0-29.4-13.2-29.4-29.4c0-16.2,13.2-29.4,29.4-29.4
				C285.1,467.4,298.3,480.6,298.3,496.8z"/>
			<path fill="#1C9DD8" d="M1993.7,717.7c0,16.2-13.2,29.4-29.4,29.4c-16.3,0-29.4-13.2-29.4-29.4c0-16.3,13.2-29.4,29.4-29.4
				C1980.6,688.3,1993.7,701.5,1993.7,717.7z"/>
			<circle fill="#39C1D2" cx="1150.4" cy="433.9" r="29.4"/>
			<path fill="#39C1D2" d="M2017.3,1308.8c0,16.2-13.2,29.4-29.4,29.4c-16.2,0-29.4-13.2-29.4-29.4c0-16.3,13.2-29.4,29.4-29.4
				C2004.1,1279.3,2017.3,1292.5,2017.3,1308.8z"/>
			<circle fill="#1C9DD8" cx="929.6" cy="126.2" r="29.4" class="animate-flicker"/>
		</g>
		<g>
			<ellipse fill="none" stroke="#699FD5" stroke-width="12.4726" stroke-miterlimit="10" cx="1502.1" cy="512.2" rx="16" ry="16"/>
		</g>
	</g>
</g>
<g id="disc1" class="animate-flicker">
	<ellipse fill="none" stroke="#699FD5" stroke-width="12.4726" stroke-miterlimit="10" cx="448.6" cy="437.4" rx="16" ry="16"/>
</g>
<g id="cricle1" class="animate-flicker">
	<circle fill="#1C9DD8" cx="1851.9" cy="1765.2" r="29.4"/>
</g>
<g id="circle2" class="animate-flicker">
	<circle fill="#39C1D2" cx="145" cy="1115.2" r="29.4"/>
</g>
<g id="cricle3" class="animate-flicker">
	<circle fill="#39C1D2" cx="1580.5" cy="183.9" r="41.1"/>
</g>
<g id="line1"  class="moveright-left">
	<g>
		
			<line fill="none" stroke="#699FD5" stroke-width="12.4726" stroke-miterlimit="10" x1="1769.7" y1="295.8" x2="1808.4" y2="295.8"/>
		
			<line fill="none" stroke="#699FD5" stroke-width="12.4726" stroke-miterlimit="10" x1="1818.4" y1="295.8" x2="1851.9" y2="295.8"/>
		
			<line fill="none" stroke="#699FD5" stroke-width="12.4726" stroke-miterlimit="10" x1="1724.8" y1="295.8" x2="1758.2" y2="295.8"/>
	</g>
</g>
<g id="line2"  class="moveleft-right">
	<g>
		
			<line fill="none" stroke="#699FD5" stroke-width="12.4726" stroke-miterlimit="10" x1="68.9" y1="1344.8" x2="161.2" y2="1344.8"/>
		
			<line fill="none" stroke="#699FD5" stroke-width="12.4726" stroke-miterlimit="10" x1="174.9" y1="1344.8" x2="221.1" y2="1344.8"/>
	</g>
</g>
<g id="line3"  class="moveright-left">
	<g>
		<line fill="none" stroke="#699FD5" stroke-width="12.4726" stroke-miterlimit="10" x1="990.3" y1="1892" x2="898.1" y2="1892"/>
		<line fill="none" stroke="#699FD5" stroke-width="12.4726" stroke-miterlimit="10" x1="884.3" y1="1892" x2="838.2" y2="1892"/>
	</g>
</g>
<g id="line4"  class="moveleft-right">
	<g>
		
			<line fill="none" stroke="#699FD5" stroke-width="13.462" stroke-miterlimit="10" x1="1856.8" y1="1541.7" x2="1964.3" y2="1541.7"/>
		
			<line fill="none" stroke="#699FD5" stroke-width="13.462" stroke-miterlimit="10" x1="1980.3" y1="1541.7" x2="2034.1" y2="1541.7"/>
	</g>
</g>
<g id="line5" class="moveleft-right">
	
		<line fill="none" stroke="#699FD5" stroke-width="12.4726" stroke-miterlimit="10" x1="1896.3" y1="946.9" x2="2032.3" y2="946.9"/>
</g>
<g id="cross1" class="spin-anim">
	<g>
		
			<line fill="none" stroke="#699FD5" stroke-width="12.4726" stroke-miterlimit="10" x1="1079.1" y1="1491.5" x2="1091.9" y2="1504.4"/>
		
			<line fill="none" stroke="#699FD5" stroke-width="12.4726" stroke-miterlimit="10" x1="1101" y1="1513.5" x2="1113.9" y2="1526.3"/>
		
			<line fill="none" stroke="#699FD5" stroke-width="12.4726" stroke-miterlimit="10" x1="1113.9" y1="1491.5" x2="1101" y2="1504.4"/>
		
			<line fill="none" stroke="#699FD5" stroke-width="12.4726" stroke-miterlimit="10" x1="1091.9" y1="1513.5" x2="1079.1" y2="1526.3"/>
	</g>
</g>
<g id="cross2" class="spin-anim">
	<g>
		<line fill="none" stroke="#699FD5" stroke-width="12.4726" stroke-miterlimit="10" x1="110.2" y1="689.4" x2="123" y2="702.2"/>
		<line fill="none" stroke="#699FD5" stroke-width="12.4726" stroke-miterlimit="10" x1="132.1" y1="711.3" x2="145" y2="724.2"/>
		<line fill="none" stroke="#699FD5" stroke-width="12.4726" stroke-miterlimit="10" x1="145" y1="689.4" x2="132.1" y2="702.2"/>
		<line fill="none" stroke="#699FD5" stroke-width="12.4726" stroke-miterlimit="10" x1="123" y1="711.3" x2="110.2" y2="724.2"/>
	</g>
</g>
<g id="cross3" class="spin-anim">
	<g>
		
			<line fill="none" stroke="#699FD5" stroke-width="12.4726" stroke-miterlimit="10" x1="1190.5" y1="256.4" x2="1203.4" y2="269.2"/>
		
			<line fill="none" stroke="#699FD5" stroke-width="12.4726" stroke-miterlimit="10" x1="1212.5" y1="278.3" x2="1225.3" y2="291.2"/>
		
			<line fill="none" stroke="#699FD5" stroke-width="12.4726" stroke-miterlimit="10" x1="1225.3" y1="256.4" x2="1212.5" y2="269.2"/>
		
			<line fill="none" stroke="#699FD5" stroke-width="12.4726" stroke-miterlimit="10" x1="1203.4" y1="278.3" x2="1190.5" y2="291.2"/>
	</g>
</g>
</svg>

        </div>
        <div class="col-sm-6 col-md-6 col-12">
          <div class="section-heading" style="text-align: left; z-index: 20;" data-aos="fade-left">
            SUCCEEDING TOGETHER
          </div>
          <p class="section-p" style="text-align: left; width: 100%; z-index: 20;" data-aos="fade-left">
            We are a digital technology firm that makes use of new, creative and frequently changing digital technology to transform your business, which leads to more flexible production, greater productivity and a seamless digital product experience for the end user.
<br/><br/>Thanks to the technology available today, we are able to unleash the true potential of your business by making use of creative strategy, design and technology innovations, which will create new opportunities to fulfill your customers individual requirements.

          </p>
         

        </div>
	  </div>
	</div>
</div>

    </div>
  </div>
  <div class="expertise-section">
  
    <div class="container">
      <div class="section-heading" data-aos="fade-up">OUR Clients </div>
      <p class="section-p" data-aos="fade-up">We help bussiness define big visions, turn ideas into realities and deliver delightful
        digital experiences
      </p>
      <div class="row">
        <div class="col-md-10 offset-md-1">
          <div class="row">
       
            <div class="col-md-3 col-xs-6 col-sm-6 col-6 brnd-content" data-aos="fade-up">
              <div class="text-center">
                <img src="./assets/img/clients/customs.png" class="img-fluid">
                
              </div>
			</div>
			<div class="col-md-3 col-xs-6 col-sm-6 col-6 brnd-content" data-aos="fade-up">
				<div class="text-center">
				  <img src="./assets/img/clients/anh.png" class="img-fluid">
				  
				</div>
			  </div>
            <div class="col-md-3 col-xs-6 col-sm-6 col-6 brnd-content" data-aos="fade-up" data-aos-delay="300">
              <div class="text-center">
                <img src="./assets/img/clients/navisha.png" class="img-fluid">
              </div>
			</div>
			
			<div class="col-md-3 col-xs-6 col-sm-6 col-6 brnd-content" data-aos="fade-up" data-aos-delay="300">
				<div class="text-center">
				  <img src="./assets/img/clients/intothelens.png" class="img-fluid">
				</div>
			  </div>

            <div class="col-md-3 col-xs-6 col-sm-6 col-6 brnd-content" data-aos="fade-up" data-aos-delay="600">
              <div class="text-center">
                <img src="./assets/img/clients/ceyone.png" class="img-fluid">
              </div>
            </div>
            <div class="col-md-3 col-xs-6 col-sm-6 col-6 brnd-content" data-aos="fade-up" data-aos-delay="900">
              <div class="text-center">
                <img src="./assets/img/clients/dolphin.png" class="img-fluid" >
              </div>
            </div>
            <div class="col-md-3 col-xs-6 col-sm-6 col-6 brnd-content" data-aos="fade-up">
              <div class="text-center">
                <img src="./assets/img/clients/foru.png" class="img-fluid">
              </div>
            </div>
            <div class="col-md-3 col-xs-6 col-sm-6 col-6 brnd-content" data-aos="fade-up" data-aos-delay="300">
              <div class="text-center">
                <img src="./assets/img/clients/krishna.png" class="img-fluid">
              </div>
            </div>
            <div class="col-md-3 col-xs-6 col-sm-6 col-6 brnd-content" data-aos="fade-up" data-aos-delay="600">
              <div class="text-center">
                <img src="./assets/img/clients/anits.png" class="img-fluid">
              </div>
            </div>
            <div class="col-md-3 col-xs-6 col-sm-6 col-6 brnd-content" data-aos="fade-up" data-aos-delay="900">
              <div class="text-center">
                <img src="./assets/img/clients/styrka.png" class="img-fluid">
              </div>
            </div>
           
			
			  <div class="col-md-3 col-xs-6 col-sm-6 col-6 brnd-content" data-aos="fade-up" data-aos-delay="600">
				<div class="text-center">
				  <img src="./assets/img/clients/realasti.png" class="img-fluid">
				</div>
			  </div>

            <div class="col-md-3 col-xs-6 col-sm-6 col-6 brnd-content" data-aos="fade-up" data-aos-delay="300">
              <div class="text-center">
                <img src="./assets/img/clients/ds.png" class="img-fluid">
              </div>
            </div>
            <div class="col-md-3 col-xs-6 col-sm-6 col-6 brnd-content" data-aos="fade-up" data-aos-delay="600">
              <div class="text-center">
                <img src="./assets/img/clients/srikanya.png" class="img-fluid">
              </div>
			</div>
				  
            <div class="col-md-3 col-xs-6 col-sm-6 col-6 brnd-content" data-aos="fade-up" data-aos-delay="900">
				<div class="text-center">
				  <img src="./assets/img/clients/nextgen.png" class="img-fluid">
				</div>
			  </div>
            <div class="col-md-3 col-xs-6 col-sm-6 col-6 brnd-content" data-aos="fade-up" data-aos-delay="900">
              <div class="text-center">
                <img src="./assets/img/clients/harbor.png" class="img-fluid">
              </div>
            </div>
            <div class="col-md-3 col-xs-6 col-sm-6 col-6 brnd-content" data-aos="fade-up" >
              <div class="text-center">
                <img src="./assets/img/clients/pravasin.png" class="img-fluid">
              </div>
			</div>
			

            <div class="col-md-3 col-xs-6 col-sm-6 col-6 brnd-content" data-aos="fade-up" data-aos-delay="300">
              <div class="text-center">
                <img src="./assets/img/clients/nimaya.png" class="img-fluid">
              </div>
            </div>
            <div class="col-md-3 col-xs-6 col-sm-6 col-6 brnd-content" data-aos="fade-up" data-aos-delay="600">
              <div class="text-center">
                <img src="./assets/img/clients/oceanvista.png" class="img-fluid">
              </div>
            </div>

			<div class="col-md-3 col-xs-6 col-sm-6 col-6 brnd-content" data-aos="fade-up" data-aos-delay="600">
				<div class="text-center">
				  <img src="./assets/img/clients/superfleet.png" class="img-fluid">
				</div>
			  </div>

		
            <div class="col-md-3 col-xs-6 col-sm-6 col-6 brnd-content" data-aos="fade-up">
              <div class="text-center">
                <img src="./assets/img/clients/club.png" class="img-fluid">
              </div>
            </div>
           
            <div class="col-md-3 col-xs-6 col-sm-6 col-6 brnd-content" data-aos="fade-up" data-aos-delay="600">
              <div class="text-center">
                <img src="./assets/img/clients/cherrytek.png" class="img-fluid">
              </div>
			</div>
			
			  <div class="col-md-3 col-xs-6 col-sm-6 col-6 brnd-content" data-aos="fade-up">
				<div class="text-center">
				  <img src="./assets/img/clients/loveknot.png" class="img-fluid">
				</div>
			  </div>

            
          
        </div>
        </div>
      </div>
      
    </div>

  </div>

