<div class="mobileimgsection VisbleonDesktop" >
  <div class="container">
<img src="../../assets/img/appdevelopment.png"  class="img-fluid" />
</div>
</div>

<div class="p60 innerbanner">
 
  <div class="container">
    <div class="row">


      <div class="col-md-6 col-sm-7 col-12 ">
        <h1 data-aos="fade-up">Application Development</h1>

        <p data-aos="fade-up" data-aos-delay="300"><span>Application development is the process of creating a computer
            program or a set of programs to perform the different tasks that your business requires in an easier and
            more efficient way. Our process follows these steps: gathering
            requirements, designing prototypes, testing, implementation, and integration.
            <br/> <br/>
            <ul>
              <li>Web Application Development</li>
              <li>Mobile Application Development</li>
              <li>Application Maintenance</li>
            </ul>
          </span> </p>

        <br>

      </div>
      <div class="col-md-6 col-sm-5 col-12" >
        <div class="innerheroimg">
          <img src="../../assets/img/appdevelopment.png"  />
        </div>
       
      </div>


    </div>
  </div>
</div>


<div class="servicedetailsection p60">
  <div class="container">

    <div class="row">

      <div class="col-sm-6 col-md-6 col-12">
          <div class="img-icon" data-aos="fade-right" >
            <img src="../../assets/img/icons/webapp1.png" class="img-fluid" style="max-height: 250px;" />
            </div>
       
      </div>
      <div class="col-sm-6 col-md-6 col-12">
        <div class="section-subheading" data-aos="fade-left" >
          Web Application Development
        </div>
        <p class="section-subpara"  data-aos="fade-left">
          Our team of technology experts build quick and innovative powefull websites, customs web applications to suit
          the needs of your businesses.

        </p>

      </div>

    </div>


  </div>
</div>

<div class="servicedetailsection p60">
  <div class="container">

    <div class="row">


      <div class="col-sm-6 col-md-6 col-12">
        <div class="section-subheading" data-aos="fade-right" >
          Mobile Application Development
        </div>
        <p class="section-subpara"  data-aos="fade-right">
          Our team of dedicated App developers have expertise in developing both simple and complex apps for iPhone &
          Android in a quick and cost-effective way.


        </p>

      </div>

      <div class="col-sm-6 col-md-6 col-12" >
        <div class="img-icon" data-aos="fade-left">
            <img src="../../assets/img/icons/mobileapp.png" class="img-fluid" style="max-height: 250px;" />
            </div>
      </div>

    </div>


  </div>
</div>


<div class="servicedetailsection p60">
  <div class="container">

    <div class="row">

      <div class="col-sm-6 col-md-6 col-12" >
        <div class="img-icon" data-aos="fade-right">
            <img src="../../assets/img/icons/webapp.png" style="max-height: 250px;" class="hideonMobile img-fluid"/>
            </div>
      </div>
      <div class="col-sm-6 col-md-6 col-12">
        <div class="section-subheading" data-aos="fade-left" >
          Application Maintenance
        </div>
        <p class="section-subpara" data-aos="fade-left" >
          Maintenance of Web, Mobile applications is as vital as the design and development. Our team runs regular
          checkups and adjustments in order to enhance the technical functions and also to update the systems,
          applications and software for a smooth and hassle-free operation of your websites, applications

        </p>

      </div>

    </div>


  </div>
</div>
