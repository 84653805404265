import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServicesComponent } from './services/services.component';
import { ExploreComponent } from './explore/explore.component';
import { WorksComponent } from './works/works.component';
import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { MenuComponent } from './menu/menu.component';
import { CareerComponent } from './career/career.component';
import { ApplicationDevelopmentComponent } from './application-development/application-development.component';
import { UXDesignComponent } from './uxdesign/uxdesign.component';
import { DigitalMarketingComponent } from './digital-marketing/digital-marketing.component';
import { from } from 'rxjs';

@NgModule({
  declarations: [
    AppComponent,
    ServicesComponent,
    ExploreComponent,
    WorksComponent,
    ContactComponent,
    HomeComponent,
    MenuComponent,
    CareerComponent,
    ApplicationDevelopmentComponent,
    UXDesignComponent,
    DigitalMarketingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [ ],
  bootstrap: [AppComponent]
})
export class AppModule { }
