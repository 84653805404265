<div class="mobileimgsection VisbleonDesktop" >
  <div class="container">
<img src="../../assets/img/digital.png"  class="img-fluid" />
</div>
</div>

<div class="p60 innerbanner">

    <div class="container">
      <div class="row">
  
  
        <div class="col-md-6 col-sm-7 col-xs-12 ">
          <h1 data-aos="fade-up">DIGITAL MARKETING</h1>
  
          <p data-aos="fade-up" data-aos-delay="300"><span>Thanks to modern technology, every business can become a digital business. People aren’t just watching entertainment videos and posting selfies on social media these days. Many rely on social media to discover, research, and educate themselves about a brand before engaging with that organization. The more your audience wants to engage with your content, the more likely it is that they will want to share it. This ultimately leads to them becoming a customer and influencing their friends to become customers, too.
         <br/> <br/>
  
  
              <ul>
                <li>Social Media Marketing</li>
                <li>Search Engine Optimization</li>
                <li>Video Marketing</li>
              </ul>
            </span> </p>
  
          <br>
  
        </div>

        <div class="col-md-6 col-sm-5 col-12" >
          <div class="innerheroimg">
            <img src="../../assets/img/digital.png" class="hideonMobile" />
          </div>
        
         
        </div>
  
  
      </div>
    </div>
  </div>


  <div class="servicedetailsection p60">
    <div class="container">
  
      <div class="row">
  
        <div class="col-sm-6 col-md-6 col-12" >
          <div class="img-icon"  data-aos="fade-right">
            <img src="../../assets/img/icons/smm.png" class="img-fluid" style="max-height: 250px;" />
            </div>
        </div>
        <div class="col-sm-6 col-md-6 col-12">
          <div class="section-subheading" data-aos="fade-left" >
            Social Media Marketing
          </div>
          <p class="section-subpara"  data-aos="fade-left">
            Our team of social media marketing experts analyze, create and manage top-performing social media campaigns on popular platforms such as Facebook, Instagram, Twitter, etc. that helps accelerate the growth of your businesses.

          </p>
  
        </div>
       
  
      </div>
  
  
    </div>
  </div>
  
  <div class="servicedetailsection p60">
    <div class="container">
  
      <div class="row">
  
  
        <div class="col-sm-6 col-md-6 col-12">
          <div class="section-subheading" data-aos="fade-right" >
            Search Engine Optimization
          </div>
          <p class="section-subpara"  data-aos="fade-right">
            We offer a wide range of Internet Marketing and Search Engine Optimization services by identifying your potential customer types and positioning your business as the solution to your customer’s needs with a focus on helping you get more traffic on your website and increase online visibility.

          </p>
  
        </div>
  
        <div class="col-sm-6 col-md-6 col-12">
          <div class="img-icon"  data-aos="fade-left">
            <img src="../../assets/img/icons/seo.png" class="img-fluid" style="max-height: 250px;"  />
            </div>
        </div>
  
      </div>
  
  
    </div>
  </div>
  
  
  <div class="servicedetailsection p60">
    <div class="container">
  
      <div class="row">
  
        <div class="col-sm-6 col-md-6 col-12" >
          <div class="img-icon"  data-aos="fade-right">
            <img src="../../assets/img/icons/videomarketing.png" class="img-fluid" style="max-height: 250px;" class="hideonMobile"/>
            </div>
        </div>
        <div class="col-sm-6 col-md-6 col-12">
          <div class="section-subheading" data-aos="fade-left" >
            Video Marketing
          </div>
          <p class="section-subpara" data-aos="fade-left" >
            Our digital marketing team creates and uses videos to promote or market your brand, product or service to increase engagement on your digital and social media channels, educate your customers, and reach your audience with a new medium. Video content is one of the easiest to consume with over 500 million people watching videos on sites such as Facebook every day which opens up your business to a wide customer base
  
          </p>
  
        </div>

       
  
      </div>
  
  
    </div>
  </div>

    
  <div class="servicedetailsection p60">
    <div class="container">
  
      <div class="row">
  
  
        <div class="col-sm-6 col-md-6 col-12">
          <div class="section-subheading" data-aos="fade-right" >
            Email Marketing
          </div>
          <p class="section-subpara"  data-aos="fade-right">
           It is the quickest and most direct way to reach customers with critical information. It involves sending commercial messages to a group of people including potential or current customers using email. Through email marketing, you can enhance your relationship with current or previous customers, encourage customer loyalty and repeat business, acquire new customers, educate people about new and existing products and convince current customers to purchase something new.


  
  
          </p>
  
        </div>
  
        <div class="col-sm-6 col-md-6 col-12">
          <div class="img-icon"  data-aos="fade-left">
            <img src="../../assets/img/icons/email.png" class="img-fluid" style="max-height: 250px;" />
            </div>
        </div>
  
      </div>
  
  
    </div>
  </div>
  