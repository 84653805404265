<div class="p60 innerbanner">
   
    <div class="container">
      <div class="row">


        <div class="col-md-7 col-sm-7 col-xs-12 ">
          <h1 data-aos="fade-up">Our Works</h1>
          <p data-aos="fade-up" data-aos-delay="300"><span>Here is some list of works that speaks about our efforts that brings successful Collaboration by elevating brand's aesthetic.
            </span><br>
            <br>
           
            
            
        </div>
       

      </div>
    </div>
  </div>

  <div class="container" style="position: relative;" id="viewworks"> 
      <div class="row">
          <div class="col-sm-4 ">
              <div class="workbox works1" data-aos="fade-up">
                    <div class="works-content">
                        <h3>Vizag Customs</h3>
                        <h4>Web Application </h4>
                    </div>
              </div>
          </div>
          <div class="col-sm-4">
            <div class="workbox works2" data-aos="fade-up" data-aos-delay="300">
                  <div class="works-content" >
                      <h3>Ceyone Life Science </h3>
                      <h4>Website, Branding </h4>
                  </div>
            </div>
        </div>

        <div class="col-sm-4">
            <div class="workbox works13" data-aos="fade-up" data-aos-delay="600">
                  <div class="works-content" >
                    <h3>APS Interiors</h3>
                    <h4>Website </h4>
                  </div>
            </div>
        </div>
        

        <div class="col-sm-4">
            <div class="workbox works9" data-aos="fade-up">
                  <div class="works-content"  >
                      <h3>Real Asti</h3>
                      <h4>UI/UX Design </h4>
                  </div>
            </div>
        </div>


        <div class="col-sm-4">
            <div class="workbox works3" data-aos="fade-up" data-aos-delay="300">
                  <div class="works-content" >
                      <h3>Etram</h3>
                      <h4>Website, Branding </h4>
                  </div>
            </div>
        </div>

        
        <div class="col-sm-4">
            <div class="workbox works5" data-aos="fade-up" data-aos-delay="600">
                  <div class="works-content">
                      <h3>Navisha Matrimony</h3>
                      <h4>Web Application, Branding </h4>
                  </div>
            </div>
        </div>
        
        <div class="col-sm-4">
            <div class="workbox works15" data-aos="fade-up">
                  <div class="works-content"  >
                      <h3>Jobster</h3>
                      <h4>UI/UX Design </h4>
                  </div>
            </div>
        </div>
        

        <div class="col-sm-4">
            <div class="workbox works8" data-aos="fade-up" data-aos-delay="300">
                  <div class="works-content" >
                      <h3>Pravasin</h3>
                      <h4>Digital Marketing, Branding </h4>
                  </div>
            </div>
        </div>

        <div class="col-sm-4">
            <div class="workbox works10" data-aos="fade-up" data-aos-delay="600">
                  <div class="works-content" >
                    <h3>Medha  </h3>
                    <h4>Website </h4>
                  </div>
            </div>
        </div>

        <div class="col-sm-4">
            <div class="workbox works11" data-aos="fade-up">
                  <div class="works-content" >
                    <h3>Dolphin Diagnostic </h3>
                    <h4>Website </h4>
                  </div>
            </div>
        </div>

        <div class="col-sm-4">
            <div class="workbox works12" data-aos="fade-up" data-aos-delay="300">
                  <div class="works-content" >
                    <h3>Krishna Health Care</h3>
                    <h4>website </h4>
                  </div>
            </div>
        </div>

        <div class="col-sm-4" data-aos="fade-up" data-aos-delay="600">
            <div class="workbox works7" >
                  <div class="works-content">
                      <h3>PCNG</h3>
                      <h4>Website </h4>
                  </div>
            </div>
        </div>

        <div class="col-sm-4">
            <div class="workbox works4"  data-aos="fade-up">
                  <div class="works-content">
                      <h3>Harbor School</h3>
                      <h4>Website </h4>
                  </div>
            </div>
        </div>

        <div class="col-sm-4">
            <div class="workbox works14" data-aos="fade-up" data-aos-delay="300">
                  <div class="works-content">
                      <h3>Pavan Propertie</h3>
                      <h4>UI/UX Design </h4>
                  </div>
            </div>
        </div>

        <div class="col-sm-4">
            <div class="workbox works6" data-aos="fade-up" data-aos-delay="600">
                  <div class="works-content">
                      <h3>Srikanya Comforts</h3>
                      <h4>Website </h4>
                  </div>
            </div>
        </div>

        

        
      </div>

      <img src="../../assets/img/pattern.png" style="position: absolute;  left: -37px;  bottom: 50px; z-index: -5;  " />
  </div>