import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ServicesComponent } from './services/services.component';
import { WorksComponent } from './works/works.component';
import { ExploreComponent } from './explore/explore.component';
import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { CareerComponent } from './career/career.component';
import { ApplicationDevelopmentComponent } from './application-development/application-development.component';
import { UXDesignComponent} from './uxdesign/uxdesign.component';
import { DigitalMarketingComponent} from './digital-marketing/digital-marketing.component';


const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'works', component: WorksComponent },
  { path: 'explore', component: ExploreComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'career', component: CareerComponent },
  { path: 'ApplicationDevelopment', component: ApplicationDevelopmentComponent },
  { path: 'UXDesign', component: UXDesignComponent },
  { path: 'DigitalMarketing', component: DigitalMarketingComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    useHash :true // Add options right here
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
