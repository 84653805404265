<div class="p60 innerbanner">

    <div class="container">
      <div class="row">


        <div class="col-md-7 col-sm-7 col-xs-12 ">
          <h1  data-aos="fade-up" >Career You Love</h1>
          
          <p  data-aos="fade-up"  data-aos-delay="300"><span>Working with right people makes all the difference! Be a part of our Thought Leaders. A community that values your work and makes an impact.
          </span> </p>
            <br>
            <br>
            
           
        </div>
        <div class="col-md-5 col-sm-5 col-12" >
            <div class="innerheroimg">
              <img src="../../assets/img/career.png" class="hideonMobile" />
            </div>
           
          </div>
       

      </div>
    </div>
  </div>


  <div class="casestudysection p60" style="padding-top: 10px;">
    <div class="container">
        <div class="section-heading"  data-aos="fade-up">
            The Perks
          </div>
      <div class="row">
          <div class="col-sm-4 col-md-4 col-12">
            <div class="perksbox" data-aos="fade-up" data-aos-delay="300">
                <img src="../../assets/img/work-hours.png" class="img-fluid" style="width: 30%;">
                <h3>
                    Flexible working Hours
                </h3>
                <p>Work is all that matters; time is just an excuse</p>
            </div>
          </div>
          <div class="col-sm-4 col-md-4 col-12">
            <div class="perksbox" data-aos="fade-up" data-aos-delay="600">
                <img src="../../assets/img/dress-code.png" class="img-fluid" style="width: 30%;">
                <h3>
                    Informal Dress Code
                </h3>
                <p>Be the best version of you; Everyday!</p>
            </div>
          </div>
          <div class="col-sm-4 col-md-4 col-12">
            <div class="perksbox" data-aos="fade-up" data-aos-delay="900">
                <img src="../../assets/img/empowerment.png" class="img-fluid" style="width: 30%;">
                <h3>
                    Better Empowerment
                </h3>
                <p>We encourage ideas that will make a great look awesome!</p>
            </div>
          </div>
      </div>
     

    </div>
  </div>

  <div class="casestudysection p60">
      <div class="container">
        <div class="section-heading"  data-aos="fade-up">
            What you can
    Explore
          </div>
          <div class="row">
              <div class="col-sm-4 col-md-4 col-12">
                <div class="explore-box" data-aos="fade-up" data-aos-delay="300">
                    <h3><span class="explore-img"><img src="../../assets/img/career-dot.png" alt="career" class="img-fluid"></span>
                      <span class="explore-span"> Team Spirit</span></h3>
                    <p>Closely tied and supportive team to
                      care about. Highly passionate and expressive
                      people to show excellence.                
                    </p>
                  </div>
              </div>
    
    
              <div class="col-sm-4 col-md-4 col-12" data-aos="fade-up" data-aos-delay="600">
                <div class="explore-box">
                    <h3><span class="explore-img"><img src="../../assets/img/career-dot.png" alt="career" class="img-fluid"></span>
                      <span class="explore-span"> Culture of Learning</span></h3>
                    <p>Make it happen always to show and excel. An atmosphere to Keep on rememebering that learning is on to-do list              
                    </p>
                  </div>
              </div>
    
    
              <div class="col-sm-4 col-md-4 col-12">
                <div class="explore-box" data-aos="fade-up" data-aos-delay="900">
                    <h3><span class="explore-img"><img src="../../assets/img/career-dot.png" alt="career" class="img-fluid"></span>
                      <span class="explore-span"> Grow Professional</span></h3>
                    <p>Enjoy challenging work and a satisfying job without any hierarchy. A small but mighty thing which brings something big.                
                    </p>
                  </div>
              </div>    
    
          </div>
      </div>
  </div>


