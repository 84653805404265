import { Component } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import * as AOS from 'aos';

import { MenuComponent } from './menu/menu.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Sistra Technologies';
  constructor(private router: Router) {

    router.events.subscribe( (event: Event) => {

        if (event instanceof NavigationStart) {
            // Show loading indicator
            console.log("start");
           
        }

        if (event instanceof NavigationEnd) {
            // Hide loading indicator
           
           
        }

        if (event instanceof NavigationError) {
            // Hide loading indicator

            // Present error to user
            console.log(event.error);
        }
    });

}

ngOnInit(): void {
  AOS.init({
    duration: 1200,
    delay: 200,
    once: true
  });
}

}
