
  <div class="p60 innerbanner">
  <img src="../../assets/img/pattern.png" style="position: absolute;  right: 0px;  top: 150px;  " class="hideonMobile" />
    <div class="container">
      <div class="row">


        <div class="col-md-7 col-sm-7 col-xs-12 ">
          <h1  data-aos="fade-up" >Exploring us</h1>
          
          <p  data-aos="fade-up"  data-aos-delay="300"><span>We help bring a cultural, organizational and operational change in your business through smart integration of digital technologies, processes and competencies across all levels and functions. Digital transformation is not just about technology. It’s about value, people, optimization and adaptation through an intelligent and people-oriented use of technologies.
          </span> </p>
            <br>
            
        </div>
       

      </div>
    </div>
  </div>

  <div class="casestudysection p60">
    <div class="container">
      <div class="row">
        <div class="col-sm-7 col-md-7 col-12" style="text-align: center;" data-aos="fade-right">
          <img src="./assets/img/boundris1.png" class="img-fluid" style="max-width: 400px;">
        </div>
        <div class="col-sm-5 col-md-5 col-12">
          <div class="section-heading" style="text-align: left;" data-aos="fade-left">
            ABOUT US
          </div>
          <p class="section-p" style="text-align: left; width: 100%;" data-aos="fade-left">
            C & D Media Labs is a technology company specialized in providing robust information technology and digital transformation solutions for startups and established businesses.<br/><br/>
Run by a team of technology and management experts, who have grown from a small group of passionate engineers and business managers to running a full-scale dedicated development center in Visakhapatnam, India providing project-ready tech experts who work in full compliance with your needs and objectives.

          </p>
         
        </div>
      </div>

    </div>
  </div>

  <div class="expertise-section">
   
    <div class="container">
       
      <div class="section-heading" data-aos="fade-up">OUR Vision </div>
      <p class="section-p" data-aos="fade-up">We help smart people do smarter work by offering our solutions and expertise which results in faster and more effortless performance.

      </p>
     
    </div>

  </div>


<!-- 
  <div class="casestudysection p60 " style="position: relative;">
   
    <div class="container">
       
      <div class="section-heading"  data-aos="fade-up"> team </div>
     
      <div class="row">
          <div class="col-md-10 offset-md-1">
            <div class="teambox-cotainer">
                <div class="row">
                    <div class="col-md-4 col-sm-4 col-xs-12">
                        <div class="teambox" data-aos="flip-left">
                          <img src="../../assets/img/Team/team1.png"  class="img-fluid">
                          <h6>Surendra Talla</h6>
                          <p>Co-Founder, Head of Development</p>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12">
                      <div class="teambox" data-aos="flip-left" data-aos-delay="300">
                        <img src="../../assets/img/Team/team2.png"  class="img-fluid">
                        <h6>Indrasena</h6>
                        <p>Co-Founder, Head of Marketing</p>
                      </div>
                  </div>
                  <div class="col-md-4 col-sm-4 col-xs-12">
                      <div class="teambox" data-aos="flip-left" data-aos-delay="600">
                        <img src="../../assets/img/Team/team3.png"  class="img-fluid">
                        <h6>U Mrudhula</h6>
                        <p>HR Manager</p>
                      </div>
                  </div>
                  <div class="col-md-4 col-sm-4 col-xs-12">
                      <div class="teambox" data-aos="flip-left" data-aos-delay="900">
                        <img src="../../assets/img/Team/team4.png"  class="img-fluid">
                        <h6>Shasank</h6>
                        <p>Marketing Expert</p>
                      </div>
                  </div>
                  <div class="col-md-4 col-sm-4 col-xs-12">
                    <div class="teambox" data-aos="flip-left" data-aos-delay="900">
                      <img src="../../assets/img/Team/team5.png"  class="img-fluid">
                      <h6>Sandeep Jonnala</h6>
                      <p>Jr Designer</p>
                    </div>
                </div>

                <div class="col-md-4 col-sm-4 col-xs-12">
                  <div class="teambox" data-aos="flip-left" data-aos-delay="900">
                    <img src="../../assets/img/Team/team6.png"  class="img-fluid">
                    <h6>Sathvik P</h6>
                      <p>Jr UI Developer</p>
                  </div>
              </div>
                  <div class="col-md-4 col-sm-4 col-xs-12">
                      <div class="teambox" >
                        <img src="../../assets/img/empty.png"  class="img-fluid">
                        <a href="career.html" class="btn ">
                            Join our team</a>
                      </div>
                  </div>
                </div>
            </div>
          </div>
      </div>
      <img src="../../assets/img/pattern.png" style="position: absolute;  left: -37px;  bottom: 0px; z-index: -5;  " />
    </div>

  </div>
  -->